import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
@Injectable({
  providedIn: 'root'
})
export class OrderService {
    autoOrderList: any;
    orderList: any;
    sharelinkCustomizableItem: any;
    canteItem: any;

    constructor(private _restService: RestService) { }

    getAllTypeProd(): Promise<any> {
        const orderpromise = new Promise((resolve, reject) => {
            const request = {
                'EnrollmentType': '',
                'CustomerType': 22,
                'WebCategoryId': 472,
                'isUpgradeCustomer': false,
                'IsSubscriptionOrder': false
            };
            this._restService.getProducts(request).subscribe((res: any) => {
                if (res) {
                    this.orderList = res.Items;
                    this.getSharelinkCustomItems(false).then((items) => {
                        items.forEach((Item) => {
                            this.orderList.push(Item);
                        })
                        resolve(this.orderList);
                    });
                }
            }, error => {
                reject(this.orderList);
            });
        });

        const autopromise = new Promise((resolve, reject) => {
            const request = {
                'EnrollmentType': '',
                'CustomerType': '',
                'WebCategoryId': 472,
                'isUpgradeCustomer': false,
                'IsSubscriptionOrder': true
            };
            this._restService.getProducts(request).subscribe((res: any) => {
                if (res) {
                    this.autoOrderList = res.Items;
                    this.getSharelinkCustomItems(true).then((items) => {
                        items.forEach((Item) => {
                            this.autoOrderList.push(Item);
                        })
                        resolve(this.autoOrderList);
                    });
                }
            }, error => {
                reject(this.autoOrderList);
            });
        });
        const res = Promise.all([orderpromise, autopromise]);
        return res;
    }
    getSharelinkCustomItems(IsAutoship:boolean): Promise<any> {
        const customitemspromise = new Promise((resolve, reject) => {
            const request = {
                'EnrollmentType': '',
                'CustomerType': '',
                'WebCategoryId': 639,
                'isUpgradeCustomer': false,
                'IsSubscriptionOrder': IsAutoship
            };
            this._restService.getProducts(request).subscribe((res: any) => {
                if (res) {
                    this.sharelinkCustomizableItem = res.Items;
                    resolve(this.sharelinkCustomizableItem);
                }
            }, error => {
                reject(this.sharelinkCustomizableItem);
            });
        })
        return customitemspromise;
    }
    getCanteItems(): Promise<any> {
        const canteitemspromise = new Promise((resolve, reject) => {
            const request = {
                'EnrollmentType': '',
                'CustomerType': '',
                'WebCategoryId': 481,
                'isUpgradeCustomer': false,
                'IsSubscriptionOrder': false
            };
            this._restService.getProducts(request).subscribe((res: any) => {
                if (res) {
                    this.canteItem = res.Items;
                    resolve(this.canteItem);
                }
            }, error => {
                reject(this.canteItem);
            });
        })
        return canteitemspromise;
    }
}
