
<div id="Enrollment" class="page-layout">
    <div class="page-wrapper">
        <div class="ult-checkout-section wf-section">
            <app-header></app-header>
          <app-stepper *ngIf="_enrollSession.mobileView"></app-stepper>
           
          <div class="collection-padding-order-review">
            <app-stepper *ngIf="!_enrollSession.mobileView"></app-stepper>
            <div style="margin-left: -26px; gap: 15px;"  fxLayout="column" fxLayoutAlign="center center" fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="center" >
                <div *ngFor="let items of itemsmemberkitBuilder"  fxFlex="31" class="item-content">
                    <h1 class="checkout-h1" fxLayoutAlign="center center"*ngIf="items.ItemCode=='11240' || items.ItemCode=='11241' || items.ItemCode=='11242' || items.ItemCode=='11243' || items.ItemCode=='11248' || items.ItemCode=='11280'">FREE GIFTS WITH <br> Month 1-Today's Order</h1>
                    <ng-container *ngIf="items.ItemCode=='11240' || items.ItemCode=='11241' || items.ItemCode=='11242' || items.ItemCode=='11243' || items.ItemCode=='11248' || items.ItemCode=='11280'">
                        <div class="p-b-20 p-t-10 m-l customize_box" fxLayoutAlign="center start" >
                            <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                              <h3 class="customizeult customize-h3  cursor-pointer m-r-0" (click)="popupLoad(itemsmemberkitcustom,1,1)">Would you like to customize this?</h3>
                            </a>
                        </div>
                    </ng-container>
                    <h1 class="checkout-h1" fxLayoutAlign="center center" *ngIf="items.ItemCode=='11250' || items.ItemCode=='11251' || items.ItemCode=='11252' || items.ItemCode=='11253' || items.ItemCode=='11258' || items.ItemCode=='11259' || items.ItemCode=='11282' || items.ItemCode=='11283'">Month 2-3</h1>
                    <h1 class="checkout-h1" fxLayoutAlign="center center" *ngIf="items.ItemCode=='11260' || items.ItemCode=='11261' || items.ItemCode=='11262' || items.ItemCode=='11263' || items.ItemCode=='11268' || items.ItemCode=='11269' || items.ItemCode=='11284' || items.ItemCode=='11285'" >Month 4+</h1>
                    <div fxLayout="column" fxLayoutAlign="center" class="m-b-30 m-r-10">
                        <div class="itemTodayOrder" fxLayout="column" fxLayout.gt-xs="row">
                            <div fxFlex="100" fxFlex.lg="40" fxFlex.sm="50" fxFlex.md="50" fxLayoutAlign.xs="center" class="p-r-20" >
                                <div class="item-img kitImage" fxFlex.xs="90%">
                                    <img [src]="items.SmallImage" onError="this.src='assets/img/noimage.png';">
                                </div>
                                
                            </div>
                            <div fxFlex="100" fxFlex.gt-lg="60" fxFlex.gt-sm="50" fxFlex.gt-md="50" class="p-10">
                                <div class="item-info m-t-10 m-b-30">
                                    <div class="productLabel">{{items.Description}}</div>
                                    <hr />
                                    <div class="order-review-price mh-22 loyal-price" fxLayoutAlign.xs="center" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{items.Price |number : '1.2-2'}}</div>
                                    <div *ngFor="let itemsCustom of itemsmemberUltLifestyle">
                                        <div *ngIf="itemsCustom.ItemCode == items.ItemCode">
                                            <div class="order-review-price mh-22 loyal-price" fxLayoutAlign.xs="center" *ngIf="_auth.isLoggedIn()">
                                                <div *ngFor="let itemprices of itemsCustom.ItemPrices">
                                                  <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="order-review-price mh-22 r-b retailprice" fxLayoutAlign.xs="center" *ngIf="!_auth.isLoggedIn()">Retail : ${{items.RetailPrice |number : '1.2-2'}}</div>
                                    <div class="order-review-price mh-22 r-b retailprice" fxLayoutAlign.xs="center" *ngIf="_auth.isLoggedIn()"><del>Retail : ${{items.RetailPrice |number : '1.2-2'}} USD</del></div>
                                </div>
                                <div class="item-info">
                                    <span [innerHTML]="items.LongDetail"></span>
                                    <span [innerHTML]="items.LongDetail3"></span>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
         <div class="footer-div">
            <app-footercomponent></app-footercomponent>
         </div>
          </div>
        </div>
      </div>
</div>