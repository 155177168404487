<div id="Order" class="page-layout enhance" [ngClass]="{'noClick': _sharedMethodService.isOpenDialog}"
  *ngIf="siteType!='ultpack'">
  <div class="page-wrapper">
    <div class="ult-checkout-section wf-section">
      <app-header></app-header>
      <app-stepper *ngIf="_enrollSession.mobileViewMethod() && false"></app-stepper>
      <div class="" id="FlippingBanner" *ngIf="_enrollSession.mobileViewMethod()">
        <div fxFlex="100">
          <div fxFlex="60">
            <span><img src="../../../assets/images/Banner/ULT_Kitchen_Mobile.png" class=""></span>
          </div>
          <div fxFlex="40" class="display-flex">
            <div class="wellnessText">Wellness Goals &</div>
            <div class="wellnessText">Recommendations</div>
            <div class="subText">Specialty Product</div>
            <div class="subText1">Reference Guide</div>
            <a href="https://online.flippingbook.com/view/987768563/" target="_blank" class="main-btn flipping_section_get_button">
              View Guide
            </a>
          </div>
        </div>
    </div>
      <div class="collection-padding">
        <div class="" id="FlippingBanner" *ngIf="!_enrollSession.mobileViewMethod()">
          <div fxFlex="100">
            <div fxFlex="60">
              <span><img src="../../../assets/images/Banner/ULT_Kitchen.png" class="imagehMobile"></span>
            </div>
            <div fxFlex="40" class="display-flex">
              <div class="wellnessText">Wellness Goals & Recommendations</div>
              <div class="subText">Specialty Product Reference Guide</div>
              <a href="https://online.flippingbook.com/view/987768563/" target="_blank" class="main-btn flipping_section_get_button">
                View Guide
              </a>
            </div>
          </div>
      </div>
        <app-stepper *ngIf="!_enrollSession.mobileViewMethod() && false"></app-stepper>
        <mat-accordion class="example-headers-align" *ngIf="productList?.length >= 0">
          <mat-expansion-panel class="m-t-80" [expanded]="true" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="allProductHeader">
              <mat-panel-title>
                <div fxLayout="column" fxLayout.gt-lg="row" fxLayoutAlign="start"
                  fxLayoutAlign.gt-xs="space-between none" fxFlex>
                  <div fxLayoutAlign="start center" fxFlex="100" fxFlex.gt-md="49">
                    <h1 class="order-summary-h1 all-products-accordion">All Purium Products</h1>
                  </div>
                  <div *ngIf="false" fxLayoutAlign="end center" fxLayoutAlign.lt-lg="center start" fxFlex="100"
                    fxFlex.gt-md="49" class="searchBox" (keydown)="$event.stopImmediatePropagation();"
                    (click)="$event.stopPropagation();">
                    <input class="searchInput" type="text" (input)="onSearchChangeEnhance($event)"
                      placeholder="Search Your Product" fxFlex>
                    <mat-icon matSuffix>search</mat-icon>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="customSelect" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="20px">
              <div class="dropdown">
                <button class="btn btn-secondary" (click)="toggleDropdown()">
                  {{selectedDisplayName}} <mat-icon style="font-size: 18px;">keyboard_arrow_down</mat-icon>
                </button>
                <div class="dropdown-menu" [class.show]="dropdownOpen">
                  <label *ngFor="let category of categoryList" class="dropdown-item">
                    <input type="checkbox" [(ngModel)]="category.selected" (change)="updateFilteredProducts()">
                    {{ category.name }}
                  </label>
                  <a id="w-node-_263a773b-b3b9-3901-44ef-9e82a85a78f1-db337a59" class="button enhance-grid-button w-button" (click)="closeDropdown();">Close</a>
                </div>
              </div>


              <select name="category" class="payment-text-field w-input " placeholder="Sort*" [(ngModel)]="selectedSortOption" (change)="updateFilteredProducts()">
                <option value="" disabled selected>Sort*</option>
                <option *ngFor="let category of sortList" [value]='category'>{{category}}</option>
              </select>

            </div>
            <br>
            <br>
            <br><br><br>
            <div class="all-products-accordion-item">
              <!-- normal products -->
              <div class="w-layout-grid enhance-grid cen m-b-30"
                *ngIf="(_enrollSession.mediumScreen) && (productList | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()).length != 0; else otherArray">
                <div *ngFor="let topPick of productList | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()"
                  class="m-b-30">
                  <div class="itemBorder" (click)="productInfo(topPick)">
                    <img src="{{topPick.SmallPicture}}" loading="lazy"
                      class="checkout-product-img checkout-product-img-grid cursor-pointer">
                  </div>
                  <div class="" (click)="productInfo(topPick)">
                    <div class="checkout-h2 enhance-grid-h2">{{topPick.Description}}
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{topPick.Price |number : '1.2-2'}} USD
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
                    <div *ngFor="let itemprices of topPick.ItemPrices">
                      <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                    USD</div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()">
                      <del>Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                        USD</del>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn() && isBPCustomerExist()">
                    BV : {{topPick?.BusinessVolume}}
                  </div>
                  <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
                  <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59"
                    class="button enhance-grid-button w-button hover-white" (click)="addToCart(topPick,1,2)">Add to
                    Cart</a>
                </div>
              </div>
              <div class="w-layout-grid enhance-grid"
                *ngIf="(!_enrollSession.mediumScreen) && (productList | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()).length != 0; else otherArray">
                <div *ngFor="let topPick of productList | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()"
                  class="m-b-30">
                  <div class="itemBorder" (click)="productInfo(topPick)">
                    <img src="{{topPick.SmallPicture}}" loading="lazy"
                      class="checkout-product-img checkout-product-img-grid cursor-pointer">
                  </div>
                  <div class="" (click)="productInfo(topPick)">
                    <div class="checkout-h2 enhance-grid-h2">
                      {{topPick.Description}}
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{topPick.Price |number : '1.2-2'}} USD
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
                    <div *ngFor="let itemprices of topPick.ItemPrices">
                      <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                    USD</div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()">
                      <del>Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                        USD</del>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn() && isBPCustomerExist()">
                    BV : {{topPick?.BusinessVolume}}
                  </div>
                  <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
                  <a class="button enhance-grid-button w-button hover-white"
                    (click)="addToCart(topPick,1,2,sideNav);">Add to Cart</a>
                </div>
              </div>
              <!-- emergency prods -->
              <div class="w-layout-grid enhance-grid cen m-b-30"
                *ngIf="(_enrollSession.mediumScreen) && (productList | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()).length == 0; else otherArray">
                <div *ngFor="let topPick of emergencyProduct | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()"
                  class="m-b-30">
                  <div class="itemBorder" (click)="productInfo(topPick)">
                    <img src="{{topPick.SmallPicture}}" loading="lazy"
                      class="checkout-product-img checkout-product-img-grid cursor-pointer">
                  </div>
                  <div class="" (click)="productInfo(topPick)">
                    <div class="checkout-h2 enhance-grid-h2">{{topPick.Description}}
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{topPick.Price |number : '1.2-2'}} USD
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
                    <div *ngFor="let itemprices of topPick.ItemPrices">
                      <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                    USD</div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()">
                      <del>Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                        USD</del>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn() && isBPCustomerExist()">
                    BV : {{topPick?.BusinessVolume}}
                  </div>
                  <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
                  <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59"
                    class="button enhance-grid-button w-button hover-white" (click)="addToCart(topPick,1,2)">Add to
                    Cart</a>
                </div>
              </div>
              <div class="w-layout-grid enhance-grid"
                *ngIf="(!_enrollSession.mediumScreen) && (productList | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()).length == 0; else otherArray">
                <div *ngFor="let topPick of emergencyProduct | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()"
                  class="m-b-30">
                  <div class="itemBorder" (click)="productInfo(topPick)">
                    <img src="{{topPick.SmallPicture}}" loading="lazy"
                      class="checkout-product-img checkout-product-img-grid cursor-pointer">
                  </div>
                  <div class="" (click)="productInfo(topPick)">
                    <div class="checkout-h2 enhance-grid-h2">
                      {{topPick.Description}}
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{topPick.Price |number : '1.2-2'}} USD
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
                    <div *ngFor="let itemprices of topPick.ItemPrices">
                      <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                    USD</div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()">
                      <del>Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                        USD</del>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn() && isBPCustomerExist()">
                    BV : {{topPick?.BusinessVolume}}
                  </div>
                  <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
                  <a class="button enhance-grid-button w-button hover-white"
                    (click)="addToCart(topPick,1,2,sideNav);">Add to Cart</a>
                </div>
              </div>
              <div *ngIf="productList.length==0">
                <h1 class="order-summary-h1 all-products-accordion">No Products</h1>
                <br>
                <br>
                <br>
                <br>
                <br><br><br>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <!-- ****************************************************** -->
        <!--****************** productListBP **********************-->

        <mat-accordion class="example-headers-align"
          *ngIf="_sharedMethodService.AllBPProducts?.length > 0">
          <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="allProductHeader">
              <mat-panel-title>
                <div  fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="center center"
                  fxLayoutAlign.gt-xs="center" class="BP-Products" fxFlex>
                  <div   fxLayoutAlign="center center" fxFlex="auto" fxFlex.gt-md="35">
                    <h1 class="order-summary-h1 all-products-accordion">PRODUCTS ONLY AVAILABLE TO BRAND PARTNERS</h1>
                  </div>
                  <div *ngIf="false" fxLayoutAlign="end center" fxLayoutAlign.lt-lg="center start" fxFlex="100"
                    fxFlex.gt-md="49" class="searchBox" (keydown)="$event.stopImmediatePropagation();"
                    (click)="$event.stopPropagation();">
                    <input class="searchInput" type="text" (input)="onSearchChangeEnhance($event)"
                      placeholder="Search Your Product" fxFlex>
                    <mat-icon matSuffix>search</mat-icon>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="all-products-accordion-item">


              <!-- normal products -->
              <div class="w-layout-grid enhance-grid cen m-b-30" *ngIf="(_enrollSession.mediumScreen)">
                <div
                  *ngFor="let topPick of _sharedMethodService.AllBPProducts | sortBy: 'asc':'Description' | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()"
                  class="m-b-30">
                  <div class="itemBorder" (click)="productInfo(topPick)">
                    <img src="{{topPick.SmallPicture}}" loading="lazy"
                      class="checkout-product-img checkout-product-img-grid cursor-pointer">
                  </div>
                  <div class="" (click)="productInfo(topPick)">
                    <div class="checkout-h2 enhance-grid-h2">{{topPick.Description}}
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{topPick.Price |number : '1.2-2'}} USD
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
                    <div *ngFor="let itemprices of topPick.ItemPrices">
                      <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                    USD</div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()">
                      <del>Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                        USD</del>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn() && isBPCustomerExist()">
                    BV : {{topPick?.BusinessVolume}}
                  </div>
                  <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
                  <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59"
                    class="button enhance-grid-button w-button hover-white" (click)="addToCart(topPick,1,2)">Add to
                    Cart</a>
                </div>
              </div>
              <div class="w-layout-grid enhance-grid" *ngIf="(!_enrollSession.mediumScreen)">
                <div
                  *ngFor="let topPick of _sharedMethodService.AllBPProducts | sortBy: 'asc':'Description' | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()"
                  class="m-b-30">
                  <div class="itemBorder" (click)="productInfo(topPick)">
                    <img src="{{topPick.SmallPicture}}" loading="lazy"
                      class="checkout-product-img checkout-product-img-grid cursor-pointer">
                  </div>
                  <div class="" (click)="productInfo(topPick)">
                    <div class="checkout-h2 enhance-grid-h2">
                      {{topPick.Description}}
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{topPick.Price |number : '1.2-2'}} USD
                  </div>
                  <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
                    <div *ngFor="let itemprices of topPick.ItemPrices">
                      <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                    USD</div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()">
                      <del>Retail : ${{topPick.Other1Price |number : '1.2-2'}}
                        USD</del>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn() && isBPCustomerExist()">
                    BV : {{topPick?.BusinessVolume}}
                  </div>
                  <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
                  <a class="button enhance-grid-button w-button hover-white"
                    (click)="addToCart(topPick,1,2,sideNav);">Add to Cart</a>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- ****************************************************** -->
        <!-- ****************** BP section end ********************* -->

        <app-footercomponent *ngIf="productList?.length > 0"></app-footercomponent>

      </div>
    </div>
  </div>
</div>
<!-- ******************** -->
<!-- siteType=='ultpack' -->

<div id="Enrollment" class="page-layout" *ngIf="siteType=='ultpack'">
  <div class="page-wrapper">
    <div class="ult-checkout-section wf-section">
      <app-header></app-header>
      <app-stepper *ngIf="_enrollSession.mobileView && false"></app-stepper>
      <div class="collection-padding">
        <app-stepper *ngIf="!_enrollSession.mobileView && false"></app-stepper>
        <h1 class="checkout-h1 ch-h1">Choose Your Ultimate Lifestyle Transformation</h1>

        <!-- ****************************************************** -->
        <!-- ****************** NOT ULT PERFORMANCE ********************* -->
        <div *ngIf=!IsUltPerformance style="margin-left: -26px; gap: 15px;" class="main-card-flex  todaysOrder"
          fxLayout="row column" fxLayout.lt-lg="column" fxLayoutAlign="start" fxLayoutAlign.lt-lg="center">
          <div
            *ngFor="let items of itemsmemberkitBuilder | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()"
            fxFlex="31">

            <div fxLayout="column" fxLayoutAlign="flex-start" class="m-b-30 m-r-10">
              <div class="itemTodayOrder" (click)="productInfo(items)">
                <img src="{{items.SmallPicture}}" loading="lazy" alt="image" class="checkout-product-img">
              </div>
              <div>
                <h2 class="checkout-h2"
                  [ngStyle]="{'line-height': (items.ItemCode=='11235' || items.ItemCode=='11236') ? '24px' :''}">
                  {{items.Description}}</h2>
              </div>
              <div>
                <h3 class="checkout-h3" [innerHTML]="items.ShortDetail" *ngIf="false"></h3>
              </div>
              <div class="todays-order-price mh-22 f-s-12" *ngIf="items.ItemCode!='ULTLIFESTYLE'">LOYAL CUSTOMER :
                ${{items.Price |number : '1.2-2'}}</div>
              <div class="todays-order-price mh-22 r-b f-s-12" *ngIf="items.ItemCode!='ULTLIFESTYLE'">RETAIL :
                ${{items.Other1Price |number : '1.2-2'}}</div>
              <div>
                <div class="todays-order-price mh-22 r-b f-s-12" *ngIf="items.ItemCode!='ULTLIFESTYLE' && _auth.isLoggedIn() && isBPCustomerExist()">
                  BV : {{items?.BusinessVolume}}
                </div>
                <a *ngIf="items.ItemCode!='ULTLIFESTYLE'" (click)="updateEnrollmentType(22);addToCart(items,1,1);"
                  class="button w-button b-w hover-white" style="width: 235px;">Add To Cart</a>
                <a *ngIf="items.ItemCode=='ULTLIFESTYLE'" (click)="navigateToUltlifestayle();"
                  class="button w-button b-w hover-white" style="width: 235px; margin-top: 24%;">Continue</a>
              </div>
              <ng-container *ngIf="items.ItemCode == 11200">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3 cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT</h3><img
                      src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="items.ItemCode == 11235 && !IsUltPerformance">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT + Collagen
                      Support </h3><img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image"
                      class="image">
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="items.ItemCode == 11208 && !IsUltPerformance">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3 cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT+Immune</h3><img
                      src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="items.ItemCode == 11208 && IsUltPerformance">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3 cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT+Immune</h3><img
                      src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="items.ItemCode == 11210 && !IsUltPerformance">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT+Performance</h3>
                    <img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="items.ItemCode == 11210 && IsUltPerformance">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT+Performance</h3>
                    <img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                  </a>
                </div>
              </ng-container>

              <ng-container *ngIf="items.ItemCode == 11236 && !IsUltPerformance">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT + Signature
                      Collagen support</h3><img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image"
                      class="image">
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- ****************************************************** -->
        <!-- ****************** ULT PERFORMANCE ********************* -->
        <div *ngIf=IsUltPerformance style="margin-left: -50px; gap: 15px;" class="main-card-flex" fxLayout="row column"
          fxLayout.lt-lg="column" fxLayoutAlign="center" fxLayoutAlign.lt-lg="center">
          <div
            *ngFor="let items of itemsmemberkitBuilder | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()"
            fxFlex="49">
            <div fxLayout="column" fxLayoutAlign="flex-start" class="m-b-30 m-r-10">
              <div class="itemTodayOrder1" (click)="productInfo(items)">
                <img src="{{items.SmallPicture}}" loading="lazy" alt="image" class="checkout-product-img">
              </div>
              <div>
                <h2 class="checkout-h2">{{items.Description}}</h2>
              </div>
              <div>
                <h3 class="checkout-h3" [innerHTML]="items.ShortDetail" *ngIf="false"></h3>
              </div>
              <div class="todays-order-price mh-22">LOYAL CUSTOMER : ${{items.Price |number : '1.2-2'}}</div>
              <div class="todays-order-price mh-22 r-b">RETAIL : ${{items.Other1Price |number : '1.2-2'}}</div>
              <div class="todays-order-price mh-22 r-b" *ngIf="_auth.isLoggedIn() && isBPCustomerExist()">
                BV : {{items?.BusinessVolume}}
              </div>
              <div>
                <a *ngIf="items.ItemCode!='ULTLIFESTYLE'" (click)="updateEnrollmentType(22);addToCart(items,1,1);"
                  class="button w-button b-w hover-white">Add To Cart</a>
                <a *ngIf="items.ItemCode=='ULTLIFESTYLE'" (click)="navigateToUltlifestayle();"
                  class="button w-button b-w hover-white">Continue</a>
              </div>
              <ng-container *ngIf="items.ItemCode == 11200">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3 cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT</h3><img
                      src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="items.ItemCode == 11208 && !IsUltPerformance">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3 cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT+Immune</h3><img
                      src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="items.ItemCode == 11208 && IsUltPerformance">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3 cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT+Immune</h3><img
                      src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="items.ItemCode == 11210 && !IsUltPerformance">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT+Performance</h3>
                    <img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="items.ItemCode == 11210 && IsUltPerformance">
                <div class="p-b-20 p-t-10 m-l" fxLayoutAlign="center start">
                  <a class="checkout-footer-link-blocl w-inline-block text-decoration">
                    <h3 class="customizeult customize-h3  cursor-pointer m-r-0"
                      (click)="updateEnrollmentType(22);popupLoad(items.Field6,1,1)">Customize your ULT+Performance</h3>
                    <img src="assets/images/image/Arrow-Right.svg" loading="lazy" alt="image" class="image">
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="footer-div">
          <app-footercomponent *ngIf="productList?.length > 0"></app-footercomponent>
        </div>
      </div>
    </div>
  </div>
</div>