import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { RestService } from 'app/shared/rest.service';
import * as moment from 'moment';

@Component({
  selector: 'subscriptionInfo-popup-dialog',
  templateUrl: './subscriptionInfo-popup-dialog.component.html',
  styleUrls: ['./subscriptionInfo-popup-dialog.component.scss']
})
export class SubscriptionInfoPopupDialogComponent implements OnInit {
  currentRouterPath: any;
  public userLoginDialog: boolean = false;
  public userInfo: any = {};
  public loginDetails: any = {};
  smartOrderDateForLifestyle: any;
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<SubscriptionInfoPopupDialogComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService, public _restService: RestService) {
    this.currentRouterPath = this.router.url.split('?')[0];
    this.smartOrderDateForLifestyle = moment().add(1, 'months').format('MM/DD/YYYY');
  }

  ngOnInit(): void {

  }



  closePopup(){
    this.dialogRef.close();
  }
}
