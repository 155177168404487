import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'order-alert-message',
    templateUrl: './OrderAlertMessage.component.html',
    styleUrls: ['./OrderAlertMessage.component.scss']
})
export class OrderAlertMessageComponent implements OnInit {
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
      public dialogRef: MatDialogRef<OrderAlertMessageComponent>,

    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService) {
  }

  ngOnInit(): void {

  }

  addressUse(status): void {
    if (status == 1) {

      this.dialogRef.close(true);
    }
    else{
      this.dialogRef.close();
    }
  }
    continueWithAddress(status): void {
        this.dialogRef.close({ continue: status });
    }
}
