<div class="dialog-content-wrapper" id="PrivacyDocument">
  <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
    <div class="pop-up-div">
      <div class="m-0 close-text" (click)="closePopup()">Close
      </div>
      <div class="border-text">
        <div class="container mb-5">
          <h1 style="text-align: center;"><strong>Privacy Policy</strong></h1>
          <p>&nbsp;</p>
          <h3><strong>OVERVIEW</strong></h3>
          <p>Purium Health Products recognizes the importance of protecting the privacy of information we collect from
            you. Purium is committed to respecting your privacy and protecting your personal information.</p>
          <p>&nbsp;</p>
          <h3><strong>PURPOSE</strong></h3>
          <p>This Policy explains how Purium collects, uses, stores and discloses information, including personal
            information. Purium is committed to collecting, using, storing and disclosing personal information
            responsibly. By using Purium’s websites, you consent to the collection of personal information. Information
            that is provided will only be used, stored and disclosed for the purposes for which you have consented.</p>
          <p>&nbsp;</p>
          <h3><strong>PURIUM'S COLLECTION &amp; USE OF INFORMATION</strong></h3>
          <p>Purium collects personally identifiable information such as your name, mailing address, email and telephone
            numbers entered on our websites, lead capture pages or provided directly to Purium Health Products. Purium
            uses such information for all legal purposes and to provide products and services to you. Purium may use and
            disclose the personal information we collect about you in order to:</p>
          <ul>
            <li>Process your transactions</li>
            <li>Create and manage your accounts</li>
            <li>Process Applications</li>
            <li>Notify you about product order status</li>
            <li>Develop downline genealogy reports</li>
            <li>Provide Member and Distributor services and administer benefits</li>
            <li>Send information about Purium events and product specials</li>
            <li>Develop and implement policies, marketing plans, and strategies</li>
            <li>Develop and provide advertising tailored to your interests</li>
            <li>Comply with applicable laws and regulatory requirements</li>
            <li>Enforce our Terms and Conditions and otherwise manage our business</li>
          </ul>
          <p>Purium users may opt out of email and text communication by contacting Customer Service.</p>
          <p>&nbsp;</p>
          <h3><strong>SECURITY</strong></h3>
          <p>We are committed to keeping personal information secure. This website has security measures to help protect
            against loss, misuse and alteration of sensitive information such as credit card and social security
            numbers. Purium uses security measures consistent with standard industry practice to protect that
            information.</p>
          <p>&nbsp;</p>
          <h3><strong>INFORMATION SHARING</strong></h3>
          <p>Purium is not in the business of selling customer personal information to third parties. Purium may share
            such information from time to time as described below.</p>
          <p>&nbsp;</p>
          <p>Third Party Service Providers: We use third party service providers to help us operate our business or
            administer activities on our behalf, such as sending emails, fulfilling your product and service requests,
            mailing products or managing payments. We may share your information with these third parties for limited
            purposes.</p>
          <p>&nbsp;</p>
          <p>Purium Independent Distributors: Purchases of Purium products through a websites or new subscribers capture
            on lead capture pages are credited to a Purium Independent Distributor, who is available to provide service,
            information and/or guidance in taking advantage of the benefits of membership in Purium. Information you
            provide to us will also be provided to the Purium Independent Distributor to whom your purchase has been
            assigned in order to facilitate that experience.</p>
          <p>&nbsp;</p>
          <p>If you are a Purium Independent Distributor, we may disclose certain personal information such as your
            contact information to customers seeking a Purium Independent Distributor or to other Purium Independent
            Distributors such as your upline Business Leader.</p>
          <p>&nbsp;</p>
          <p>Other Disclosures: We may disclose your personal information where required or permitted by law or to
            protect and defend the rights of Purium Health Products.</p>
          <p>&nbsp;</p>
          <h3><strong>CONTACTING US</strong></h3>
          <p>If you have any questions about this Policy or the practices of this website, you may contact the Purium
            Customer Service Department using the “Support” button on this site.</p>
        </div>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">
      </div>
    </div>
  </div>
</div>