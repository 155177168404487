<app-header></app-header>
<section id="ultlifestyleLanding">
    <div class="page-wrapper">

        <!---------------------------------- subscription-pack-choose-section --------------------------------------------------->
        <div class="subscription-pack-choose-section">
            <div class="logolifestyleClub">
                <p class="pack-heading">You’re Worth it!</p>
                <img class="imageLogo desktopView1" src="../../../assets/images/ultlifestyleLanding/Logo A Lifestyle CLub-02.png" width="100">
            </div>
            <p class="pack-sub-heading">Join the club by choosing one of these uniquely-designed superfoods packs.<br *ngIf="!_enrollSession.mobileViewMethod()"> Start your commitment today with the core nutrition your body needs to feel its best!</p>

            <div class="displayFlex">
                <p class="subscriptionQuestion">What Subscription Pack is Right for You?</p>
                <img (click)="SubscriptionTextPopup()" class="margin-info" src="../../../assets/icons/info 9.png" width="20">
            </div>
            <div class="">
                <div class="display-flex2">
                    <div class="cursor-pointer color-zoom mb-55" (click)="prevSubscribe()">
                        <img src="../../../assets/icons/left-nav.png" alt="Previous" class="nav-arrow left-arrow">
                    </div>
                
                    <ngx-slick-carousel class="subscribe-Slick" #slickModal2="slick-carousel" [config]="!_enrollSession.mobileViewMethod() ? slideConfig2 : slideConfig1">
                        <div ngxSlickItem [ngStyle]="getColors(i)" *ngFor="let subPro of SubscribeProducts; let i = index">
                            <div [ngStyle]="getColors(i)" class="border-box-slick" id="border-box-slick{{i}}">
                                <p id="Subscribeheading{{i}}" class="Subscribeheading" [innerHtml]="subPro?.heading"></p>
                                <img *ngIf="!_enrollSession.mobileViewMethod()" [src]="subPro?.imageUrl" width="200">
                                <img *ngIf="_enrollSession.mobileViewMethod()" [src]="subPro?.imageUrl" width="150">
                                <button id="product_section_get_button{{i}}" class="product_section_get_button2" (click)="packSelected(subPro?.id)">
                                    Buy Today
                                </button>
                            </div>
                            <div class="starURL">
                                <img [src]="subPro?.starURL" width="100">
                            </div>
                            <div class="reviewText">
                                <p [innerHtml]="subPro?.reviewText"></p>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                
                    <div class="cursor-pointer color-zoom mb-55" (click)="nextSubscribe()">
                        <img src="../../../assets/icons/right-nav.png" alt="Next" class="nav-arrow right-arrow">
                    </div>
                </div>
                
            </div>
        </div>
        <!---------------------------------- BannerFreeGift-Section --------------------------------------------------->
        <div class="BannerFreeGift-Section">
            <div class="">
                <div fxFlex="100"
                    id="blender-banner">
                    <span fxFlex="21" id="blenderImagespan"><img src="../../../assets/images/ultlifestyleLanding/White Blender 1.png" class="blenderImage"></span>
                    <div fxFlex="79" class="blender-text"><span>Free Gift with Purchase - A Portable Hand Blender <br *ngIf="_enrollSession.mobileViewMethod()"> <span class="color-vary">(color may vary)</span></span></div>
                </div>
            </div>
        </div>

        <!---------------------------------- benefits-section for Desktop --------------------------------------------------->
        <div class="benefits-section productInfo desktopView1">
            <div class="benefits-container w-container">
                <div class="best-life-div container">
                    <div class="container">
                        <div class="benefits-header-section">
                            <p class="section-h1 logo-section-h1" id="section-h1">
                                Making a lifestyle change starts with <br>making a commitment and creating new habits. 
                            </p>
                        </div>
                        <div class="product-section-header" id="shopNowRedirect">
                            <button (click)="packType(1)" id="1">ULT</button>
                            <button (click)="packType(6)" id="6">ULT + DARK BERRY PROTEIN</button>
                            <button (click)="packType(5)" id="5">ULT + BRAIN</button>
                            <button (click)="packType(3)" id="3">ULT + IMMUNE</button>
                            <button (click)="packType(2)" id="2">ULT + COLLAGEN</button>
                            <button (click)="packType(4)" id="4">ULT + PERFORMANCE</button>
                        </div>
                        <!-- ULT -->
                        <div class="product_secrtion" id="ult">
                            <div style="width: 100%;">
                                <div class="products-section-div">
                                    <div class="products-sec-div2">
                                    <h2 class="ult-container ult-lifestyle-transformation-text c1">Ultimate Lifestyle <br> Transformation (ULT)</h2>
                                    <button class="product_section_get_button1" (click)="packSelected(11240)">
                                        Buy Today
                                    </button>
                                    <div class="products-sec-image">
                                        <img src="../../../assets/images/ultlifestyleLanding/lightGreen_Star.png" width="200" loading="lazy"
                                        class="icons product-img ult-star" alt="core-main-product">
                                        <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                    </div>
                                    </div>
                                    <div>
                                        <img class="product_image_section_img1"
                                         [src]="ultImage" width="150">
                                        <p class="f-s-18">
                                            <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                            <br>
                                            <del style="color: #333333;" class="m-t-20">
                                                <b style="color: black;">
                                                    Retail :
                                                    ${{RetailPrices | number: '1.2-2'}} USD
                                                </b>
                                            </del>
                                        </p>
                                    </div>
                                </div>
                                <div class="m-30">
                                    <div class="product-section-header1">
                                        <button (click)="productSectionInfo(11)" id="11">Product Benefits</button>
                                        <button (click)="productSectionInfo(12)" id="12">Label Info</button>
                                        <button (click)="productSectionInfo(13)" id="13">Learn More</button>
                                    </div>
                                    <div class="info-box">
                                        <div id="pane-A">
                                            <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                            </div>
                                        </div>
                                    
                                        <div id="pane-B">
                                            <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                            </div>
                                        </div>
                                    
                                        <div id="pane-C">
                                            <div class="card-body js-reg more-info-a" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-30">
                                    <div class="product-section-header1">
                                        <button id="c1">Customer Reviews</button>
                                    </div>
                                    <div class="review-box">
                                        <div>
                                            <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11240></div>
                                            <ng-container>
                                                <div class="w-form" *ngIf="reviewSection['11200']">
                                                    <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11200">
                                                        <div fxLayout="column">
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="100">
                                                                    <div>
                                                                        <label class="f-w-600">Name</label>
                                                                        <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                            formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                            required>
                                                                    </div>
                                                                    <span class="help-block"
                                                                        *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                        <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                            required</span>
                                                                    </span>
                                                                </div>
                                            
                                                            </div>
                                                            <div fxLayout="column" fxFlex="100">
                                                                <div>
                                                                    <label class="f-w-600">Email</label>
                                                                    <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                        formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                                </div>
                                                                <span class="help-block"
                                                                    *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                    <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                    <span
                                                                    *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                    is invalid</span>
                                                                </span>
                                                            </div>
                                                            <div fxLayout="column" fxFlex="100">
                                                                <label class="f-w-600">Rating</label>
                                                                <div>
                                                                    <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                                </div>
                                            
                                                            </div><br>
                                                            <label class="f-w-600">Review Title</label>
                                                            <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                                placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                            
                                                            <span class="help-block"
                                                                *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                                <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                            </span>
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="100">
                                                                    <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                        placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                        required></textarea>
                                                                    <span class="help-block"
                                                                        *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                        <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                            required</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                    <label class="f-w-600">Picture / Video Review.</label>
                                                                    <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                        <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                        <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                            class="form-field w-input uploadPicture" name="uploadPicture"
                                                                            (change)="addReviewPicture($event,'picture')" accept="image">
                                                                            <span *ngIf="addImage">Image added successfully</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                    <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                        <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                        <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                            class="form-field w-input uploadPicture" name="uploadVideo"
                                                                            (change)="addReviewPicture($event,'video')" accept="video">
                                                                            <span *ngIf="addVideo">Video added successfully</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="100">
                                                                    <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                        id="YoutubeURL">
                                                                </div>
                                                            </div>
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="100">
                                                                    <button class="form-field w-input review-button" (click)="submitReview('11200')">Submit Review</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- ULT + Dark Berry Protein  -->
                        <div class="product_secrtion" id="ult_darkberry">
                            <div style="width: 100%;">
                                <div class="products-section-div">
                                    <div class="products-sec-div2">
                                    <h2 class="ult-container ult-lifestyle-transformation-text c1">ULT + <br>Dark Berry Protein</h2>
                                    <button class="product_section_get_button1" (click)="packSelected(11280)">
                                        Buy Today
                                    </button>
                                    <div class="products-sec-image">
                                        <img src="../../../assets/images/ultlifestyleLanding/brown_Star.png" width="200" loading="lazy"
                                        class="icons product-img ult-star" alt="core-main-product">
                                        <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                    </div>
                                    </div>
                                    <div>
                                        <img class="product_image_section_img1"
                                         [src]="ultImage" width="150">
                                        <p class="f-s-18">
                                            <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                            <br>
                                            <del style="color: #333333;" class="m-t-20">
                                                <b style="color: black;">
                                                    Retail :
                                                    ${{RetailPrices | number: '1.2-2'}} USD
                                                </b>
                                            </del>
                                        </p>
                                    </div>
                                </div>
                                <div class="m-30">
                                    <div class="product-section-header1">
                                        <button (click)="productSectionInfo(26)" id="26">Product Benefits</button>
                                        <button (click)="productSectionInfo(27)" id="27">Label Info</button>
                                        <button (click)="productSectionInfo(28)" id="28">Learn More</button>
                                    </div>
                                    <div class="info-box">
                                        <div id="pane-P">
                                            <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                            </div>
                                        </div>
                                    
                                        <div id="pane-Q">
                                            <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                            </div>
                                        </div>
                                    
                                        <div id="pane-R">
                                            <div class="card-body js-reg more-info-a" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-30">
                                    <div class="product-section-header1">
                                        <button id="c1">Customer Reviews</button>
                                    </div>
                                    <div class="review-box">
                                        <div>
                                            <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11280></div>
                                            <ng-container>
                                                <div class="w-form" *ngIf="reviewSection['11280']">
                                                    <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11280">
                                                        <div fxLayout="column">
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="100">
                                                                    <div>
                                                                        <label class="f-w-600">Name</label>
                                                                        <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                            formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                            required>
                                                                    </div>
                                                                    <span class="help-block"
                                                                        *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                        <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                            required</span>
                                                                    </span>
                                                                </div>
                                            
                                                            </div>
                                                            <div fxLayout="column" fxFlex="100">
                                                                <div>
                                                                    <label class="f-w-600">Email</label>
                                                                    <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                        formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                                </div>
                                                                <span class="help-block"
                                                                    *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                    <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                    <span
                                                                    *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                    is invalid</span>
                                                                </span>
                                                            </div>
                                                            <div fxLayout="column" fxFlex="100">
                                                                <label class="f-w-600">Rating</label>
                                                                <div>
                                                                    <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                                </div>
                                            
                                                            </div><br>
                                                            <label class="f-w-600">Review Title</label>
                                                            <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                                placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                            
                                                            <span class="help-block"
                                                                *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                                <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                            </span>
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="100">
                                                                    <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                        placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                        required></textarea>
                                                                    <span class="help-block"
                                                                        *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                        <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                            required</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                    <label class="f-w-600">Picture / Video Review.</label>
                                                                    <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                        <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                        <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                            class="form-field w-input uploadPicture" name="uploadPicture"
                                                                            (change)="addReviewPicture($event,'picture')" accept="image">
                                                                            <span *ngIf="addImage">Image added successfully</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                    <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                        <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                        <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                            class="form-field w-input uploadPicture" name="uploadVideo"
                                                                            (change)="addReviewPicture($event,'video')" accept="video">
                                                                            <span *ngIf="addVideo">Video added successfully</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="100">
                                                                    <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                        id="YoutubeURL">
                                                                </div>
                                                            </div>
                                                            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                <div fxLayout="column" fxFlex="100">
                                                                    <button class="form-field w-input review-button" (click)="submitReview('11200')">Submit Review</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- ULT-BRAIN -->
                        <div class="product_secrtion" id='ult_brain'>
                            <div style="width: 100%;">
                                <div class="products-section-div">
                                    <div class="products-sec-div2">
                                    <h2 class="ult-container ult-lifestyle-transformation-text c1">ULT + BRAIN</h2>
                                    <button class="product_section_get_button1" (click)="packSelected(11248)">
                                        Buy Today
                                    </button>
                                    <div class="products-sec-image">
                                        <img src="../../../assets/images/ultlifestyleLanding/blue_star.png" width="200" loading="lazy"
                                        class="icons product-img ult-star" alt="core-main-product">
                                        <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                    </div>
                                    </div>
                                    <div>
                                        <img class="product_image_section_img1"
                                         [src]="ultImage" width="150">
                                        <p class="f-s-18">
                                            <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                            <br>
                                            <del style="color: #333333;" class="m-t-20">
                                                <b style="color: black;">
                                                    Retail :
                                                    ${{RetailPrices | number: '1.2-2'}} USD
                                                </b>
                                            </del>
                                        </p>
                                    </div>
                                </div>
                                <div class="m-30">
                                    <div class="product-section-header1">
                                        <button (click)="productSectionInfo(14)" id="14">Product Benefits</button>
                                        <button (click)="productSectionInfo(15)" id="15">Label Info</button>
                                        <button (click)="productSectionInfo(16)" id="16">Learn More</button>
                                    </div>
                                    <div class="info-box">
                                        <div id="pane-D">
                                            <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                            </div>
                                        </div>
                                    
                                        <div id="pane-E">
                                            <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                            </div>
                                        </div>
                                    
                                        <div id="pane-F">
                                            <div class="card-body js-reg more-info-a" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-30">
                                    <div class="product-section-header1">
                                        <button id="c1">Customer Reviews</button>
                                    </div>
                                    <div class="review-box">
                                        <div>
                                            <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11248></div>
                                            <ng-container>
                                            <div class="w-form" *ngIf="reviewSection['11270']">
                                                <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11248">
                                                    <div fxLayout="column">
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <div>
                                                                    <label class="f-w-600">Name</label>
                                                                    <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                        formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                        required>
                                                                </div>
                                                                <span class="help-block"
                                                                    *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                    <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                        required</span>
                                                                </span>
                                                            </div>
                                        
                                                        </div>
                                                        <div fxLayout="column" fxFlex="100">
                                                            <div>
                                                                <label class="f-w-600">Email</label>
                                                                <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                    formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                            </div>
                                                            <span class="help-block"
                                                                *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                <span
                                                                *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                is invalid</span>
                                                            </span>
                                                        </div>
                                                        <div fxLayout="column" fxFlex="100">
                                                            <label class="f-w-600">Rating</label>
                                                            <div>
                                                                <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                            </div>
                                        
                                                        </div><br>
                                                        <label class="f-w-600">Review Title</label>
                                                        <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                            placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                        
                                                        <span class="help-block"
                                                            *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                            <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                        </span>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                    placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                    required></textarea>
                                                                <span class="help-block"
                                                                    *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                    <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                        required</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                <label class="f-w-600">Picture / Video Review.</label>
                                                                <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                    <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                    <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                        class="form-field w-input uploadPicture" name="uploadPicture"
                                                                        (change)="addReviewPicture($event,'picture')" accept="image">
                                                                        <span *ngIf="addImage">Image added successfully</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                    <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                    <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                        class="form-field w-input uploadPicture" name="uploadVideo"
                                                                        (change)="addReviewPicture($event,'video')" accept="video">
                                                                        <span *ngIf="addVideo">Video added successfully</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                    id="YoutubeURL">
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <button class="form-field w-input review-button" (click)="submitReview('11270')">Submit Review</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ULT + COLLAGEN -->
                        <div class="product_secrtion" id='ult_collagen'>
                            <div style="width: 100%;">
                            <div class="products-section-div">
                                <div class="products-sec-div2">
                                <h2 class="ult-container ult-lifestyle-transformation-text c1">ULT + COLLAGEN</h2>
                                <button class="product_section_get_button1" (click)="packSelected(11243)">
                                    Buy Today
                                </button>
                                <div class="products-sec-image">
                                    <img src="../../../assets/images/ultlifestyleLanding/pink_Star.png" width="200" loading="lazy"
                                    class="icons product-img ult-star" alt="core-main-product">
                                    <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                </div>
                                </div>
                                <div>
                                    <img class="product_image_section_img1"
                                     [src]="ultImage" width="150">
                                    <p class="f-s-18">
                                        <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                        <br>
                                        <del style="color: #333333;" class="m-t-20">
                                            <b style="color: black;">
                                                Retail :
                                                ${{RetailPrices | number: '1.2-2'}} USD
                                            </b>
                                        </del>
                                    </p>
                                </div>
                            </div>
                            <div class="m-30">
                                <div class="product-section-header1">
                                    <button (click)="productSectionInfo(17)" id="17">Product Benefits</button>
                                    <button (click)="productSectionInfo(18)" id="18">Label Info</button>
                                    <button (click)="productSectionInfo(19)" id="19">Learn More</button>
                                </div>
                                <div class="info-box">
                                    <div id="pane-G">
                                        <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                        </div>
                                    </div>
                                
                                    <div id="pane-H">
                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                        </div>
                                    </div>
                                
                                    <div id="pane-I">
                                        <div class="card-body js-reg more-info-a" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="m-30">
                                <div class="product-section-header1">
                                    <button id="c1">Customer Reviews</button>
                                </div>
                                <div class="review-box">
                                    <div>
                                        <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11243></div>
                                        <ng-container>
                                            <div class="w-form" *ngIf="reviewSection['11235']">
                                                <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11235">
                                                    <div fxLayout="column">
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <div>
                                                                    <label class="f-w-600">Name</label>
                                                                    <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                        formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                        required>
                                                                </div>
                                                                <span class="help-block"
                                                                    *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                    <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                        required</span>
                                                                </span>
                                                            </div>
                                        
                                                        </div>
                                                        <div fxLayout="column" fxFlex="100">
                                                            <div>
                                                                <label class="f-w-600">Email</label>
                                                                <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                    formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                            </div>
                                                            <span class="help-block"
                                                                *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                <span
                                                                *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                is invalid</span>
                                                            </span>
                                                        </div>
                                                        <div fxLayout="column" fxFlex="100">
                                                            <label class="f-w-600">Rating</label>
                                                            <div>
                                                                <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                            </div>
                                        
                                                        </div><br>
                                                        <label class="f-w-600">Review Title</label>
                                                        <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                            placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                        
                                                        <span class="help-block"
                                                            *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                            <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                        </span>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                    placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                    required></textarea>
                                                                <span class="help-block"
                                                                    *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                    <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                        required</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                <label class="f-w-600">Picture / Video Review.</label>
                                                                <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                    <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                    <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                        class="form-field w-input uploadPicture" name="uploadPicture"
                                                                        (change)="addReviewPicture($event,'picture')" accept="image">
                                                                        <span *ngIf="addImage">Image added successfully</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                    <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                    <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                        class="form-field w-input uploadPicture" name="uploadVideo"
                                                                        (change)="addReviewPicture($event,'video')" accept="video">
                                                                        <span *ngIf="addVideo">Video added successfully</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                    id="YoutubeURL">
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <button class="form-field w-input review-button" (click)="submitReview('11235')">Submit Review</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <!-- ULT + Immune -->
                        <div class="product_secrtion" id='ult_immune'>
                            <div style="width: 100%;">
                            <div class="products-section-div">
                                <div class="products-sec-div2">
                                <h2 class="ult-container ult-lifestyle-transformation-text c1">ULT + IMMUNE</h2>
                                <button class="product_section_get_button1" (click)="packSelected(11242)">
                                    Buy Today
                                </button>
                                <div class="products-sec-image">
                                    <img src="../../../assets/images/ultlifestyleLanding/yellow_Star.png" width="200" loading="lazy"
                                    class="icons product-img ult-star" alt="core-main-product">
                                    <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                </div>
                                </div>
                                <div>
                                    <img class="product_image_section_img1"
                                     [src]="ultImage" width="150">
                                    <p class="f-s-18">
                                        <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                        <br>
                                        <del style="color: #333333;" class="m-t-20">
                                            <b style="color: black;">
                                                Retail :
                                                ${{RetailPrices | number: '1.2-2'}} USD
                                            </b>
                                        </del>
                                    </p>
                                </div>
                            </div>
                            <div class="m-30">
                                <div class="product-section-header1">
                                    <button (click)="productSectionInfo(20)" id="20">Product Benefits</button>
                                    <button (click)="productSectionInfo(21)" id="21">Label Info</button>
                                    <button (click)="productSectionInfo(22)" id="22">Learn More</button>
                                </div>
                                <div class="info-box">
                                    <div id="pane-J">
                                        <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                        </div>
                                    </div>
                                
                                    <div id="pane-K">
                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                        </div>
                                    </div>
                                
                                    <div id="pane-L">
                                        <div class="card-body js-reg more-info-a" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="m-30">
                                <div class="product-section-header1">
                                    <button id="c1">Customer Reviews</button>
                                </div>
                                <div class="review-box">
                                    <div>
                                        <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11242></div>
                                        <ng-container>
                                            <div class="w-form" *ngIf="reviewSection['11208']">
                                                <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11208">
                                                    <div fxLayout="column">
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <div>
                                                                    <label class="f-w-600">Name</label>
                                                                    <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                        formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                        required>
                                                                </div>
                                                                <span class="help-block"
                                                                    *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                    <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                        required</span>
                                                                </span>
                                                            </div>
                                        
                                                        </div>
                                                        <div fxLayout="column" fxFlex="100">
                                                            <div>
                                                                <label class="f-w-600">Email</label>
                                                                <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                    formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                            </div>
                                                            <span class="help-block"
                                                                *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                <span
                                                                *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                is invalid</span>
                                                            </span>
                                                        </div>
                                                        <div fxLayout="column" fxFlex="100">
                                                            <label class="f-w-600">Rating</label>
                                                            <div>
                                                                <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                            </div>
                                        
                                                        </div><br>
                                                        <label class="f-w-600">Review Title</label>
                                                        <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                            placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                        
                                                        <span class="help-block"
                                                            *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                            <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                        </span>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                    placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                    required></textarea>
                                                                <span class="help-block"
                                                                    *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                    <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                        required</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                <label class="f-w-600">Picture / Video Review.</label>
                                                                <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                    <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                    <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                        class="form-field w-input uploadPicture" name="uploadPicture"
                                                                        (change)="addReviewPicture($event,'picture')" accept="image">
                                                                        <span *ngIf="addImage">Image added successfully</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                    <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                    <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                        class="form-field w-input uploadPicture" name="uploadVideo"
                                                                        (change)="addReviewPicture($event,'video')" accept="video">
                                                                        <span *ngIf="addVideo">Video added successfully</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                    id="YoutubeURL">
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <button class="form-field w-input review-button" (click)="submitReview('11208')">Submit Review</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <!-- ULT + Performance -->
                        <div class="product_secrtion" id="ult_performance">
                            <div style="width: 100%;">
                            <div class="products-section-div">
                                <div class="products-sec-div2">
                                <h2 class="ult-container ult-lifestyle-transformation-text c1">ULT + PERFORMANCE</h2>
                                <button class="product_section_get_button1" (click)="packSelected(11241)">
                                    Buy Today
                                </button>
                                <div class="products-sec-image">
                                    <img src="../../../assets/images/ultlifestyleLanding/blue_star.png" width="200" loading="lazy"
                                    class="icons product-img ult-star" alt="core-main-product">
                                    <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                </div>
                                </div>
                                <div>
                                    <img class="product_image_section_img1"
                                     [src]="ultImage" width="150">
                                    <p class="f-s-18">
                                        <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                        <br>
                                        <del style="color: #333333;" class="m-t-20">
                                            <b style="color: black;">
                                                Retail:
                                                ${{RetailPrices | number: '1.2-2'}} USD
                                            </b>
                                        </del>
                                    </p>
                                </div>
                            </div>
                            <div class="m-30">
                                <div class="product-section-header1">
                                    <button (click)="productSectionInfo(23)" id="23">Product Benefits</button>
                                    <button (click)="productSectionInfo(24)" id="24">Label Info</button>
                                    <button (click)="productSectionInfo(25)" id="25">Learn More</button>
                                </div>
                                <div class="info-box">
                                    <div id="pane-M">
                                        <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                        </div>
                                    </div>
                                
                                    <div id="pane-N">
                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                        </div>
                                    </div>
                                
                                    <div id="pane-O">
                                        <div class="card-body js-reg more-info-a" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="m-30">
                                <div class="product-section-header1">
                                    <button id="c1">Customer Reviews</button>
                                </div>
                                <div class="review-box">
                                    <div>
                                        <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11241></div>
                                        <ng-container>
                                            <div class="w-form" *ngIf="reviewSection['11210']">
                                                <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11210">
                                                    <div fxLayout="column">
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <div>
                                                                    <label class="f-w-600">Name</label>
                                                                    <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                        formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                        required>
                                                                </div>
                                                                <span class="help-block"
                                                                    *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                    <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                        required</span>
                                                                </span>
                                                            </div>
                                        
                                                        </div>
                                                        <div fxLayout="column" fxFlex="100">
                                                            <div>
                                                                <label class="f-w-600">Email</label>
                                                                <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                    formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                            </div>
                                                            <span class="help-block"
                                                                *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                <span
                                                                *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                is invalid</span>
                                                            </span>
                                                        </div>
                                                        <div fxLayout="column" fxFlex="100">
                                                            <label class="f-w-600">Rating</label>
                                                            <div>
                                                                <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                            </div>
                                        
                                                        </div><br>
                                                        <label class="f-w-600">Review Title</label>
                                                        <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                            placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                        
                                                        <span class="help-block"
                                                            *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                            <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                        </span>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                    placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                    required></textarea>
                                                                <span class="help-block"
                                                                    *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                    <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                        required</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                <label class="f-w-600">Picture / Video Review.</label>
                                                                <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                    <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                    <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                        class="form-field w-input uploadPicture" name="uploadPicture"
                                                                        (change)="addReviewPicture($event,'picture')" accept="image">
                                                                        <span *ngIf="addImage">Image added successfully</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                    <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                    <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                        class="form-field w-input uploadPicture" name="uploadVideo"
                                                                        (change)="addReviewPicture($event,'video')" accept="video">
                                                                        <span *ngIf="addVideo">Video added successfully</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                    id="YoutubeURL">
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                            <div fxLayout="column" fxFlex="100">
                                                                <button class="form-field w-input review-button" (click)="submitReview('11210')">Submit Review</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: none;" class="core-section">
                <div class="benefits-headings-div mob-cen">

                    <button fxHide class="product_section_get_button" id="product_section_get_buttonx"
                            (click)="packSelected(11240)">
                        Get Started Today
                    </button>
                </div>
            </div>
        </div>
        
        <!---------------------------------- benefits-section for Mobile --------------------------------------------------->
        <div class="benefits-section productInfo mobileView1">
            <div class="benefits-container w-container">
                <div class="best-life-div w-container">
                    <div class="icons-container w-container">
                        <div class="benefits-header-section">
                            <h1 class="section-h1 logo-section-h1" id="section-h1">
                                Making a lifestyle change starts with <br>making a commitment and creating new habits. 
                            </h1>
                        </div>
                        <div class="container accordion accordion-flush">
                            <div class="accordion-item" id="collapseULT"  [ngClass]="{'margin-border':ulttoggle}" style="border: none !important;">
                                <h2 class="accordion-header m-5px" id="ULT">
                                    <button (click)="ClickToOpenULT(1)" class="accordion-button collapsed togglesButtonULT bg-ult1" type="button">
                                        <p class="lora-bold">
                                            <span class="headingTextult1 fs19" style="color: #749472">
                                                ULT
                                            </span>
                                        </p>
                                        <mat-icon class="arrowupdown" *ngIf="ulttoggle">keyboard_arrow_up</mat-icon>
                                        <mat-icon class="arrowupdown" *ngIf="!ulttoggle">keyboard_arrow_down</mat-icon>
                                    </button>
                                </h2>
                                <div *ngIf="ulttoggle" class="ULTClass ultborder1">
                                    <div class="accordion-body">
                                        <div class="products-sec-div2">
                                            <h2 class="ult-container ult-lifestyle-transformation-text c1">Ultimate Lifestyle <br> Transformation (ULT)</h2>
                                            <img class="product_image_section_img1" [src]="ultImage" width="150">
                                            <button class="product_section_get_button1" (click)="packSelected(11240)">
                                                Buy Today
                                            </button>
                                            <div class="products-sec-image">
                                                <img src="../../../assets/images/ultlifestyleLanding/lightGreen_Star.png" width="200" loading="lazy"
                                                    class="icons product-img ult-star" alt="core-main-product">
                                                <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                            </div>
                                            <div class="mt-30px">
                                                <p class="f-s-18">
                                                    <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                                    <br>
                                                    <del style="color: #AEA8A8;" class="m-t-20">
                                                        <b style="color: black;">
                                                            Retail:
                                                            ${{RetailPrices | number: '1.2-2'}} USD
                                                        </b>
                                                    </del>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="">
                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTProductsInfo(1)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Product Benefits
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultproductsInfoStates[0]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultproductsInfoStates[0]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultproductsInfoStates[0]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLabelInfo(1)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span>
                                                                Label Info
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlabelInfoStates[0]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlabelInfoStates[0]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlabelInfoStates[0]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLearnMore(1)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span>
                                                                Learn More
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlearnMoreStates[0]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlearnMoreStates[0]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlearnMoreStates[0]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTCustomerReviews(1)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span>
                                                                Customer Reviews
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultcustomerReviews">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultcustomerReviews">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultcustomerReviews">
                                                    <div>
                                                        <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11240Mobile></div>
                                                        <ng-container>
                                                            <div class="w-form" *ngIf="reviewSection['11200']">
                                                                <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11200Mobile">
                                                                    <div fxLayout="column">
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <div>
                                                                                    <label class="f-w-600">Name</label>
                                                                                    <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                                        formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                                        required>
                                                                                </div>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                                    <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                        
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <div>
                                                                                <label class="f-w-600">Email</label>
                                                                                <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                                    formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                                            </div>
                                                                            <span class="help-block"
                                                                                *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                                <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                                <span
                                                                                *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                                is invalid</span>
                                                                            </span>
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <label class="f-w-600">Rating</label>
                                                                            <div>
                                                                                <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                                            </div>
                                                        
                                                                        </div><br>
                                                                        <label class="f-w-600">Review Title</label>
                                                                        <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                                            placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                                        
                                                                        <span class="help-block"
                                                                            *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                                            <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                                        </span>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                                    placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                                    required></textarea>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                                    <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <label class="f-w-600">Picture / Video Review.</label>
                                                                                <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                                    <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                                    <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                                        class="form-field w-input uploadPicture" name="uploadPicture"
                                                                                        (change)="addReviewPicture($event,'picture')" accept="image">
                                                                                        <span *ngIf="addImage">Image added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                                    <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                                    <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                                        class="form-field w-input uploadPicture" name="uploadVideo"
                                                                                        (change)="addReviewPicture($event,'video')" accept="video">
                                                                                        <span *ngIf="addVideo">Video added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                                    id="YoutubeURL">
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <button class="form-field w-input review-button" (click)="submitReview('11200')">Submit Review</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" id="collapseULTDarkBerry"  [ngClass]="{'margin-border':ulttoggle6}" style="border: none !important;">
                                <h2 class="accordion-header m-5px" id="ULTDARKBERRY">
                                    <button (click)="ClickToOpenULT(6)" class="accordion-button collapsed togglesButtonULT bg-ult6" type="button">
                                        <p class="lora-bold">
                                            <span class="headingTextult6 fs19" style="color: #550855">
                                                ULT + DARK BERRY PROTEIN
                                            </span>
                                        </p>
                                        <mat-icon class="arrowupdown" *ngIf="ulttoggle6">keyboard_arrow_up</mat-icon>
                                        <mat-icon class="arrowupdown" *ngIf="!ulttoggle6">keyboard_arrow_down</mat-icon>
                                    </button>
                                </h2>
                                <div *ngIf="ulttoggle6" class="ULTClass ultborder6">
                                    <div class="accordion-body">
                                        <div class="products-sec-div2">
                                            <h2 class="ult-container ult-lifestyle-transformation-text c1" style="color: #550855">ULT + DARK BERRY PROTEIN</h2>
                                            <img class="product_image_section_img1" [src]="ultImage" width="150">
                                            <button class="product_section_get_button1" style="background-color: #550855;" (click)="packSelected(11280)">
                                                Buy Today
                                            </button>
                                            <div class="products-sec-image">
                                                <img src="../../../assets/images/ultlifestyleLanding/brown_Star.png" width="200" loading="lazy"
                                                    class="icons product-img ult-star" alt="core-main-product">
                                                <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                            </div>
                                            <div class="mt-30px">
                                                <p class="f-s-18">
                                                    <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                                    <br>
                                                    <del style="color: #AEA8A8;" class="m-t-20">
                                                        <b style="color: black;">
                                                            Retail
                                                            ${{RetailPrices | number: '1.2-2'}} USD
                                                        </b>
                                                    </del>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="">
                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTProductsInfo(6)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Product Benefits
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultproductsInfoStates[5]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultproductsInfoStates[5]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultproductsInfoStates[5]">
                                                    <div id="pane-P">
                                                        <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLabelInfo(6)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Label Info
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlabelInfoStates[5]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlabelInfoStates[5]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlabelInfoStates[5]">
                                                    <div id="pane-P">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLearnMore(6)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Learn More
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlearnMoreStates[5]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlearnMoreStates[5]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlearnMoreStates[5]">
                                                    <div id="pane-P">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTCustomerReviews(6)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Customer Reviews
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultcustomerReviews6">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultcustomerReviews6">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultcustomerReviews6">
                                                    <div>
                                                        <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11280Mobile></div>
                                                        <ng-container>
                                                            <div class="w-form" *ngIf="reviewSection['11280']">
                                                                <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11280Mobile">
                                                                    <div fxLayout="column">
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <div>
                                                                                    <label class="f-w-600">Name</label>
                                                                                    <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                                        formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                                        required>
                                                                                </div>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                                    <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                        
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <div>
                                                                                <label class="f-w-600">Email</label>
                                                                                <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                                    formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                                            </div>
                                                                            <span class="help-block"
                                                                                *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                                <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                                <span
                                                                                *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                                is invalid</span>
                                                                            </span>
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <label class="f-w-600">Rating</label>
                                                                            <div>
                                                                                <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                                            </div>
                                                        
                                                                        </div><br>
                                                                        <label class="f-w-600">Review Title</label>
                                                                        <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                                            placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                                        
                                                                        <span class="help-block"
                                                                            *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                                            <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                                        </span>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                                    placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                                    required></textarea>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                                    <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <label class="f-w-600">Picture / Video Review.</label>
                                                                                <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                                    <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                                    <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                                        class="form-field w-input uploadPicture" name="uploadPicture"
                                                                                        (change)="addReviewPicture($event,'picture')" accept="image">
                                                                                        <span *ngIf="addImage">Image added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                                    <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                                    <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                                        class="form-field w-input uploadPicture" name="uploadVideo"
                                                                                        (change)="addReviewPicture($event,'video')" accept="video">
                                                                                        <span *ngIf="addVideo">Video added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                                    id="YoutubeURL">
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <button class="form-field w-input review-button" (click)="submitReview('11280')">Submit Review</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" id="collapseULTBrain"  [ngClass]="{'margin-border':ulttoggle2}" style="border: none !important;">
                                <h2 class="accordion-header m-5px" id="ULTBRAIN">
                                    <button (click)="ClickToOpenULT(2)" class="accordion-button collapsed togglesButtonULT bg-ult2" type="button">
                                        <p class="lora-bold">
                                            <span class="headingTextult2 fs19" style="color: #1C4BC0">
                                                ULT + BRAIN
                                            </span>
                                        </p>
                                        <mat-icon class="arrowupdown" *ngIf="ulttoggle2">keyboard_arrow_up</mat-icon>
                                        <mat-icon class="arrowupdown" *ngIf="!ulttoggle2">keyboard_arrow_down</mat-icon>
                                    </button>
                                </h2>
                                <div *ngIf="ulttoggle2" class="ULTClass ultborder2">
                                    <div class="accordion-body">
                                        <div class="products-sec-div2">
                                            <h2 class="ult-container ult-lifestyle-transformation-text c1" style="color: #1C4BC0">ULT + BRAIN</h2>
                                            <img class="product_image_section_img1" [src]="ultImage" width="150">
                                            <button class="product_section_get_button1" style="background-color: #1C4BC0;" (click)="packSelected(11248)">
                                                Buy Today
                                            </button>
                                            <div class="products-sec-image">
                                                <img src="../../../assets/images/ultlifestyleLanding/blue_star.png" width="200" loading="lazy"
                                                    class="icons product-img ult-star" alt="core-main-product">
                                                <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                            </div>
                                            <div class="mt-30px">
                                                <p class="f-s-18">
                                                    <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                                    <br>
                                                    <del style="color: #AEA8A8;" class="m-t-20">
                                                        <b style="color: black;">
                                                            Retail
                                                            ${{RetailPrices | number: '1.2-2'}} USD
                                                        </b>
                                                    </del>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="">
                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTProductsInfo(2)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Product Benefits
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultproductsInfoStates[1]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultproductsInfoStates[1]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultproductsInfoStates[1]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLabelInfo(2)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Label Info
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlabelInfoStates[1]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlabelInfoStates[1]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlabelInfoStates[1]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLearnMore(2)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Learn More
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlearnMoreStates[1]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlearnMoreStates[1]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlearnMoreStates[1]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTCustomerReviews(2)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Customer Reviews
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultcustomerReviews2">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultcustomerReviews2">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultcustomerReviews2">
                                                    <div>
                                                        <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11248Mobile></div>
                                                        <ng-container>
                                                            <div class="w-form" *ngIf="reviewSection['11270']">
                                                                <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11248Mobile">
                                                                    <div fxLayout="column">
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <div>
                                                                                    <label class="f-w-600">Name</label>
                                                                                    <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                                        formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                                        required>
                                                                                </div>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                                    <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                        
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <div>
                                                                                <label class="f-w-600">Email</label>
                                                                                <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                                    formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                                            </div>
                                                                            <span class="help-block"
                                                                                *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                                <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                                <span
                                                                                *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                                is invalid</span>
                                                                            </span>
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <label class="f-w-600">Rating</label>
                                                                            <div>
                                                                                <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                                            </div>
                                                        
                                                                        </div><br>
                                                                        <label class="f-w-600">Review Title</label>
                                                                        <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                                            placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                                        
                                                                        <span class="help-block"
                                                                            *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                                            <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                                        </span>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                                    placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                                    required></textarea>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                                    <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <label class="f-w-600">Picture / Video Review.</label>
                                                                                <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                                    <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                                    <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                                        class="form-field w-input uploadPicture" name="uploadPicture"
                                                                                        (change)="addReviewPicture($event,'picture')" accept="image">
                                                                                        <span *ngIf="addImage">Image added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                                    <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                                    <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                                        class="form-field w-input uploadPicture" name="uploadVideo"
                                                                                        (change)="addReviewPicture($event,'video')" accept="video">
                                                                                        <span *ngIf="addVideo">Video added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                                    id="YoutubeURL">
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <button class="form-field w-input review-button" (click)="submitReview('11270')">Submit Review</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" id="collapseULTCollagen" [ngClass]="{'margin-border':ulttoggle3}" style="border: none !important;">
                                <h2 class="accordion-header m-5px" id="ULTCOLLAGEN">
                                    <button (click)="ClickToOpenULT(3)" class="accordion-button collapsed togglesButtonULT bg-ult3" type="button">
                                        <p class="lora-bold">
                                            <span class="headingTextult3 fs19" style="color: #A33FA3">
                                                ULT + COLLAGEN
                                            </span>
                                        </p>
                                        <mat-icon class="arrowupdown" *ngIf="ulttoggle3">keyboard_arrow_up</mat-icon>
                                        <mat-icon class="arrowupdown" *ngIf="!ulttoggle3">keyboard_arrow_down</mat-icon>
                                    </button>
                                </h2>
                                <div *ngIf="ulttoggle3" class="ULTClass ultborder3" >
                                    <div class="accordion-body">
                                        <div class="products-sec-div2">
                                            <h2 class="ult-container ult-lifestyle-transformation-text c1" style="color: #A33FA3">ULT + COLLAGEN</h2>
                                            <img class="product_image_section_img1" [src]="ultImage" width="150">
                                            <button class="product_section_get_button1" style="background-color: #A33FA3;" (click)="packSelected(11243)">
                                                Buy Today
                                            </button>
                                            <div class="products-sec-image">
                                                <img src="../../../assets/images/ultlifestyleLanding/pink_Star.png" width="200" loading="lazy"
                                                    class="icons product-img ult-star" alt="core-main-product">
                                                <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                            </div>
                                            <div class="mt-30px">
                                                <p class="f-s-18">
                                                    <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                                    <br>
                                                    <del style="color: #AEA8A8;" class="m-t-20">
                                                        <b style="color: black;">
                                                            Retail:
                                                            ${{RetailPrices | number: '1.2-2'}} USD
                                                        </b>
                                                    </del>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="">
                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTProductsInfo(3)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span>
                                                                Product Benefits
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultproductsInfoStates[2]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultproductsInfoStates[2]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultproductsInfoStates[2]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLabelInfo(3)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span>
                                                                Label Info
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlabelInfoStates[2]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlabelInfoStates[2]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlabelInfoStates[2]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLearnMore(3)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span>
                                                                Learn More
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlearnMoreStates[2]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlearnMoreStates[2]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlearnMoreStates[2]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTCustomerReviews(3)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span>
                                                                Customer Reviews
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultcustomerReviews3">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultcustomerReviews3">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultcustomerReviews3">
                                                    <div>
                                                        <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11243Mobile></div>
                                                        <ng-container>
                                                            <div class="w-form" *ngIf="reviewSection['11235']">
                                                                <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11235Mobile">
                                                                    <div fxLayout="column">
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <div>
                                                                                    <label class="f-w-600">Name</label>
                                                                                    <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                                        formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                                        required>
                                                                                </div>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                                    <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                        
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <div>
                                                                                <label class="f-w-600">Email</label>
                                                                                <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                                    formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                                            </div>
                                                                            <span class="help-block"
                                                                                *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                                <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                                <span
                                                                                *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                                is invalid</span>
                                                                            </span>
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <label class="f-w-600">Rating</label>
                                                                            <div>
                                                                                <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                                            </div>
                                                        
                                                                        </div><br>
                                                                        <label class="f-w-600">Review Title</label>
                                                                        <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                                            placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                                        
                                                                        <span class="help-block"
                                                                            *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                                            <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                                        </span>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                                    placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                                    required></textarea>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                                    <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <label class="f-w-600">Picture / Video Review.</label>
                                                                                <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                                    <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                                    <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                                        class="form-field w-input uploadPicture" name="uploadPicture"
                                                                                        (change)="addReviewPicture($event,'picture')" accept="image">
                                                                                        <span *ngIf="addImage">Image added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                                    <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                                    <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                                        class="form-field w-input uploadPicture" name="uploadVideo"
                                                                                        (change)="addReviewPicture($event,'video')" accept="video">
                                                                                        <span *ngIf="addVideo">Video added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                                    id="YoutubeURL">
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <button class="form-field w-input review-button" (click)="submitReview('11235')">Submit Review</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" id="collapseULTImmune" [ngClass]="{'margin-border':ulttoggle4}" style="border: none !important;">
                                <h2 class="accordion-header m-5px" id="ULTIMMUNE">
                                    <button (click)="ClickToOpenULT(4)" class="accordion-button collapsed togglesButtonULT bg-ult4" type="button">
                                        <p class="lora-bold">
                                            <span class="headingTextult4 fs19" style="color: #BF8A56">
                                                ULT + IMMUNE
                                            </span>
                                        </p>
                                        <mat-icon class="arrowupdown" *ngIf="ulttoggle4">keyboard_arrow_up</mat-icon>
                                        <mat-icon class="arrowupdown" *ngIf="!ulttoggle4">keyboard_arrow_down</mat-icon>
                                    </button>
                                </h2>
                                <div *ngIf="ulttoggle4"  class="ULTClass ultborder4">
                                    <div class="accordion-body">
                                        <div class="products-sec-div2">
                                            <h2 class="ult-container ult-lifestyle-transformation-text c1" style="color: #BF8A56">ULT + IMMUNE</h2>
                                            <img class="product_image_section_img1" [src]="ultImage" width="150">
                                            <button class="product_section_get_button1" style="background-color: #BF8A56" (click)="packSelected(11242)">
                                                Buy Today
                                            </button>
                                            <div class="products-sec-image">
                                                <img src="../../../assets/images/ultlifestyleLanding/yellow_Star.png" width="200" loading="lazy"
                                                    class="icons product-img ult-star" alt="core-main-product">
                                                <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                            </div>
                                            <div class="mt-30px">
                                                <p class="f-s-18">
                                                    <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                                    <br>
                                                    <del style="color: #AEA8A8;" class="m-t-20">
                                                        <b style="color: black;">
                                                            Retail:
                                                            ${{RetailPrices | number: '1.2-2'}} USD
                                                        </b>
                                                    </del>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="">
                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTProductsInfo(4)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Product Benefits
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultproductsInfoStates[3]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultproductsInfoStates[3]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultproductsInfoStates[3]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLabelInfo(4)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Label Info
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlabelInfoStates[3]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlabelInfoStates[3]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlabelInfoStates[3]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLearnMore(4)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Learn More
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlearnMoreStates[3]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlearnMoreStates[3]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlearnMoreStates[3]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTCustomerReviews(4)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span >
                                                                Customer Reviews
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultcustomerReviews4">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultcustomerReviews4">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultcustomerReviews4">
                                                    <div>
                                                        <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11242Mobile></div>
                                                        <ng-container>
                                                            <div class="w-form" *ngIf="reviewSection['11208']">
                                                                <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11208Mobile">
                                                                    <div fxLayout="column">
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <div>
                                                                                    <label class="f-w-600">Name</label>
                                                                                    <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                                        formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                                        required>
                                                                                </div>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                                    <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                        
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <div>
                                                                                <label class="f-w-600">Email</label>
                                                                                <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                                    formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                                            </div>
                                                                            <span class="help-block"
                                                                                *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                                <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                                <span
                                                                                *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                                is invalid</span>
                                                                            </span>
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <label class="f-w-600">Rating</label>
                                                                            <div>
                                                                                <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                                            </div>
                                                        
                                                                        </div><br>
                                                                        <label class="f-w-600">Review Title</label>
                                                                        <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                                            placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                                        
                                                                        <span class="help-block"
                                                                            *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                                            <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                                        </span>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                                    placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                                    required></textarea>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                                    <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <label class="f-w-600">Picture / Video Review.</label>
                                                                                <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                                    <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                                    <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                                        class="form-field w-input uploadPicture" name="uploadPicture"
                                                                                        (change)="addReviewPicture($event,'picture')" accept="image">
                                                                                        <span *ngIf="addImage">Image added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                                    <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                                    <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                                        class="form-field w-input uploadPicture" name="uploadVideo"
                                                                                        (change)="addReviewPicture($event,'video')" accept="video">
                                                                                        <span *ngIf="addVideo">Video added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                                    id="YoutubeURL">
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <button class="form-field w-input review-button" (click)="submitReview('11208')">Submit Review</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <!-- <button (click)="productSectionInfo(11)" id="11">Product Benefits</button>
                                                <button (click)="productSectionInfo(12)" id="12">Label Info</button>
                                                <button (click)="productSectionInfo(13)" id="13">Learn More</button> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item" id="collapseULTPerformance" [ngClass]="{'margin-border':ulttoggle5}" style="border: none !important;">
                                <h2 class="accordion-header m-5px" id="ULTPERFORMANCE">
                                    <button (click)="ClickToOpenULT(5)" class="accordion-button collapsed togglesButtonULT bg-ult5" type="button">
                                        <p class="lora-bold">
                                            <span class="headingTextult5 fs19" style="color: #53A3D7">
                                                ULT + PERFORMANCE
                                            </span>
                                        </p>
                                        <mat-icon class="arrowupdown" *ngIf="ulttoggle5">keyboard_arrow_up</mat-icon>
                                        <mat-icon class="arrowupdown" *ngIf="!ulttoggle5">keyboard_arrow_down</mat-icon>
                                    </button>
                                </h2>
                                <div *ngIf="ulttoggle5" class="ULTClass ultborder5">
                                    <div class="accordion-body">
                                        <div class="products-sec-div2">
                                            <h2 class="ult-container ult-lifestyle-transformation-text c1" style="color: #53A3D7">ULT + PERFORMANCE</h2>
                                            <img class="product_image_section_img1" [src]="ultImage" width="150">
                                            <button class="product_section_get_button1" style="background-color: #53A3D7" (click)="packSelected(11241)">
                                                Buy Today
                                            </button>
                                            <div class="products-sec-image">
                                                <img src="../../../assets/images/ultlifestyleLanding/blue_star.png" width="200" loading="lazy"
                                                    class="icons product-img ult-star" alt="core-main-product">
                                                <p class="products_reviews">{{reviews? (reviews + ' Reviews') : 'No reviews yet.' }}</p>
                                            </div>
                                            <div class="mt-30px">
                                                <p class="f-s-18">
                                                    <b>Your Price: <span style="color: #67B961;"> ${{YourPrice |number :'1.2-2'}} USD</span></b>
                                                    <br>
                                                    <del style="color: #AEA8A8;" class="m-t-20">
                                                        <b style="color: black;">
                                                            Retail:
                                                            ${{RetailPrices | number: '1.2-2'}} USD
                                                        </b>
                                                    </del>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="">
                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTProductsInfo(5)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span>
                                                                Product Benefits
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultproductsInfoStates[4]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultproductsInfoStates[4]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultproductsInfoStates[4]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="ultLongDetail">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLabelInfo(5)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span>
                                                                Label Info
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlabelInfoStates[4]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlabelInfoStates[4]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlabelInfoStates[4]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail3 ? 'No Label Info yet.' : (ultLongDetail3 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail3}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTLearnMore(5)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span>
                                                                Learn More
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultlearnMoreStates[4]">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultlearnMoreStates[4]">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultlearnMoreStates[4]">
                                                    <div id="pane-A">
                                                        <div class="card-body js-reg" [innerHtml]="(!ultLongDetail4 ? 'No Learn More yet.' : (ultLongDetail4 | safeHtml))" [ngClass]="{'no-label-info':!ultLongDetail4}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <h2 class="accordion-header" id="ULT">
                                                    <button (click)="ClickToOpenULTCustomerReviews(5)" class="accordion-button collapsed togglesButtonULTInfo" type="button">
                                                        <p class="lora-bold">
                                                            <span>
                                                                Customer Reviews
                                                            </span>
                                                        </p>
                                                        <mat-icon class="arrowupdown" *ngIf="ultcustomerReviews5">keyboard_arrow_up</mat-icon>
                                                        <mat-icon class="arrowupdown" *ngIf="!ultcustomerReviews5">keyboard_arrow_down</mat-icon>
                                                    </button>
                                                </h2>
                                                <div *ngIf="ultcustomerReviews5">
                                                    <div>
                                                        <div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement11241Mobile></div>
                                                        <ng-container>
                                                            <div class="w-form" *ngIf="reviewSection['11210']">
                                                                <form [formGroup]="customerReviewForms" class="customer-info-form custom" id="ult_11210Mobile">
                                                                    <div fxLayout="column">
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <div>
                                                                                    <label class="f-w-600">Name</label>
                                                                                    <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                                                                                        formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
                                                                                        required>
                                                                                </div>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('Name')?.valid&&customerReviewForms.get('Name')?.touched">
                                                                                    <span *ngIf="customerReviewForms.get('Name').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                        
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <div>
                                                                                <label class="f-w-600">Email</label>
                                                                                <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                                                                                    formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
                                                                            </div>
                                                                            <span class="help-block"
                                                                                *ngIf="!customerReviewForms.get('Email')?.valid&&customerReviewForms.get('Email')?.touched">
                                                                                <span *ngIf="customerReviewForms.get('Email').errors['required']">This field is required</span>
                                                                                <span
                                                                                *ngIf="!customerReviewForms.get('Email').errors['required'] && !customerReviewForms.get('Email').valid">E-mail
                                                                                is invalid</span>
                                                                            </span>
                                                                        </div>
                                                                        <div fxLayout="column" fxFlex="100">
                                                                            <label class="f-w-600">Rating</label>
                                                                            <div>
                                                                                <ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
                                                                            </div>
                                                        
                                                                        </div><br>
                                                                        <label class="f-w-600">Review Title</label>
                                                                        <input type="text" class="form-field w-input" maxlength="256" name="Phone"
                                                                            placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
                                                        
                                                                        <span class="help-block"
                                                                            *ngIf="!customerReviewForms.get('ReviewTitle').valid&&customerReviewForms.get('ReviewTitle').touched">
                                                                            <span *ngIf="customerReviewForms.get('ReviewTitle').errors['required']">This field is required</span>
                                                                        </span>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <textarea type="text" class="form-field w-input" name="WriteYourComment"
                                                                                    placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
                                                                                    required></textarea>
                                                                                <span class="help-block"
                                                                                    *ngIf="!customerReviewForms.get('WriteYourComment').valid&&customerReviewForms.get('WriteYourComment').touched">
                                                                                    <span *ngIf="customerReviewForms.get('WriteYourComment').errors['required']">This field is
                                                                                        required</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <label class="f-w-600">Picture / Video Review.</label>
                                                                                <div class="cursor-pointer w-form" (click)="uploadPicture.click()">
                                                                                    <mat-icon class="f-s-60 picture">photo_camera</mat-icon>
                                                                                    <input type="file" #uploadPicture formControlName="reviewPicture"
                                                                                        class="form-field w-input uploadPicture" name="uploadPicture"
                                                                                        (change)="addReviewPicture($event,'picture')" accept="image">
                                                                                        <span *ngIf="addImage">Image added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
                                                                                <div class="cursor-pointer w-form" (click)="uploadVideo.click()">
                                                                                    <mat-icon class="f-s-60 picture">videocam</mat-icon>
                                                                                    <input type="file" #uploadVideo formControlName="reviewVideo"
                                                                                        class="form-field w-input uploadPicture" name="uploadVideo"
                                                                                        (change)="addReviewPicture($event,'video')" accept="video">
                                                                                        <span *ngIf="addVideo">Video added successfully</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
                                                                                    id="YoutubeURL">
                                                                            </div>
                                                                        </div>
                                                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                                                            <div fxLayout="column" fxFlex="100">
                                                                                <button class="form-field w-input review-button" (click)="submitReview('11210')">Submit Review</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!---------------------------------- PositiveChange-section --------------------------------------------------->
        <div class="PositiveChange-section">
            <div>
                <p class="positivechange-section-heading">Prepare for Positive Change</p>
                <p class="positivechange-sec-subheading">Transformation happens in phases.</p>
                <p class="positivechange-sec-subheading">At Purium, we make it easy to track your product<br *ngIf="!_enrollSession.mobileViewMethod()"> intake and progress with an insightful, 90-day tracker.</p>
            </div>
        </div>
        
        <!---------------------------------- Commitment-section --------------------------------------------------->
        <div class="container commitment-section pt-2 pb-3">
            <div>
                <p class="Commitment-section-heading">Make A Commitment • Stay Consistent • Be Transformed</p>
                <p class="commitment-sec-subhead">Within these 1st 90 days, you will look, feel, and perform better than you have in years or we’ll give you your money back. With our 60-Day Money Back Guaranty, you can feel confident about the commitment you’re making today. Imagine how good you’ll feel as your body gets more of the nutrition it needs.</p>
            </div>
            <div class="container accordion" id="accordionFlushExample">
                <div class="accordion-item p-1" style="border: none !important;">
                    <h2 class="accordion-header m-5px" id="headingOne">
                        <button (click)="ClickToOpenToggleMonth(1)" class="accordion-button collapsed togglesButtonMonth" type="button">
                            <p class="lora-bold">
                                <span style="color: #749472">
                                    Month 1 of Lifestyle
                                    Subscription
                                </span>
                            </p>
                            <mat-icon class="arrowupdown" *ngIf="toggleMonth1">keyboard_arrow_up</mat-icon>
                            <mat-icon class="arrowupdown" *ngIf="!toggleMonth1">keyboard_arrow_down</mat-icon>
                        </button>
                    </h2>
                    <div *ngIf="toggleMonth1" id="collapseOne" class="">
                        <div class="accordion-body">
                            <img class="img-fluid" src="../../../assets/images/ultlifestyleLanding/What to expect Month 1 of Lifestyle Subscription.png"
                                 alt="Business Launch Pack, Portable Electric Blender, Ultimate Lifestyle Transformation">
                        </div>
                    </div>
                </div>
                <div class="accordion-item p-1" style="border: none !important;">
                    <h2 class="accordion-header m-5px" id="headingTwo">
                        <button (click)="ClickToOpenToggleMonth(2)" class="accordion-button collapsed togglesButtonMonth" type="button"
                                >
                            <p class="lora-bold">
                                <span style="color: #F9A35A">
                                    Month 2 of Lifestyle
                                    Subscription
                                </span>
                            </p>
                            <mat-icon class="arrowupdown" *ngIf="toggleMonth2">keyboard_arrow_up</mat-icon>
                            <mat-icon class="arrowupdown" *ngIf="!toggleMonth2">keyboard_arrow_down</mat-icon>
                        </button>
                    </h2>
                    <div *ngIf="toggleMonth2" id="collapseTwo">
                        <div class="accordion-body">
                            <img class="img-fluid" src="../../../assets/images/ultlifestyleLanding/What to expect Month 2 of Lifestyle Subscription.png"
                                 alt="Ultimate Lifestyle Transformation - Smart Order Pack">
                        </div>
                    </div>
                </div>
                <div class="accordion-item p-1" style="border: none !important;">
                    <h2 class="accordion-header m-5px" id="headingThree">
                        <button (click)="ClickToOpenToggleMonth(3)" class="accordion-button collapsed togglesButtonMonth" type="button">
                            <p class="lora-bold">
                                <span style="color: #F0BC53">
                                    Month 3 of Lifestyle
                                    Subscription
                                </span>
                            </p>
                            <mat-icon class="arrowupdown" *ngIf="toggleMonth3">keyboard_arrow_up</mat-icon>
                            <mat-icon class="arrowupdown" *ngIf="!toggleMonth3">keyboard_arrow_down</mat-icon>
                        </button>
                    </h2>
                    <div *ngIf="toggleMonth3" id="collapseThree" >
                        <div class="accordion-body">
                            <img class="img-fluid" src="../../../assets/images/ultlifestyleLanding/What to expect Month 3 of Lifestyle Subscription.png"
                                 alt="Ultimate Lifestyle Transformation - Smart Order Pack">
                        </div>
                    </div>
                </div>
                <div class="accordion-item p-1" style="border: none !important;">
                    <h2 class="accordion-header m-5px" id="headingFour">
                        <button (click)="ClickToOpenToggleMonth(4)" class="accordion-button collapsed togglesButtonMonth" type="button">
                            <p class="lora-bold">
                                <span style="color: #657ED6">
                                    Month 4 Beyond of Lifestyle
                                    Subscription
                                </span>
                            </p>
                            <mat-icon class="arrowupdown" *ngIf="toggleMonth4">keyboard_arrow_up</mat-icon>
                            <mat-icon class="arrowupdown" *ngIf="!toggleMonth4">keyboard_arrow_down</mat-icon>
                        </button>
                    </h2>
                    <div *ngIf="toggleMonth4" id="collapseFour">
                        <div class="accordion-body">
                            <img class="img-fluid" src="../../../assets/images/ultlifestyleLanding/What to expect Month 4 of Lifestyle Subscription.png"
                                 alt="Ultimate Lifestyle Transformation - Maintenance Smart Order Pack (Daily Core 4)">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!---------------------------------- Meet-Dave Section --------------------------------------------------->
        <div class="Meet-Dave-video-section">
            <p class="Meet-Dave-video-sec-heading">Meet Dave Sandoval, ULT & Product Formulator</p>
            <div class="display-flex2 margin-width">
                <div class="cursor-pointer color-zoom" (click)="prev()">
                    <img src="../../../assets/icons/left-nav.png" alt="Previous" class="nav-arrow left-arrow">
                </div>
                <ngx-slick-carousel class="carousel iframeVideo" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let video of meetDaveVideos" >
                        <p class="videoTitles">{{video.titles}}</p>
                        <iframe class="iframeClass"
                        [src]="(video.url) | safeUrl" frameborder="0"
                        data-resize-to-parent="true" allow="clipboard-write"></iframe>
                    </div>
                </ngx-slick-carousel>            
                <div class="cursor-pointer color-zoom" (click)="next()">
                    <img src="../../../assets/icons/right-nav.png" alt="Next" class="nav-arrow right-arrow">
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                <script src="https://v0.wordpress.com/js/next/videopress-iframe.js?m=1674852142"></script>
            </div>
        </div>

        <!---------------------------------- core-main-section --------------------------------------------------->
        <div class="container core-section">
            <div class="container benefits-headings-div mob-cen">
                <p class="core-sec-heading">Meet the Core Products Included in All<br *ngIf="!_enrollSession.mobileViewMethod()">
                    Lifestyle Subscription Packs</p>
                <div class="display-flex2 margin-width1">
                    <div class="cursor-pointer color-zoom" (click)="prevCore()">
                        <img src="../../../assets/icons/left-nav.png" alt="Previous" class="nav-arrow left-arrow">
                    </div>
                    <div class="core-main-section m-t-10">
                        <div class="core-main-top-section">
                            <div class="core-main-top-section-content">
                                <div class="core-main-products">
                                    <ngx-slick-carousel class="carousel iframeVideo" #slickModal1="slick-carousel"
                                        [config]="slideConfig">
                                        <div *ngFor="let coreSlide of coreProducts" ngxSlickItem class="text-padding">
                                            <div class="display-flex2 ml-50px">
                                                <p class="core-heading">{{coreSlide.heading}}</p>
                                                <div>
                                                <img class="margin-info" (click)="openCoreSection(coreSlide.id)" src="../../../assets/icons/info 9.png" width="20">
                                                </div>
                                            </div>
                                            <p class="core-description" [innerHtml]="coreSlide.description | safeHtml"></p>
                                            <img style="background-image: url(../../../assets/images/ultlifestyleLanding/Rectanglegrey.png);background-size: cover;"
                                                [src]="coreSlide.imageUrl" width="170" loading="lazy"
                                                class="icons product-img core-image" alt="core-main-product">
                                            <img [src]="coreSlide.star" width="120" loading="lazy"
                                                class="icons product-img core-star" alt="core-main-product">
                                            <p class="core-review" [innerHtml]="coreSlide.reviews | safeHtml"></p>
                                        </div>
                                    </ngx-slick-carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cursor-pointer color-zoom" (click)="nextCore()">
                        <img src="../../../assets/icons/right-nav.png" alt="Next" class="nav-arrow right-arrow">
                    </div>
                </div>
            </div>
        </div>
        
        <!---------------------------------- ult-tracker-section --------------------------------------------------->
        <div class="ult-tracker-section">
            <p class="ult-sec-heading">ULT Tracker: <span style="color: #B5D195;">See it, </span><span style="color: #EEA25F;">Feel it </span><span style="color: #F2C46A;">& Track it!</span></p>
            <p class="ult-sec-smallhead">This 90-day tracker comes in your Month 1 pack and includes valuable support and resources!</p>
            <img src="../../../assets/images/ultlifestyleLanding/ULT_Tracker2.png" loading="lazy" alt="ult_tracker">
            <a class="ult-sec-btn" href="https://bit.ly/ULTSchedule-Tracker" target="_blank">
                ULT Tracker
            </a>
        </div>

        <!---------------------------------- club-member-section --------------------------------------------------->
        <div class="club-member-section">
            <img class="logo" src="../../../assets/images/ultlifestyleLanding/Logo A lifestyle Club-01 1.png" loading="lazy" alt="">
            <p class="clubMemberSwag-heading">Club Member Swag</p>
            <p class="clubMemberText">The health benefits are rewarding and so is Purium’s Lifestyle Club!</p>
            <div class="">
                <div class="display-flex">
                    <div class="free1MonthImage">
                        <img class="" src="../../../assets/images/ultlifestyleLanding/Free1MonthIMG_5714_2.png" loading="lazy" alt="">
                    </div>
                    <div class="Month1Text">
                        <p class="textheadingclub">Free Gift with Month 1’s Pack</p>
                        <p class="fs-18">The “You’re Worth It” bracelet is a special<br *ngIf="!_enrollSession.mobileViewMethod()"> reminder to be kind to yourself, follow<br *ngIf="!_enrollSession.mobileViewMethod()"> your tracker and
                            discover your best self!</p>
                    </div>
                </div>
                <div class="display-flex mt-50 mt-0">
                    <div class="free1MonthImage">
                        <img class="" src="../../../assets/images/ultlifestyleLanding/t-shirt mockup_ 2.png" loading="lazy" alt="">
                    </div>
                    <div class="Month3Text">
                        <p class="textheadingclub">Free Gift with Month 3’s Pack</p>
                        <p class="fs-18">Be reminded of your accomplishment<br *ngIf="!_enrollSession.mobileViewMethod()"> every time you wear this exclusive,<br *ngIf="!_enrollSession.mobileViewMethod()"> organic cotton T-shirt!</p>
                    </div>
                </div>
            </div>
        </div>

        <!---------------------------------- lifestyle-guide-section --------------------------------------------------->
        <div class="lifestyle-guide-section" id="startFirst">
            <p class="lgs-sec-heading">Check out Purium’s ULT Welcome Guide, Schedule and Tracker</p>
            <iframe scrolling="no" frameborder="0" loading="lazy" allowfullscreen="allowfullscreen" allow="accelerometer; autoplay; encrypted-media; fullscreen *; gyroscope; picture-in-picture;" src="https://online.flippingbook.com/view/340136345/?embed=true&amp;embedId=9042b81440" width="100%" class="fbo-embed" title="title">
            </iframe>
            <a href="https://online.flippingbook.com/view/340136345/" target="_blank" class="fbo-embed lgs-sec-btn" data-fbo-id="912d78b44d" data-fbo-ratio="3:2" data-fbo-lightbox="yes" data-fbo-width="100%" data-fbo-height="auto" data-fbo-version="1" style="max-width: 100%">View Guide</a> 
        </div>

        <!---------------------------------- best-life-section Start Today --------------------------------------------------->
        <div class="best-life-section" id="startToday">
            <div class="best-life-container w-container">
                <h1 class="section-h2 best-life-h2 best-life-container-color">Start Today!</h1><br>
                <p class="start-today-section-p">Choose Your ULT Pack with Lifestyle Subscription</p>
            </div>
            <div class="w-layout-grid icon-grid-1 w-70 m-l-15 ml-15">
                <div>
                    <h6 class="icons-section-h5 color-text1">Ultimate Lifestyle <br>Transformation(ULT)</h6>
                    <div class="BuyNow">
                        <button id="BuyNow1" class="buynowBtn" (click)="BuyNowToday(11240)">Buy Today</button>
                    </div>
                </div>
                <div>
                    <h5 class="icons-section-h5 color-text5">ULT + <br>Brain</h5>
                    <div class="BuyNow">
                        <button id="BuyNow5" class="buynowBtn" (click)="BuyNowToday(11248)">Buy Today</button>
                    </div>
                </div>
                <div>
                    <h5 class="icons-section-h5 color-text6">ULT + <br>Dark Berry Protein</h5>
                    <div class="BuyNow">
                        <button id="BuyNow6" class="buynowBtn" (click)="BuyNowToday(11280)">Buy Today</button>
                    </div>
                </div>
            </div>
            <div class="w-layout-grid icon-grid-1 w-70 m-l-15 ml-15">
                <div>
                    <h5 class="icons-section-h5 color-text2">ULT + <br>Collagen</h5>
                    <div class="BuyNow">
                        <button id="BuyNow2" class="buynowBtn" (click)="BuyNowToday(11243)">Buy Today</button>
                    </div>
                </div>
                <div>
                    <h5 class="icons-section-h5 color-text4">ULT +<br> Immune</h5>
                    <div class="BuyNow">
                        <button id="BuyNow4" class="buynowBtn" (click)="BuyNowToday(11242)">Buy Today</button>
                    </div>
                </div>
                <div>
                    <h5 class="icons-section-h5 color-text3">ULT +<br> Performance</h5>
                    <div class="BuyNow">
                        <button id="BuyNow3" class="buynowBtn" (click)="BuyNowToday(11241)">Buy Today</button>
                    </div>
                </div>
            </div>
            </div>

        <!---------------------------------- footer-section-para --------------------------------------------------->
        <div class="footer-section-para">
            <p class="margin18">
                Your credit card will be charged for today's order only and securely stored for subscription processing.<br> The Lifestyle Subscription includes:
            </p>
            <div class="lifestylelist">
                <ul>
                    <li>Today's order - <b>Make a Commitment Starter Pack with </b> <span style="color: #F36F62;"><b>Free Portable Hand Blender</b></span> </li>
                    <li>Month 2 - <b>Stay Consistent & Be Transformed Smart Order Pack</b></li>
                    <li>Month 3 - <b>Stay Consistent & Be Transformed Smart Order Pack with </b><span style="color: #F36F62;"><b>Free T-Shirt</b></span></li>
                    <li>Month 4 & Beyond - <b>Maintenance Smart Order Pack "AKA Daily Core 4"</b></li>         
                </ul>
            </div><br>
            <p class="margin18">
                Your credit card will be charged on the subscription date. Subscriptions may be changed or cancelled anytime.
            </p>
        </div>
    </div>

    <div fxHide.lt-sm fxShow style="margin-right: 30px;">
        <app-footercomponent></app-footercomponent>
    </div>
    <div fxHide fxShow.lt-sm>
        <app-footercomponent></app-footercomponent>
    </div>
</section>