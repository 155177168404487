<div class="" id="stepper" fxLayoutAlign="center center">
  <a [routerLink]="steps[0].stepper_back" class="back-arrow-link-block w-inline-block text-underline" aria-label="order-stepper">
    Back</a>
  <div class="progress-bar-div1" fxLayout="column" fxLayoutAlign="center none">
    <div *ngFor="let step of steps;">
      <div fxLayout="row" fxLayoutAlign="start none" fxLayoutAlign.gt-sm="start center" fxLayoutAlign.xs="start center" fxFlex="100" *ngFor="let title of step.stepper_title; let i = index">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31">
          <div fxFlex="30" fxLayoutAlign="center center" class="tooltip_stepper">
            <a [routerLink]="step.routes[i]"><img [src]="step.stepper_images[i]" loading="lazy" alt="image">
              <span class="tooltiptext_stepper">{{title}}</span>
            </a>
          </div>
          <div fxFlex="70" fxLayoutAlign="center center">
            <span class="f-s-8 nowrap">{{title}}</span>
          </div>
        </div>
        <div fxFlex="69" class="border-top" fxLayoutAlign="center center" *ngIf="hide(i)">
        </div>
      </div>
    </div>
  </div>
  <a *ngIf="router.url.includes('/Subscribe')" (click)="subscribeDialog()" class="back-arrow-link-block w-inline-block text-underline cursor-pointer">
    Continue</a>
</div>