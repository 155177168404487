import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'faq'
})
export class FaqPipe implements PipeTransform {
  transform(data: any[], searchText: string): any[] {
    if (!data || !searchText) {
      return data;
    }

    searchText = searchText.toLowerCase();

    const matchingQuestions = data.filter(faq => faq.question.toLowerCase().includes(searchText));

    const matchingAnswers = data.filter(faq => 
      !matchingQuestions.includes(faq) && faq.answer.toLowerCase().includes(searchText)
    );

    const combinedResults = [...matchingQuestions, ...matchingAnswers];

    const seenQuestions = new Set();
    const uniqueFaqs = combinedResults.filter(faq => {
      const isDuplicate = seenQuestions.has(faq.question.toLowerCase());
      seenQuestions.add(faq.question.toLowerCase());
      return !isDuplicate; // Only include if not a duplicate
    });

    return uniqueFaqs;
  }
}
