import { OnDestroy, Component, OnInit, ViewEncapsulation, HostListener, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EnrollSessionService } from '../enrollSession.service';
import { OrderSubItemComponent } from '../Orders-Sub-Item/Orders-Sub-Item.component';
import { SharedMethodService } from '../sharedMethod.service';
import { RestService } from '../rest.service';
import { AuthService } from '../auth.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { MatSidenav } from '@angular/material/sidenav';
import { LifestyleSubscriptionComponent } from '../LifestyleSubscription/LifestyleSubscription.component';

@Component({
    selector: 'app-product-info',
    templateUrl: './Product-info.component.html',
    styleUrls: ['./Product-info.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class productInfoComponent implements OnInit, OnDestroy {
    item: any;
    reviews: any;
    routPath: any;
    currentRouterPath: any;
    subtitle: any;
    borderColor: any;
    leftLabelImg: any;
    databelowLabel: any;
    oldTab: any;
    oldexp: any;
    mobileTabs: any;
    mobileTabsWeb: any;
    customertype: any;
    allProductList: any = [];
    EnhanceTranformation: any = [];
    productList: any = [];
    reviewList: any;
    anchors: any;
    customerReview: any;
    customerReviewImage: any;
    customerReviewVideo: any;
    ratingCount = 5;
    rating = 1;
    addImage: boolean = false;
    addVideo: boolean = false;
    reviewSection: boolean = false;
    @ViewChild('ReviewElement', { static: false }) ReviewElement: ElementRef;
    clear: any;
    customerReviewForm: FormGroup;
    siteType: any;
    transformationPackMandatory: any;
    subscriptionPack: boolean = false;
    ProductCode: any = '';
    productDisplayCode: any = '';
    pageLoad: boolean = false;
    BucketUrl = environment.BucketURl;
    itemJudgmeRes: any;
    public banners = [];
    public itemsUserPrice;
    public slideConfig = {
        "dots": false,
        "infinite": false,
        "speed": 300,
        "slidesToShow": 1,
        "adaptiveHeight": false,
        "autoplay": true,
        "autoplaySpeed": 3000,
        "dir": 'ltr',
        "arrows": true
    };
    public isPanelOpend = {
        'Benefits': true,
        'Suggested': true,
        'MoreInfo': true
    };
    public isPanelOpendFirst_1: boolean = false;
    public isPanelOpendFirst: boolean = true;
    public isPanelOpendSecond: boolean = false;
    public isPanelOpendThird: boolean = false;
    SubscriptionProcessType: any;
    webcategoryIds: any = [];
    reviewMediaFile: any = [];
    constructor(
        private _matDialog: MatDialog,
        public _sharedMethodService: SharedMethodService,
        public _enrollSession: EnrollSessionService,
        public _router: Router,
        public _restService: RestService,
        public _auth: AuthService,
        private _formBuilder: FormBuilder,
        private _http: HttpClient,
        private _toastr: ToastrService,
        private _activateRoute: ActivatedRoute,
    ) {
        this._enrollSession.data.customertype = this._enrollSession.data.customertype ? this._enrollSession.data.customertype : 22;
        if (this._enrollSession.editTransformationPack != 'true' && !this._enrollSession.data.IsLoggedIn && (!this._enrollSession.data.RetailOrderSummary || Object.keys(this._enrollSession.data?.RetailOrderSummary)?.length == 0)) {
            this._enrollSession.data = {};
        }
        if (this._enrollSession.data && this._enrollSession.editTransformationPack != 'true' && (!this._enrollSession.data.RetailOrderSummary || Object.keys(this._enrollSession.data?.RetailOrderSummary)?.length == 0)) {
            this._enrollSession.data.IsShowCart = false;
            this._enrollSession.data.TotalItemLength = 0;
            this._enrollSession.data.RetailEnroll = {};
            this._enrollSession.data.InitOrder = [];
            this._enrollSession.data.InitialOrdersSubTotal = 0;
            this._enrollSession.data.RetailOrderSummary = {};
            this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
            this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
            this._enrollSession.data.RetailOrderSummary.SmartOrderDetails = {};
            this._enrollSession.data.EligibleForFreeUpgrade = false;
            this._enrollSession.data.RetailEnroll.ShippingMethod = '';
            this._enrollSession.data.usePrimaryCard = null;
            this._enrollSession.data.primaryCreditCardField = {};
            this._enrollSession.data.EnrollmentType = this._enrollSession.data.EnrollmentType || 1;
            this._enrollSession.data.IsUpGrade =
                this._activateRoute.snapshot.queryParamMap.get('customerId') ? this._activateRoute.snapshot.queryParamMap.get('customerId') : false;
            if (this._enrollSession.data.IsUpGrade) {
                localStorage.setItem('customerId', this._enrollSession.data.IsUpGrade);
            }
            this._enrollSession.data.Volume109 = 0;
            this._enrollSession.data.SmartOrderBVTotal = 0;
            this._enrollSession.data.InitialOrdersQVTotal = 0;
            this._enrollSession.data.AddOnOrdersQVTotal = 0;
        }
    }

    ngOnInit() {
        window.scrollTo(0, 0);
        this.siteType = sessionStorage.getItem("siteType");
        let ProductCode = this._activateRoute.snapshot.paramMap.get('id');
        this.productDisplayCode = JSON.parse(sessionStorage.getItem('productDisplay'))?.ItemCode;
        let CategoryID = JSON.parse(sessionStorage.getItem('productDisplay'))?.CategoryID;

        if (CategoryID)
        {
            this.webcategoryIds = [CategoryID]
        }
        else
        {
            this.webcategoryIds = [472]
        }
        //Product filter By Item Code
        this.webcategoryIds.forEach(element => {
        this.getAllProduct(element, '').then(response => {
            let items = _.filter(response, function (item) {
                return item.ItemCode == ProductCode;
            });
            if ((!items || items.length == 0) && ProductCode.indexOf('-') > -1) {
                ProductCode = ProductCode.replace(/-/g, " ");
                items = _.filter(response, function (item) {
                    return item.Description.toLowerCase() == ProductCode.toLowerCase();
                });
            }
            this.item = items[0] || this.item;
            if (this.item) {
                this.pageLoad = true;
                response.forEach(element => {
                    if (element.ItemCode == this.item.ItemCode) {
                        if (element.ItemPrices && element.ItemPrices.length > 0 && this._enrollSession.data.priceType) {
                            element.ItemPrices.forEach(price => {
                                if (price.PriceTypeID == this._enrollSession.data.priceType) {
                                    this.itemsUserPrice = price.Price;
                                }
                            });
                        }
                        else {
                            this.itemsUserPrice = element.Price;
                        }
                    }
                });
                this.subtitle = this.item.ShortDetail3?.split(':')[1];
                this.borderColor = this.item.ShortDetail4;
                this.leftLabelImg = this.item.LongDetail2?.split('>');

                for (let i = 0; i < this.leftLabelImg?.length; i++) {
                    if (this.leftLabelImg[i].search(/img/i) != -1) {
                        this.item.leftSideLabel = this.leftLabelImg[i] + '>'
                        this.leftLabelImg.splice(i, 1)
                        break
                    }
                }
                let main = this.leftLabelImg;
                main = main.map(item => item.replace('//cdn.shopify.com/s/files/1/2062/5235/t/64/assets/','//cdn.ishoppurium.store/'))
                this.item.DataBelowLabel = main?.join('>');
                let test = this.item.ShortDetail2?.split(':');
                for (let i = 0; i < test?.length; i++) {
                    if (test[i] == 'https') {
                        test.splice(i, 1);
                    }
                }
                this.banners = test;
                if (this._router.url == '/AllProducts/' + this.item.ItemCode && (!this.siteType || this.siteType == null || this.siteType == '')) {
                    this.routPath = 'Subscribe'
                    this.transformationPackMandatory = false;
                }
                else if (this._router.url == '/AllProducts/' + this.item.ItemCode && this.siteType == 'ultpack') {
                    this.transformationPackMandatory = true;
                    this.routPath = 'Add-ons'
                }
                else if (this._router.url == '/Add-ons/' + this.item.ItemCode && this.siteType == 'ultpack') {
                    this.transformationPackMandatory = false;
                    this.routPath = 'Subscribe'
                }
                else if (this._router.url == '/Add-ons/' + this.item.ItemCode && this.siteType == 'ultlifestyle') {
                    this.routPath = 'Shipping'
                    this.transformationPackMandatory = false;
                }
                else if (this._router.url == '/Subscribe/' + this.item.ItemCode) {
                    this.subscriptionPack = true;
                    this.routPath = 'Shipping'
                    this.transformationPackMandatory = false;
                }
                else {
                    this.routPath = 'AllProducts'
                    this.transformationPackMandatory = false;
                }

                if (this._router.url == '/AllProducts/' + this.item.ItemCode) {
                    this.currentRouterPath = 'onetime'
                } else {
                    this.currentRouterPath = 'subscribe'
                }

                this.GetAllJudgMeProducts();

                this.clear = setTimeout(() => {
                    this.oldTab = document.getElementsByClassName('mat-ripple mat-tab-label mat-focus-indicator mat-tab-label-active ng-star-inserted')[0] as HTMLElement

                    if (this.oldTab) {
                        this.oldTab.style.backgroundColor = this.borderColor
                        this.oldexp = document.getElementsByClassName('mat-expansion-panel-header mat-expanded')[0] as HTMLElement
                        this.oldexp.style.backgroundColor = this.borderColor
                        $('.slick-prev').css({ 'background-color': this.borderColor })
                        $('.slick-next').css({ 'background-color': this.borderColor })
                        $('.slick-prev').css({ 'border-radius': '50%' })
                        $('.slick-next').css({ 'border-radius': '50%' })
                        $('.product-videos img').css({ 'border': '2px solid ' + this.borderColor, 'border-radius': '30px', 'padding': '5px' })
                        this.mobileTabs = document.getElementsByClassName('mat-expansion-toggle-indicator-after ng-star-inserted mat-expanded')[0] as HTMLElement
                        this.mobileTabs.style.setProperty('background', this.borderColor, 'important')
                        this.mobileTabs.style.setProperty('color', 'white', 'important')
                        this.mobileTabsWeb = document.getElementsByClassName('mat-expansion-panel-header mat-expanded')[0] as HTMLElement
                        this.mobileTabsWeb.style.setProperty('background', this.borderColor, 'important')
                        this.mobileTabsWeb.style.setProperty('color', 'white', 'important');
                        const element = document.getElementsByClassName('nav-tabs');
                        element[0].classList.add('your-custom-class');
                        let elementa: HTMLElement
                        elementa = document.querySelector('.your-custom-class');
                        elementa.style.setProperty('--after-background-color', this.borderColor);
                        const tabA = document.getElementById('tab-A');
                        if (tabA) {
                            tabA.click();
                        } else {
                            console.error('Element with ID "tab-A" not found.');
                        }
                    }
                }, 1000);
                this.customerReviewForm = this.createCustomerReviewForm();
            }
            else
            {
                this.GetAllJudgMeProducts();
                this._router.navigate(['/homepage']);
            }
        }).catch(e => {
            console.log(e);
        });
        });



        const customerDetail = JSON.parse(this._auth.getUserData()) || {}
        this.getUserData(customerDetail);
        this._sharedMethodService.userLoggedInEvent.subscribe((result) => {
            if (result) {
                this.getUserData(result);
            }
        })
    }
    getType(url: string): string {
        const query = url.split('?')[1];
        if (query) {
            const params = new URLSearchParams(query);
            return params.get('type') || '';
        }
        return 'image';
    }

    getUrlWithoutType(url: string): string {
        return 'https:'+url.split('?')[0];
    }
    @HostListener('window:beforeunload')
    onBeforeUnload() {
        clearTimeout(this.clear)
        this._router.navigate(['/', 'AllProducts']);
        return true;
    }

    handleReview(handle) {
        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    this.anchors = this.ReviewElement?.nativeElement.querySelectorAll('.jdgm-paginate__page');
                    this.anchors.forEach((anchor: HTMLAnchorElement) => {
                        anchor.addEventListener('click', ($event: any) => {
                            this.getReviews(handle, $event.target.dataset.page);
                        });
                    });
                }
            }
        });
        observer.observe(this.ReviewElement.nativeElement, {
            childList: true,
            subtree: true,
        });
    }
    waitForElement(classname, callback) {
        var poops = setInterval(function () {
            if (document.getElementsByClassName(classname)) {
                clearInterval(poops);
                callback();
            }
        }, 1000);
    }

    ngAfterViewInit() {

    }

    createCustomerReviewForm() {
        return this._formBuilder.group({
            Name: ['', Validators.required],
            Email: ['', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/)]],
            ReviewTitle: ['', Validators.required],
            WriteYourComment: ['', Validators.required],
            reviewPicture: [''],
            reviewVideo: ['']
        })
    }
    addToCart(item: any, quantity: any, type: any, sideNav: MatSidenav) {
        if (this._auth.isLoggedIn() || sessionStorage.getItem('newCustomer') == 'true' || this.siteType == 'ultlifestyle') {
            if ((this.siteType == "ultpack" || this.siteType == "" || !this.siteType) && (item.ItemCode == '11200' || item.ItemCode == '11205' || item.ItemCode == '11235' || item.ItemCode == '11238' || item.ItemCode == '11208' || item.ItemCode == '11209' || item.ItemCode == '11210' || item.ItemCode == '11220' || item.ItemCode == '11270' || item.ItemCode == '11271')) {
                this.ultlifestylePoup(item, quantity, type,sideNav);
            }
            else if (item.KitMembers && item.KitMembers.length > 0) {
                const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                    width: '600px',
                    panelClass: ['order-sub-item', 'sub-item-modal'],
                    data: {
                        item: item,
                        type: type

                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result.event == 'submit') {
                        this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
                    } 
                });
            } else {
                this._sharedMethodService.ContinueRetailOrder(item, quantity, type)
                this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
            }
        }
        else {
            const dialogRef = this._matDialog.open(UserDialogComponent, {
                width: '500px',
                maxWidth: '500px',
                height: '356px',
                panelClass: '',
                disableClose: true,
                autoFocus: false
            });
            this._sharedMethodService.isOpenDialog = true;
            dialogRef.afterClosed().subscribe(result => {
                
                this._sharedMethodService.isOpenDialog = false;
                if (result) {
                    const customerDetail = JSON.parse(this._auth.getUserData()) || {}
                    this.getUserData(customerDetail);
                    if ((this.siteType == "ultpack" || this.siteType == "" || !this.siteType) && (item.ItemCode == '11200' || item.ItemCode == '11205' || item.ItemCode == '11235' || item.ItemCode == '11238' || item.ItemCode == '11208' || item.ItemCode == '11209' || item.ItemCode == '11210' || item.ItemCode == '11220' || item.ItemCode == '11270' || item.ItemCode == '11271')) {
                        this.ultlifestylePoup(item, quantity, type,sideNav);
                    }
                    else if (item.KitMembers && item.KitMembers.length > 0) {
                        this._matDialog.open(OrderSubItemComponent, {
                            width: '600px',
                            panelClass: ['order-sub-item', 'sub-item-modal'],
                            data: {
                                item: item,
                                type: type,
                            }
                        });
                    } else {
                        this._sharedMethodService.ContinueRetailOrder(item, quantity, type);
                    }
                    if (sessionStorage.getItem('newCustomer') != 'true') {
                        this._restService.GetAutoOrders(customerDetail.CustomerID).subscribe((res: any) => {
                            if (res.AutoOrderList.length > 0) {
                                localStorage.setItem("isExistingAutoship", "true");
                                let CustomAutoOrderList = [];
                                res.AutoOrderList.forEach(item => {
                                    if (new Date(item.NextRunDate).getTime() == new Date(Math.min(...res.AutoOrderList.map(e => new Date(e.NextRunDate)))).getTime()) {
                                        CustomAutoOrderList.push(item);
                                    }
                                });

                                var minAutoOrderId = Math.min(...CustomAutoOrderList.map(e => e.AutoOrderID));
                                CustomAutoOrderList = _.filter(CustomAutoOrderList, (item) => {
                                    return (item.AutoOrderID == minAutoOrderId);
                                });

                                localStorage.setItem('AutoOrderID', CustomAutoOrderList[0].AutoOrderID);
                                localStorage.setItem("AutoOrderDetails", JSON.stringify(CustomAutoOrderList[0]));
                                this.SubscriptionProcessType = CustomAutoOrderList[0].ProcessType == 0 ? "AutoOrderProcessTy1" : "AutoOrderProcessTy2" || JSON.parse(localStorage.getItem('SubscriptionProcessType')) || "AutoOrderProcessTy1";
                                localStorage.setItem("SubscriptionProcessType", JSON.stringify(this.SubscriptionProcessType));
                                this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
                            }
                        }, error => {
                            console.log("In error")
                        })
                    }

                }
            });
        }
    }

    tabChanged(test?: string) {
        if (this.pageLoad && this.oldTab) {
            this.oldTab?.removeAttribute("style");
            this.oldTab = document.getElementsByClassName('mat-ripple mat-tab-label mat-focus-indicator mat-tab-label-active ng-star-inserted')[0] as HTMLElement
            this.oldTab.style.backgroundColor = this.borderColor

            this.oldexp.removeAttribute("style");
            setTimeout(() => {
                this.oldexp = document.getElementsByClassName('mat-expansion-panel-header mat-expansion-toggle-indicator-after mat-expanded')[0] as HTMLElement
                this.oldexp.style.setProperty('background', this.borderColor, 'important')
                this.oldexp.style.setProperty('color', 'white', 'important')
            }, 1000);
        }
    }
    closeMobTab(type) {
        if (type) {
            if (type == 'benefits') {
                this.isPanelOpendFirst = false;
                this.isPanelOpendFirst_1 = false;
            }
            if (type == 'suggested') {
                this.isPanelOpendSecond = false;
            }
            if (type == 'moreInfo') {
                this.isPanelOpendThird = false;
            }
        }
        else {
            this.isPanelOpendFirst = true;
        }
        this.mobileTabs?.removeAttribute("style");
        this.oldexp?.removeAttribute("style");
    }
    openMobTab(type) {
        if (type) {
            if (type == 'benefits') {
                this.isPanelOpendFirst = false;
                this.isPanelOpendFirst_1 = true;
            }
            if (type == 'suggested') {
                if (this.isPanelOpendFirst) {
                    this.isPanelOpendFirst_1 = true;
                    this.isPanelOpendSecond = true;
                    this.isPanelOpendFirst = false;
                }
                else {
                    this.isPanelOpendSecond = true;
                    this.isPanelOpendFirst = false;
                }
            }
            if (type == 'moreInfo') {
                if (this.isPanelOpendFirst) {
                    this.isPanelOpendFirst_1 = true;
                    this.isPanelOpendThird = true;
                    this.isPanelOpendFirst = false;
                }
                else {
                    this.isPanelOpendThird = true;
                }
            }
        }
    }

    writeCustomerReview() {
        this.anchors = this.ReviewElement?.nativeElement.getElementsByClassName('jdgm-write-rev-link');
        this.anchors[0]?.removeAttribute('href');
        this.anchors[0]?.setAttribute('display', 'block');
        var _this = this;
        this.reviewMediaFile = [];
        this.addVideo = false;
        this.addImage = false;
        $(document).on("click", ".jdgm-write-rev-link", () => {
            window.scroll({ top: 9999, left: 100, behavior: "smooth", });
            _this.reviewSection = !_this.reviewSection;
            if (sessionStorage.getItem('CustomerReviewMessage')) {
                _this.customerReviewForm.patchValue({
                    Name: '',
                    Email: '',
                    ReviewTitle: '',
                    WriteYourComment: '',
                    reviewPicture: '',
                    reviewVideo: '',
                    YoutubeURL: ''
                });
            }
        });
    }
    padStart(string, targetLength, padString) {
        if (String.prototype.padStart) {
            return string.padStart(targetLength, padString);
        }
    
        targetLength = targetLength >> 0; 
        padString = String(padString || ' ');
        if (string.length > targetLength) {
            return String(string);
        }
    
        targetLength = targetLength - string.length;
        if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length);
        }
    
        return padString.slice(0, targetLength) + String(string);
    }

    addTimestamp() {
        const momentDate = this.ReviewElement?.nativeElement.getElementsByClassName('jdgm-rev__timestamp'); 
        _.each(momentDate, (ele) => {
            let date = ele.dataset.content; let momentObj = moment(date, 'YYYY-MM-DD HH:mm:ss UTC'); 
            if (momentObj.isValid()) {
                let monthNumber = momentObj.month() + 1;
                let dayNumber = momentObj.date();
                let year = momentObj.year();
                let month = monthNumber < 10 ? '0' + monthNumber : monthNumber;
                let day = dayNumber < 10 ? '0' + dayNumber : dayNumber;
                ele.innerHTML = `${month}/${day}/${year}`;
            }
            else { console.error('Invalid date format:', date); }
        });
    }

    clickOnTab(item?: any) {
        var taeb = $("ul.nav.nav-tabs");
        taeb.find(".nav-link").on("click", function () {
            var $this = $(this);
            if ($this.hasClass("activeb")) return;
            var direction 
            if(item?.LongDetail && item?.LongDetail3 && item?.LongDetail4){
                direction = $this.attr("tab-direction");
            }
            else{
                if($this.attr("tab-direction") == 'left'){
                    direction = 'left';
                }else{
                    direction = 'middle-special';
                }
            }
            
            taeb.removeClass("left middle right middle-special").addClass(direction);
            taeb.find(".nav-link.active").removeClass("active");
            $this.addClass("active");
        });
    }

    getAllProduct(webCategoryId, customertype): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            if(webCategoryId === 472 && sessionStorage.getItem("AllProducts472") !== null && sessionStorage.getItem("AllProducts472") !== undefined){
                let allProductInfo = JSON.parse(sessionStorage.getItem("AllProducts472"));
                resolve(allProductInfo);
            }
            else{
                const request = {
                    'EnrollmentType': '',
                    'CustomerType': this._auth.isLoggedIn() ? customertype : 22,
                    'WebCategoryId': webCategoryId,
                    'isUpgradeCustomer': false,
                    'IsSubscriptionOrder': webCategoryId == 466 ? true : false,
                    'PriceType': ''
                };
                this._restService.getProducts(request).subscribe((res: any) => {
                        let allProductInfo = res.Items;
                        resolve(allProductInfo);
                });
            }
        })
        return promise;
    }

    GetAllJudgMeProducts() {
        this._restService.GetAllJudgMeProducts().subscribe((items: any) => {
            items.forEach((item) => {
                if (this.item && this.item.ItemCode == item.ItemCode) {
                    this.getReviews(item.Handle, 1);
                    this.handleReview(item.Handle);
                    this.itemJudgmeRes = item;
                }
            });
        });
        
    }
    convertSpecialCharacters(text) {
        return text.replace(/â€™/g, "'")
                   .replace(/â€˜/g, "'")
                   .replace(/â€œ/g, '"')
                   .replace(/â€/g, '"')
                   .replace(/â€¦/g, '...')
                   .replace(/â€“/g, '-')
                   .replace(/â€¢/g, '*')
                   .replace(/â€/g, '•')
                   .replace(/â€º/g, '›')
                   .replace(/â€º/g, '‹')
                   .replace(/ðŸ™/g, '🙏')
                   .replace(/ðŸ¥°/g, '😰')
                   .replace(/ðŸ¦‹/g, '🦋')
                   .replace(/ðŸ¿/g, '🏿');
    }
    getReviews(product, page) {
        let a = product.split(' ');
        let b = a.join('-');
        this._restService.JudgeMeReviews(b, page).subscribe((res: any) => {
            let c = res.widget.split("data-number-of-reviews='");
            let d = c[1].split("'");
            this.reviews = d[0];
            let responseHtml = res?.widget;
            responseHtml = responseHtml.replaceAll("data-src", "src")

            if (responseHtml) {
                this.reviewList = this.convertSpecialCharacters(responseHtml);
                var _self = this;
                this.waitForElement('jdgm-write-rev-link', function () { _self.writeCustomerReview() });
                this.waitForElement('jdgm-rev__timestamp', function () { _self.addTimestamp() });
            }

        })
    }

    getUserData(customerDetail) {
        if (this._auth.isLoggedIn()) {
            this.customertype = customerDetail.CustomerType || 22;
            this.getAllProduct(482, this.customertype);
            this.getAllProduct(472, this.customertype);
        }
    }

    gotoReviews() {
        let el = this.ReviewElement.nativeElement
        el.scrollIntoView();
    }

    addReviewPicture(event, type) {
        const file: File = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("reviewImage", file);
            this._http.post('https://file.io', formData, { reportProgress: true, observe: 'events' })
                .subscribe((event) => {
                    let result = JSON.stringify(event);
                    let resultBody = JSON.parse(result);
                    this.reviewMediaFile.push(resultBody.body.link);
                    if (event.type === HttpEventType.UploadProgress) {
                    } else if (event instanceof HttpResponse) {
                        if (type == 'picture') {
                            this.customerReviewImage = file.name;
                            this.addImage = true;
                        } else if (type == 'video') {
                            this.customerReviewVideo = file.name;
                            this.addVideo = true;
                        }
                    }
                });
        }
    }


    submitReview() {
        this.customerReview = this.customerReviewForm.value;
        if (this.customerReviewForm.invalid) {
            this._toastr.error('Please field the required');
            return this.validateAllFormFields(this.customerReviewForm);
        }
        const request = {
            "shop_domain": "puriumreviews.com",
            "platform": "woocommerce",
            "id": this.itemJudgmeRes.ExternalId,
            "email": this.customerReview.Email,
            "name": this.customerReview.Name,
            "reviewer_name_format": "",
            "rating": this.rating,
            "title": this.customerReview.ReviewTitle,
            "body": this.customerReview.WriteYourComment,
            "cf_answers": [{ "cf_question_id": 0, "value": "" }],
            "picture_urls": this.reviewMediaFile,
            "ip_addr": ""
        }
        this._restService.CreateProductReview(request).subscribe({
            next: (result) => {
                this.reviewSection = false;
                sessionStorage.setItem('CustomerReviewMessage', result['message']);
                this.reviewMediaFile = [];
            }, error: (error) => {
                console.log('error', error);
            },
            complete: () => {
            }
        })
    }
    ultlifestylePoup(itemCode, quantity?: any, type?: any,sideNav?: MatSidenav) {
        const dialogRef = this._matDialog.open(LifestyleSubscriptionComponent, {
            width: '500px',
            maxWidth: '500px',
            height: '456px',
            panelClass: '',
            disableClose: true,
            data: {
                item: itemCode.ItemCode,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
                if (itemCode.KitMembers && itemCode.KitMembers.length > 0) {
                    const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                        width: '600px',
                        panelClass: ['order-sub-item', 'sub-item-modal'],
                        data: {
                            item: itemCode,
                            type: type
                        }
                    });
                    dialogRef.afterClosed().subscribe(results => {
                        if (results.event == 'submit') {
                            this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
                        }
                    });
                } else {
                    this._sharedMethodService.ContinueRetailOrder(itemCode, quantity, type);
                    this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
                }
            }
        });
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    ngOnDestroy(): void {
        this._sharedMethodService.headerSearchValue = '';
        clearTimeout(this.clear)
    }
}