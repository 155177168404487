import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(value: any, keys: string, term: string) {
    if (!term) {
      return value;
    }
    const searchMapping = {
      'ItemCode': 0,
      'Description': 1,
      'ShortDetail': 2,
      'LongDetail': 3,
      'LongDetail1': 4,
      'LongDetail2': 5
    }

    // Escape special characters in the search term
    const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    if (term == 'special' || term == 'Special') {
      let CustomFilter = JSON.parse(sessionStorage.getItem("blackFridayProducts"));
      return CustomFilter;
    }
    else {
      const filteredItems = [];
      let keyArray = keys.split(',');
      if (value.length > 0) {
        value.forEach((item) => {
          keyArray.forEach((key) => {
            if (item.hasOwnProperty(key) && new RegExp(escapedTerm, 'gi').test(item[key])) {
              const existItem = filteredItems.find(i => i.ItemCode == item.ItemCode);
              if (!existItem) {
                filteredItems.push({ ...item, source: key });
              }
            }
          });
        });

        const sortedArray = filteredItems.sort((a, b) => searchMapping[a.source] - searchMapping[b.source]);
        return sortedArray;
      }
    }
  }
}