import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NotTranslatedService {
    notTranslated(key: string): void {
        console.log('Not translated', key);
    }
}
