import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    enrollSession: any;
  constructor(private _router: Router) { }

  sendToken(token: any): void {
    localStorage.setItem('authtoken', JSON.stringify(token.access_token));
  }

  sendUserData(data: string): void {
    localStorage.setItem('LoggedInUserData', data);
  }

  getToken(): any {
    return localStorage.getItem('authtoken');
  }

  getUserData(): any {
    return localStorage.getItem('LoggedInUserData');
  }


  isLoggedIn(): any {
    return this.getToken() !== null;
  }

  sendLanguageMenu(data: any): void {
    localStorage.setItem('LanguageMenuList', data);
  }
  getLanguageMenu(): any {
    return JSON.parse(localStorage.getItem('LanguageMenuList'));
  }

  sendDefaultCountryCode(data: any): void {
    localStorage.setItem('DefaultCountryCode', data);
  }
  sendDefaultLanguageCountryCode(data: any): void {
    localStorage.setItem('DefaultLanguageCountryCode', data);
  }
  logout(): void {
    localStorage.removeItem('LoggedInUser');
    localStorage.removeItem('LoggedInUserData');
    localStorage.removeItem('LanguageMenuList');
    localStorage.removeItem('DefaultCountryCode');
    localStorage.removeItem('DefaultLanguageCountryCode');
    this._router.navigate(['login']);
  }
  sendBillingInfo(data): any {
    return localStorage.setItem('billingInfo', data)
  }
    clearLoggedInData(): any {
        localStorage.removeItem('LoggedInUser');
        localStorage.removeItem('LoggedInUserData');
        this.enrollSession = JSON.parse(localStorage.getItem('EnrollSession'));
        this.enrollSession.IsLoggedIn = false;
        this.enrollSession.LoggedInUserData = {};
        localStorage.setItem('EnrollSession', JSON.stringify(this.enrollSession));
    }
}
