<!-- ======================= PAGE CONTENT ====================================== -->
<app-header></app-header>
<div id="IncomeOpportunity-page" fxLayout="column">
    <div class="container" fxLayout="column" fxLayoutAlign="center center" fxFlex="80">
        <h1 class="heading">
            Get your own personalized Gift Code <br>
            to start building your own team today!
        </h1>
        <div class="lifestyle-guide-section" id="startFirst">
            <iframe scrolling="no" frameborder="0" loading="lazy" allowfullscreen="allowfullscreen" allow="accelerometer; autoplay; encrypted-media; fullscreen *; gyroscope; picture-in-picture;" src="https://online.flippingbook.com/view/258138394/" width="100%" class="fbo-embed" title="title">
            </iframe>
            <p class="tagline">
                Say “YES” to the lifestyle you desire!
            </p>
            <a href="https://bit.ly/IncomeOpportunityGuide" target="_blank" class="fbo-embed lgs-sec-btn" data-fbo-id="912d78b44d" data-fbo-ratio="3:2" data-fbo-lightbox="yes" data-fbo-width="100%" data-fbo-height="auto" data-fbo-version="1" style="max-width: 100%">View Guide</a> 
        </div>
    </div>
    <app-footercomponent style="width: 100%;"></app-footercomponent>
</div>