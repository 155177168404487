import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "app/shared/auth.service";
import { EnrollSessionService } from "app/shared/enrollSession.service";
import { RestService } from "app/shared/rest.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-faq-chatbox',
  templateUrl: './faq-chatbox.component.html',
  styleUrls: ['./faq-chatbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FAQChatBoxComponent implements OnInit {
  isActive = 0;
  faq = {};
  subfaq = {};
  faqc = {};
  subfaqc = {};
  preState = 1;
  preSubState = 1;
  preCatState = 1;
  preSubCatState = 1;
  searchedValue: any ="";
  showSearchIcon: boolean = true;
  allfaqs:any =[];
  allfaqsList:any=[];
  LCTypes: any = [];
  BPTypes: any = [];
  @Input() faqsList:any;
  @Input() faqsCatList:any;
  @Input() isChatbotOpen:boolean;

  constructor(
    public restService: RestService,
    private _toastr: ToastrService,
    public authService: AuthService,
    public _enrollSession: EnrollSessionService,
  ) {
    this.LCTypes = [1, 2, 11, 14, 18, 20, 22, 26, 27, 28];
    this.BPTypes = [3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 15, 16, 17, 19, 21, 23, 24, 25];
  }

  ngOnInit(): void {
    this.faqsCatList.forEach((category) => {
      category.faqs.forEach(faq => {
        this.allfaqsList.push(faq);
      });
      category.subCategory.forEach((subcategory) => {
        subcategory.faqs.forEach(faq => {
          this.allfaqsList.push(faq);
        });
      });
    });
    this.faqsCatList = this.CategoryFilterList();
    this.faqsList = this.AllFaqFilterList(this.faqsList, 2);
    this.allfaqs = this.filterAllFaqs();
  }
  handleFaq(faq, type?: string) {
    if(type == 'subcat'){
      this.preSubState = this.isActive;
      this.isActive = 6;
      this.subfaq = faq;
    }
    if(type == "cat"){
      this.preState = this.isActive;
      this.isActive = 3;
      this.faq = faq;
    }
  }
  handleFaqCat(faqc) {
    this.preCatState = this.isActive;
    this.isActive = 4;
    this.faqc = this.AllFaqFilterList(faqc, 1);
    
  }
  handleFaqSubCat(faqc) {
    this.preSubCatState = this.isActive;
    this.isActive = 5;
    this.subfaqc = this.AllFaqFilterList(faqc, 1);
  }
  navigateToSupportTeam()
  {
    window.open("https://ishoppurium.com/documents/support", "_blank");
  }
  onSearchChange(event) {
    if (event?.target?.value != undefined && event?.target?.value != null && event?.target?.value.length > 2) {
      const excludedWords = ["the", "and", "any"];
      const words = event.target.value.toLowerCase().split(" ");
      const filteredWords = words.filter(word => !excludedWords.includes(word));
      this.searchedValue = filteredWords.join(" ");
      this.showSearchIcon = false;
    }
    else
    {
     this.showSearchIcon = true;
    }
  }

  AllFaqFilterList(faqList, step) {
    if (this.authService.isLoggedIn()) {
      const Customer = this.LCTypes.find((cType) => cType === this._enrollSession.data?.LoggedInUserData?.CustomerType);
      const BP = this.BPTypes.find((bpType) => bpType === this._enrollSession.data?.LoggedInUserData?.CustomerType);
      const filterFAQs = (faqs) => {
        return faqs.filter(faq => {
          if (faq.accessType.length > 0) {
            if (Customer && !BP && !faq.accessType.includes("All")) {
              return faq.accessType.includes("Customer") || !faq.accessType.includes("BP");
            } else if (BP && !Customer && !faq.accessType.includes("All")) {
              return faq.accessType.includes("BP") || !faq.accessType.includes("Customer");
            } else {
              return !faq.accessType.includes("BP") && !faq.accessType.includes("Customer");
            }
          } else {
            return !faq.accessType.includes("BP") && !faq.accessType.includes("Customer");
          }
        });
      };

      const filterSubCategories = (subCategories) => {
        return subCategories.filter(subCat => {
          if (subCat.accessType.length > 0) {
            if (Customer && BP && !subCat.accessType.includes("All")) {
              return subCat.accessType.includes("Customer") || subCat.accessType.includes("BP");
            } else if (Customer && !BP && !subCat.accessType.includes("All")) {
              return subCat.accessType.includes("Customer") || !subCat.accessType.includes("BP");
            } else if (BP && !Customer && !subCat.accessType.includes("All")) {
              return subCat.accessType.includes("BP") || !subCat.accessType.includes("Customer");
            } else {
              return !subCat.accessType.includes("BP") && !subCat.accessType.includes("Customer");
            }
          } else {
            return !subCat.accessType.includes("BP") && !subCat.accessType.includes("Customer");
          }
        }).map(subCat => ({
          ...subCat,
          faqs: subCat?.faqs && subCat.faqs.length > 0 && filterFAQs(subCat.faqs),
          subCategory: subCat?.subCategory && subCat.subCategory.length > 0 && this.AllFaqFilterList(subCat, 1) // Recursive call for nested subcategories
        }));
      };

      if (step == 1) {
        const filteredFAQs = (faqList?.faqs && faqList.faqs.length > 0 && filterFAQs(faqList.faqs));
        const filteredSubCategories = faqList?.subCategory.length > 0 && filterSubCategories(faqList.subCategory);

        return {
          ...faqList,
          faqs: filteredFAQs,
          subCategory: filteredSubCategories
        };
      }
      if(step==2){
        const filteredFAQs = filterFAQs(faqList);
        return filteredFAQs;
      }
    }
    else {
      const filterPublicFAQs = (faqs) => {
        return faqs.filter(faq => !faq.accessType || faq.accessType.length === 0 || faq.accessType.includes("All"));
      };
      const filterPublicSubCategories = (subCategories) => {
        return subCategories.filter(subCat => !subCat.accessType || subCat.accessType.length === 0).map(subCat => ({
          ...subCat,
          faqs: subCat?.faqs && subCat.faqs.length > 0 && filterPublicFAQs(subCat.faqs),
          subCategory: subCat?.subCategory && subCat.subCategory.length > 0 && this.AllFaqFilterList(subCat, 1) // Recursive call for nested subcategories
        }));
      };
      if (step == 1) {
        const publicFAQs = (faqList?.faqs && faqList.faqs.length > 0 && filterPublicFAQs(faqList.faqs));
        const publicSubCategories = faqList?.subCategory.length > 0 && filterPublicSubCategories(faqList.subCategory);
    
        return {
          ...faqList,
          faqs: publicFAQs,
          subCategory: publicSubCategories
        };
      }
      if(step==2){
        const publicFAQs = filterPublicFAQs(faqList);
        return publicFAQs; 
      }
    }
  }

  CategoryFilterList(){
    const Customer = this.LCTypes.find((cType) => cType === this._enrollSession.data?.LoggedInUserData?.CustomerType);
    const BP = this.BPTypes.find((bpType) => bpType === this._enrollSession.data?.LoggedInUserData?.CustomerType);

    this.faqsCatList = this.faqsCatList.filter((cat)=>{
      if(cat.accessType.length > 0){
        if (Customer && !BP && !cat.accessType.includes("All")) {
          return cat.accessType.includes("Customer") || !cat.accessType.includes("BP");
        } 
        else if (BP && !Customer && !cat.accessType.includes("All")) {
          return cat.accessType.includes("BP") || !cat.accessType.includes("Customer");
        } 
        else {
          return !cat.accessType.includes("BP") && !cat.accessType.includes("Customer");
        }
      }
      else{
        return !cat.accessType.includes("BP") && !cat.accessType.includes("Customer");
      }
    })
    return this.faqsCatList;
  }

  filterAllFaqs()
  {
    if (this.authService.isLoggedIn()) {
      const userType = this._enrollSession.data?.LoggedInUserData?.CustomerType;
      const isCustomer = this.LCTypes.includes(userType);
      const isBP = this.BPTypes.includes(userType);
      if (isCustomer) {
        this.allfaqsList = this.allfaqsList.filter((faq) => faq.accessType && faq.accessType.length !== 0 && (!faq.accessType.includes("BP")));
      }
      else if(isBP){
        this.allfaqsList = this.allfaqsList.filter((faq) => faq.accessType && faq.accessType.length !== 0 && (!faq.accessType.includes("Customer")));
      }
    } else {
      this.allfaqsList = this.allfaqsList.filter((faq) => !faq.accessType || faq.accessType.length === 0 || faq.accessType.includes("All"));
    }
    return this.allfaqsList;
  }
}
