import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { BrowserModule } from '@angular/platform-browser';
import { footerModule } from "../../shared/footer/footer.module";
import { AmyAndDaveComponent } from './amyAndDave.component';

const routes: Routes = [
    {
        path: 'amy-and-dave',
        component: AmyAndDaveComponent,
        pathMatch: 'full',
    }
];

@NgModule({
    declarations: [
        AmyAndDaveComponent
    ],
    entryComponents: [],
    providers: [],
    bootstrap: [AppComponent],
    imports: [
        RouterModule.forChild(routes),
        BrowserModule,
        footerModule,
    ]
})
export class  AmyAndDaveModule {}

