import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';
import { OrderSubItemComponentCustom } from 'app/shared/Orders-Sub-Item-Custom/Orders-Sub-Item-Custom.component';
import { productInfoComponent } from 'app/shared/Product-info/Product-info.component';
import { AppComponent } from '../../app.component';
import { StepperModule } from '../stepper/stepper.module';
import { OrderReviewComponent } from './order-review.component';
import { CommonModule } from '@angular/common';
import { footerModule } from 'app/shared/footer/footer.module';
import { LifestyleSmartorderSubscriptionModule } from 'app/shared/LifestyleSmartorderSubscription/LifestyleSmartorderSubscription.module';

const routes: Routes = [
  {
      path: 'OrderReview',
      component: OrderReviewComponent
  }
];


@NgModule({
  declarations: [
        OrderReviewComponent,
        OrderSubItemComponentCustom
  ],
  imports: [
      RouterModule.forChild(routes),
      MatButtonModule,
      CommonModule,
      MatFormFieldModule,
      MatIconModule,
      MatMenuModule,
      MatTableModule,
      MatTabsModule,
      NgxChartsModule,
      MatExpansionModule,
      SummaryCartModule,
      FlexLayoutModule,
      MatDialogModule,
      StepperModule,
      footerModule,
      LifestyleSmartorderSubscriptionModule
      
  ], entryComponents: [
      productInfoComponent,
      OrderSubItemComponent,
      OrderSubItemComponentCustom
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class OrderReviewModule { }
