<app-header></app-header>
<div id="incentive">
    <div style="max-width: 1620px; margin:auto;">
        <div>
            <img class="img-fluid w-100" style="margin:auto;" src="../../../../assets/img/incentives-1.png" />
        </div>
        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button heading" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                        (click)="toggleFirstPurchase()">
                        Purium's Incentives Menu
                        <i class="fa-solid fa fa-sort icon"></i>
                    </button>
                </h2>
                <div *ngIf="showList" id="collapseOne" class="accordion-collapse collapse show"
                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body" style="background-color: #f8f8f8;">
                        <ul class="list-unstyled">
                            <li class="my-li"><a (click)="smoothscroll('#FirstPurchase')"
                                    class="hover-green-according accordion-body-text">First Purchase Incentives</a></li>
                            <li class="my-li"><a (click)="smoothscroll('#loyalcus')"
                                    class="hover-green-according accordion-body-text">25% Loyal Customer</a></li>
                            <li class="my-li"><a (click)="smoothscroll('#smartorder')"
                                    class="hover-green-according accordion-body-text">Monthly Subscription</a></li>
                            <li class="my-li"><a (click)="smoothscroll('#rewards')"
                                    class="hover-green-according accordion-body-text">Rewards
                                    Points</a></li>
                            <li class="my-li"><a (click)="smoothscroll('#3andfree')"
                                    class="hover-green-according accordion-body-text">3 &
                                    Then Free</a></li>
                            <li class="my-li"><a (click)="smoothscroll('#upgrade')"
                                    class="hover-green-according accordion-body-text">Upgrade
                                    to a Brand Partner</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="incentive-container">
            <div class="content">
                <h1>The More You Spend, <br> The More You Save</h1>
                <p>Use your Brand Partner's Gift Code and save on your first Purium order. <br>
                    The more you shop, the more you save. Discounted pricing begins when your first purchase <br> is at
                    least $75.
                    Subscribe to Monthly Subscription upon checkout for future self & savings.
                </p>
            </div>

            <div class="star-images">
                <img src="../../../../assets/img/Incentives3&free/Star.png" alt="Star Icon" class="star top-left">
                <img src="../../../../assets/img/Incentives3&free/Star.png" alt="Star Icon" class="star bottom-left">
                <img src="../../../../assets/img/Incentives3&free/Star.png" alt="Star Icon" class="star top-right">
                <img src="../../../../assets/img/Incentives3&free/Star.png" alt="Star Icon" class="star middle-right">
                <img src="../../../../assets/img/Incentives3&free/Star.png" alt="Star Icon" class="star bottom-right">
            </div>
        </div>
        <div class="offers-container">
            <div class="offer-card">
                <h3>$50 Off</h3>
                <p>Shop $75</p>
            </div>
            <div class="offer-card">
                <h3>25% Off</h3>
                <p>Shop $200+</p>
            </div>
            <div class="offer-card">
                <h3>Save $10 on S&H</h3>
                <p>with orders of $250 or more!</p>
            </div>
        </div>
        <div class="padding">
            <div class="divider-line"></div>
        </div>
        <div class="promo-card">
            <div class="promo-content">
              <img src="../../../../assets/img/Jar_Incentives_Banner.png" alt="Promo Image" class="promo-image">
              <div class="promo-text">
                <h2>RETURNING CUSTOMERS <br> SHOP MORE, SAVE MORE</h2>
              </div>
            </div>
         </div>
          
        <div class="save-container">
            <div class="save-content">
                <p>Order every month to maximize your savings.</p>
            </div>
            <div class="save-star-images">
                <img src="../../../../assets/img/Incentives3&free/Star.png" alt="Star Icon" class="star top-left-save">
                <img src="../../../../assets/img/Incentives3&free/Star.png" alt="Star Icon"
                    class="star bottom-right-save">
            </div>
        </div>
        <!-- Returning Customers -->
        <style>
            .image_off,
            .loyalcus:hover .image_on {
                display: none
            }

            .image_on,
            .loyalcus:hover .image_off {
                display: block
            }
        </style>
        <div id="loyalcus">
            <div class="container text-center">
                <div class="row">
                    <div class="col ">
                        <a class="loyalcus"><img class="image_on loyalcusImage"
                                src="../../../../assets/img/logos/inc-1.png" alt="" style="margin:auto;" /><img
                                class="image_off loyalcusImage" src="../../../../assets/img/logos/inc-12.png" alt=""
                                style="margin:auto;" /></a>
                    </div>
                    <div class="col">
                        <a class="loyalcus"><img class="image_on loyalcusImage"
                                src="../../../../assets/img/logos/inc-2.png" alt="" style="margin:auto;" /><img
                                class="image_off loyalcusImage" src="../../../../assets/img/logos/inc-22.png" alt=""
                                style="margin:auto;" /></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <a class="loyalcus"><img class="image_on loyalcusImage"
                                src="../../../../assets/img/logos/inc-4.png" alt="" style="margin:auto;" />
                            <img class="image_off loyalcusImage" src="../../../../assets/img/logos/inc-33.png" alt=""
                                style="margin:auto;" />
                        </a>
                    </div>
                    <div class="col">
                        <a class="loyalcus"><img class="image_on loyalcusImage"
                                src="../../../../assets/img/logos/inc-3.png" alt="" style="margin:auto;" />
                            <img class="image_off loyalcusImage" src="../../../../assets/img/logos/inc-45.png" alt=""
                                style="margin:auto;" />

                        </a>
                    </div>

                </div>
            </div>
        </div>
        <div class="padding">
            <div class="divider-line"></div>
        </div>
        <!-- Smart Order Subscriptions Incentives -->
        <ng-container id="smartorder">
            <div id="smartorder" class="banner-images mt-5 collection-padding-subscribe">
                <img class="banner-image-width-1" src="../../../assets/images/Banner/MonthlyWeb.png">
                <h1 class="calender-text"><i>Automate your superfood delivery!</i></h1>
                <img class="banner-image-width-2" src="../../../assets/images/Banner/calender-img.png">
            </div>
            <div>
                <div class="p-5">
                    <div class="flex-monthly-div">
                        <div class="borderRewardBox">
                            <img width="100" src="../../../../assets/img/reward white 1.png">
                            <div class="rewardText">Earn 50% more Rewards Points on every order</div>
                        </div>
                        <div class="borderDiscountBox">
                            <img width="100" src="../../../../assets/img/discount white_ 1.png">
                            <div class="rewardText">Ensure 25% discount by ordering every 30 days</div>
                        </div>
                        <div class="borderAnnualBox">
                            <img width="100" src="../../../../assets/img/annual white_ 1.png">
                            <div class="rewardText">Qualify for a free product in your 4th month with 3 & Then Free Products.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="processing-container">
                <div class="processing-content">
                    <p>Processing Type:</p>
                </div>
            </div>
            <div class="container text-center processType">
                <div class="row mt-5">
                    <div class="col flex-div">
                        <img class="img-fluid time-box" src="../../../../assets/img/logos/inc-time.png" />
                        <div class="flex-div1 margintop">
                            <h1 class="lora-bold-text">Always <br>Ship</h1>&nbsp;&nbsp;
                            <img (click)="OpenAlwaysShipPopup(1)" class="margin-info"
                                src="../../../../assets/icons/info 9.png" width="20">
                        </div>
                    </div>
                    <div class="col flex-div">
                        <img class="img-fluid time-box" src="../../../../assets/img/logos/inc-box.png" />
                        <div class="flex-div1">
                            <h1 class="lora-bold-text">Backup <br>Order</h1>&nbsp;&nbsp;
                            <img (click)="OpenAlwaysShipPopup(2)" class="margin-info"
                                src="../../../../assets/icons/info 9.png" width="20">
                        </div>
                    </div>
                </div>
                <p class="mt-5 autoship-para">"Always Ship" is the default for most orders,<br>yet you can edit in your
                    iShopPurium.com
                    Account.</p>
            </div>

            <img class="img-fluid w-100 pb-5" style="margin:auto;" src="../../../../assets/img/updated_imgtext.png" />

        </ng-container>

        <div class="padding">
            <div class="divider-line"></div>
        </div>

        <!-- Reward Points Section -->
        <div id="rewards">
            <img class="img-fluid" style="margin:auto;" src="../../../../assets/img/updated_incentives-7.png" />
        </div>

        <!-- 3 & then free -->
        <div class="padding">
            <div class="divider-line"></div>
        </div>
        <div id="3andfree" class="text-center">
            <img class="img-fluid" style="margin:auto;" src="../../../../assets/img/Incentives3&free/3leaf01.png" />
        </div>
        <div id="3andfree" class="container text-center mt-5 mb-5">
            <div class="text-center free-div">
                <span class="free-h">How does it work?</span>
            </div>
            <h4 class="free-p">Purchase one of the products below for three consecutive months, and have an <br>
                active Smart Order, you receive
                the product for FREE in the fourth month.
            </h4>
            <div class="row mt-5 align-items-end align-items-media">
                <div class="col">
                    <img class="img-fluid img-width" style="margin:auto;"
                        src="../../../../assets/img/Incentives3&free/Heart-Aid.png" />
                    <p class="font-text m-0 pt-3">Heart Aid</p>
                </div>
                <div class="col">
                    <img class="img-fluid img-width" style="margin:auto;"
                        src="../../../../assets/img/Incentives3&free/EpiDeGenus.png" />
                    <p class="font-text m-0 pt-3">Epi-Genius Dogs & Cats
                    </p>
                </div>
                <div class="col">
                    <img class="img-fluid img-width" style="margin:auto;"
                        src="../../../../assets/img/Incentives3&free/Women's Defense.png" />
                    <p class="font-text m-0 pt-3">Women’s Defense</p>
                </div>
                <div class="col">
                    <img class="img-fluid img-width" style="margin:auto;"
                        src="../../../../assets/img/Incentives3&free/Men's Defense.png" />
                    <p class="font-text m-0 pt-3">Men’s Defense</p>
                </div>
            </div>
            <div class="row mt-5 align-items-baseline align-items-media">
                <div class="col">
                    <img class="img-fluid img-width" src="../../../../assets/img/Incentives3&free/SuperLytes.png" />
                    <p class="font-text m-0 pt-3">Super Lytes</p>
                </div>
                <div class="col">
                    <img class="img-fluid img-width"
                        src="../../../../assets/img/Incentives3&free/Control Pre-Meal Capsules.png" />
                    <p class="font-text m-0 pt-3">Control <br> Pre-Meal Capsules</p>
                </div>
                <div class="col">
                    <img class="img-fluid img-width" src="../../../../assets/img/Incentives3&free/KamutBlend01.png" />
                    <p class="font-text m-0 pt-3">Kamut Blend
                    </p>
                </div>
                <div class="col">
                    <img class="img-fluid img-width"
                        src="../../../../assets/img/Incentives3&free/Barley Green Juice.png" />
                    <p class="font-text m-0 pt-3">Barley <br> Green Juice
                    </p>
                </div>
            </div>
        </div>
        <div class="padding">
            <div class="divider-line"></div>
        </div>

        <div id="post"></div>
        <!-- Interested in Sharing Purium -->
        <div id="share" class="container text-center mt-5">
            <br><br>
            <h1 class="sharing-h1">Interested in Sharing Purium <br> with your own Gift Code?</h1>
            <div id="3andfree" class="text-center gift-banner">
                <img class="img-fluid" style="margin:auto;" src=".../../../assets/images/Banner/giftcode.png" />
            </div>
            <h4 class="pt-3 sharing-h2">How much you earn depends on how much you share.</h4>
            <div class="m-5">
                <h1 class="sharing-h3">Pay for Your Products</h1>
                <p class="sharing-p">Share products with people you know.</p>
                <hr class="mt-4 border-line">
            </div>
            <div class="m-5 pt-1 sharing_sub_section">
                <h1>Pay for Your Lifestyle</h1>
                <p>Build a team of Brand Partners<br>who love to change the world together.</p>
            </div>
            <hr class="mt-4 border-line">
        </div>
        <div id="upgrade" class="container text-center mb-5 pb-5">
            <h3><i>Reach out to the person who referred you to<br>Purium to learn more today!</i></h3>
        </div>
    </div>
</div>
<app-footercomponent></app-footercomponent>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">
