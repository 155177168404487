import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RgetRegexService } from '../../shared/getRegex.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CalculateAutoOrderService } from 'app/shared/calculateAutoOrder.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { startOfDay, addWeeks, subWeeks, startOfMonth, endOfWeek, subMonths, addMonths, endOfDay, subDays, addDays, endOfMonth, isSameDay, getMonth } from 'date-fns';
import { CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
type CalendarPeriod = 'month';
@Component({
    selector: 'Subscribe2',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './Subscribe2.component.html',
    styleUrls: ['./Subscribe2.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class Subscribe2Component implements OnInit {
    routPath: any = "Checkout";
    CalculateInitialOrderPrice: any = {};
    currentYear = new Date().getFullYear();
    viewDate: any = this._enrollSession.data.smartOrderDate ? this._enrollSession.data.smartOrderDate : addMonths(new Date(), 1);
    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    refresh: Subject<any> = new Subject();
    activeDayIsOpen: boolean = true;
    datesDisabled: any;
    _selectedDay: any;
    AutoShipStartDate: any;
    prevBtnDisabled: boolean = false;
    LoggedInUserData: any;
    nextBtnDisabled: boolean = false;
    checked: boolean = true;
    minDate: Date = subMonths(new Date(), 0);
    maxDate: Date = addMonths(new Date(), 1);
    disableDaysOfMonth = [22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    currentMonth=+new Date().getMonth()+1;
    subscribtionStatus: any;
    SubscriptionProcessType: any;
    siteType: any;
    customerDetail: any;
    authtoken: any;
    public userInfo: any = {};
    constructor(
        public _RgetRegexService: RgetRegexService,
        public _enrollSession: EnrollSessionService,
        public _calculateAutoOrder: CalculateAutoOrderService,
        private router: Router,
        private _toastr: ToastrService,
        public authService: AuthService,
        public _sharedMethodService: SharedMethodService
    ) {
        this.siteType = sessionStorage.getItem("siteType");
        if ((this._enrollSession.data?.RetailOrderSummary == undefined) || (this._enrollSession.data?.RetailOrderSummary && this._enrollSession.data?.RetailOrderSummary?.Enrollpack?.length == 0 && this._enrollSession.data?.RetailOrderSummary?.Enrollpack == undefined && this._enrollSession.data?.RetailOrderSummary?.Enrollpack?.length == 0) && (this.siteType && this.siteType != null)) {
            if (this._enrollSession.data?.RetailOrderSummary?.Enrollpack?.length == 0) {
                this._toastr.warning("Please select an item ", '');
            }
            this.router.navigate(['/AllProducts']);
        }
        if ((this._enrollSession.data?.RetailOrderSummary == undefined) || this._enrollSession.data.RetailOrderSummary?.InitialOrders == undefined || (this._enrollSession.data?.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary?.InitialOrders?.length == 0) && (this.siteType && (this.siteType == null || this.siteType == ''))) {
            this._toastr.warning("Please select an item", '');
            this.router.navigate(['/AllProducts']);
        }
        this.CalculateInitialOrderPrice = {};
        this.customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData')) || {}
        this.authtoken = localStorage.getItem('authtoken');
    }
    ngOnInit(): void {
        this._enrollSession.data.RetailOrderSummary.customerInfoData = this._enrollSession.data.RetailOrderSummary.customerInfoData || {};
        this._sharedMethodService.isOpen = true;
        this.SubscriptionProcessType = localStorage.getItem('SubscriptionProcessType') || "AutoOrderProcessTy1";
        localStorage.setItem("SubscriptionProcessType",this.SubscriptionProcessType);
    }
    dateIsValid(date: Date): boolean {
        return date.getMonth()+1!==this.currentMonth && date.getTime() > new Date().getTime() && (this.disableDaysOfMonth.indexOf(date.getDate()) == -1);
    }
    beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
        body.forEach(day => {
            if (!this.dateIsValid(day.date)) {
                day.cssClass = 'disabled-date';
            }
            const _selectedDay = moment(day.date).format('MM/DD/YYYY') === this._enrollSession.data.smartOrderDate ? day : "";
            if (_selectedDay) {
                _selectedDay.cssClass = 'cal-selected';
            }
        });
    }
    getFirstAndThirdWed(year, month) {
        let currmonth = (parseInt(month, 10) + 1);
        let arrdays = [];
        let dateinmonth = moment().daysInMonth();
        while (dateinmonth) {
            var current = moment().date(dateinmonth);
            arrdays.push(current);
            dateinmonth--;
        }

        var enabledDatesArr = [];
        for (var y = year; y <= 2030; y++) {
            for (var j = currmonth; j <= 12; j++) {
                for (var i = 22; i <= 31; i++) {
                    var dat = j + '/' + i + '/' + y;
                    enabledDatesArr.push(moment(dat).format('MM-DD-YYYY'));
                }
            }
        }
        arrdays.forEach(element => {
            if (moment(element._d).isSameOrBefore(moment())) {
                enabledDatesArr.push(moment(element._d).format('MM-DD-YYYY'));
            }
        });
        return enabledDatesArr;
    }
    changeDate(date: Date): void {
        this.viewDate = date;
        this.dateOrViewChanged();
    }
    monthIsValid(date: Date): boolean {
        return date >= this.minDate && date <= this.maxDate;
    }
    dateOrViewChanged(): void {
        this.prevBtnDisabled = !this.monthIsValid(
            this.endOfPeriod(this.viewDate, this.subPeriod('month', this.viewDate, 1))
        );
        this.nextBtnDisabled = !this.monthIsValid(
            this.startOfPeriod(this.viewDate, this.addPeriod('month', this.viewDate, 1))
        );
        if (this.viewDate < this.minDate) {
            this.changeDate(this.minDate);
        } else if (this.viewDate > this.maxDate) {
            this.changeDate(this.maxDate);
        }
    }
    increment() {
        this.changeDate(this.addPeriod('month', this.viewDate, 1));
    }
    decrement() {
        this.changeDate(this.subPeriod('month', this.viewDate, 1));
    }
    dayClicked({ date, }: { date: Date; }): void {
        var maxMonth = getMonth(this.maxDate);
        if ((getMonth(date) && getMonth(date) <= maxMonth) || (getMonth(date) == 0 && getMonth(date) <= maxMonth)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = moment(date).format('MM/DD/YYYY');
            this._enrollSession.data.smartOrderDate = this.viewDate;
            this.AutoShipStartDate = this.viewDate;
            localStorage.setItem("AutoShipStartDate", JSON.stringify(this.AutoShipStartDate));
        }
    }
    startOfPeriod(period: CalendarPeriod, date: Date): Date {
        return {
            day: startOfDay,
            month: startOfMonth,
        }['month'](date);
    }
    endOfPeriod(period: CalendarPeriod, date: Date): Date {
        return {
            day: endOfDay,
            week: endOfWeek,
            month: endOfMonth,
        }['month'](date);
    }
    addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
        return {
            day: addDays,
            week: addWeeks,
            month: addMonths,
        }['month'](date, amount);
    }
    subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
        return {
            day: subDays,
            week: subWeeks,
            month: subMonths,
        }['month'](date, amount);
    }
    processTypeChange(value) {
        localStorage.setItem("SubscriptionProcessType",value);
        if(value === 'AutoOrderProcessTy1'){
            this._sharedMethodService.processType = 'Always Ship';
        }
        else if(value === 'AutoOrderProcessTy2'){
            this._sharedMethodService.processType = 'Backup Order';
        }
    }
    OpenDesktopCart()
    {
        this._sharedMethodService.isOpen = true;
    }
}
