import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import * as _ from 'underscore';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryCartModule } from 'app/main/summary-cart/summary-cart.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from 'app/main/pipe/pipe.module';
import { StepperModule } from 'app/main/stepper/stepper.module';
import { AppComponent } from 'app/app.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { footerModule } from 'app/shared/footer/footer.module';
import { HomeComponent } from './home.component';

const routes: Routes = [
    {
        path: 'Home',
        redirectTo: '/homepage',
        pathMatch: 'full',
    }
];

@NgModule({
    declarations: [
        HomeComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatTabsModule,
        NgpSortModule,
        NgxChartsModule,
        FilterPipeModule,
        MatExpansionModule,
        SummaryCartModule,
        MatTooltipModule,
        FlexLayoutModule,
        PipesModule,
        StepperModule,
        footerModule,
        MatDialogModule,
        SlickCarouselModule
    ], entryComponents: [
        HomeComponent
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class HomeModule {
}

