import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'ship-popup-dialog',
  templateUrl: './ship-popup-dialog.component.html',
  styleUrls: ['./ship-popup-dialog.component.scss']
})
export class ShipPopupDialogComponent implements OnInit {
  currentRouterPath: any;
  public userLoginDialog: boolean = false;
  public userInfo: any = {};
  public loginDetails: any = {};
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<ShipPopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {

  }



  closePopup(){
    this.dialogRef.close();
  }
}
