import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as _ from 'underscore';
import { CommonModule } from '@angular/common';
import { Subscribe2Component } from './Subscribe2.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StepperModule } from '../stepper/stepper.module';
import { footerModule } from 'app/shared/footer/footer.module';
const routes: Routes = [
  {
    path: 'Subscribe-Date',
    component: Subscribe2Component
  }
];


@NgModule({
  declarations: [
    Subscribe2Component,
  ],
  imports: [
    RouterModule.forChild(routes),
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatTabsModule,
    NgpSortModule,
    MatStepperModule,
    NgxChartsModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    HttpClientModule,
    SummaryCartModule,
    MatRadioModule,
    FlexLayoutModule,
    StepperModule,
    footerModule

  ],
  exports: [
    FlexLayoutModule
  ],
  entryComponents: [
  ],
})
export class Subscribe2Module { }
