import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import * as _ from 'underscore';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { PasswordResetComponent } from './password-reset.component';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from 'app/app.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';

const routes: Routes = [
    {
        path: 'ResetPassword',
        component: PasswordResetComponent
    }
];

@NgModule({
    declarations: [
        PasswordResetComponent
    ],
    imports: [
    RouterModule.forChild(routes),
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatTabsModule,
    NgpSortModule,
    MatStepperModule,
    NgxChartsModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    HttpClientModule,
    SummaryCartModule,
    MatRadioModule,
    FlexLayoutModule
    ], entryComponents: [
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class PasswordResetModule {
}

