<div class="dialog-content-wrapper cookie-popup" [ngClass]="{'row': !mobileView}" id="two-way-wrapper">
    <mat-toolbar matDialogTitle class="m-0">
    </mat-toolbar>
    <div style="display:flex" class="pop-up-background" [ngClass]="{'row': !mobileView}">
        <div class="pop-up-div p-10" fxLayout.lt-sm="column">
            <div class="matIconContainerMobile"><mat-icon (click)="closeCookie('false')">close</mat-icon>
            </div>
            <div fxFlex fxLayout="row" fxLayout.lt-sm="column">
                <p class="fs-13">We use essential cookies to make our site work. With your consent, we may also use non-essential
                    cookies to improve user experience and analyze website traffic. <br> By clicking “Accept,” you agree to our
                    website's cookie use.</p>
                <div fxLayout="row" class="m-t-20 accept-botton"
                    fxLayout-align="center center" fxLayoutGap="10px" layout-wrap>
                    <a type="submit" class="black-color upper-case f-s-13" color="primary" mat-raised-button
                        (click)="closeCookie('false')">Reject</a>
                    <button type="submit" class="btn-project upper-case f-s-13" color="primary" mat-raised-button
                        (click)="closeCookie('true')">Accept Cookies</button>
                </div>
                <div class="matIconContainer"><mat-icon (click)="closeCookie('false')">close</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>


