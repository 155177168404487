<div class="dialog-content-wrapper" id="two-way-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
    </mat-toolbar>
    <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
      <div [ngClass]="{'pop-up-div': !isReset, 'new-heigh': isReset}" id="pop-up-div" style="margin-top: 150px !important;">
        <div class="matIconContainer"><mat-icon (click)="closeDialog()">close</mat-icon>
        </div>
        <div class="pop-up-button-div customer_dialog" *ngIf="!userLoginDialog">
          <button mat-button class="pop-up-button pop-up-button-no w-button" (click)="newCustomer()">NEW CUSTOMER</button>&nbsp;&nbsp;
          <button mat-button aria-current="page" class="pop-up-button w-button w--current"
            cdkFocusInitial (click)="returingCustomer()">RETURNING CUSTOMER</button>
        </div>
        <div class="pop-up-login-div" *ngIf="userLoginDialog && !isReset" id="login_container">
          <h3 class="text-center p-t-20 f-s-24 text-head">Customer Login</h3>
          <form [formGroup]="customerLoginForm" name="Login" id="login">
              <div *ngIf="!validateShow" fxLayout="column" class="p-20-15-10">
                  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                      <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" style="max-width:400px;margin:auto">
                          <div>
                              <input type="text" class="form-field w-input input-field" maxlength="256"
                                  name="userName" formControlName="userName" placeholder="Login Name"
                                  id="Email" [(ngModel)]="userInfo.userName" required>
                          </div>
                          <div class="error-box text-left">
                              <span class="help-block"
                                  *ngIf="!customerLoginForm.get('userName').valid && customerLoginForm.get('userName').touched">
                                  <span
                                      *ngIf="customerLoginForm.get('userName').errors['required']">This
                                      field is required</span>
                              </span>
                          </div>
                      </div>
                  </div>
                  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                      <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" style="max-width:400px;margin:auto" class="passwordDiv">
                          <div style="display: flex;">
                              <input [type]="show ? 'text' : 'password'" class="form-field w-input"
                                  [(ngModel)]="userInfo.Password" maxlength="256" name="Password"
                                  formControlName="password" placeholder="Password" id="password"
                                  required>
                                  <span class="toggleEye" (click)="toggleShowHide()"><i class="fa fa-eye" *ngIf="show"></i><i class="fa fa-eye-slash" *ngIf="!show"></i></span>
                          </div>
                          <div class="error-box text-left">
                              <span class="help-block"
                                  *ngIf="!customerLoginForm.get('password').valid && customerLoginForm.get('password').touched">
                                  <span
                                      *ngIf="customerLoginForm.get('password').errors['required']"
                                      >This field is required</span>
                              </span>
                          </div>
                      </div>
                  </div>
              </div>
              <br>
              <div *ngIf="!validateShow" fxLayout="row" fxLayout-sm="column" class="m-t-20 login-bottom" 
                  fxLayout-xs="column" fxLayout-align="start center"
                  fxLayout-align-xs="center start" fxLayout-align-sm="center start" layout-wrap>
                  <button type="submit" class="btn-project w-80 upper-case f-s-18" color="primary"
                      mat-raised-button (click)="SignIn()" [disabled]="appLoader">SIGN IN</button>
              </div>
              <div *ngIf="validateShow" style="font-size: 18px;width: 420px;margin: 0 auto;">
                Reset password is required for security purposes. Click the reset password link below and a reset password email will be sent to you.
            </div>
              <div layout="row" class="m-t-30 login-bottom resetbtnFlex" layout-wrap>
                  <div>
                  <a class="reset-pass cursor-pointer m-t-20" id="" (click)="resetPassword('username')">Forgot Login Name</a>
                  </div>
                  <div>
                  <a class="reset-pass cursor-pointer m-t-20" id="" (click)="resetPassword()">Reset Password</a>
                  </div>
                </div>
          </form>
        </div>
        <div class="pop-up-login-div" *ngIf="isReset" id="login_container">
            <form [formGroup]="customerLoginForm" name="Login" id="login">
                <div fxLayout="column" class="p-20-15-10">
                    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                        <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" style="max-width:400px;margin:auto">
                            <div>
                                <input type="text" class="form-field w-input input-field" maxlength="256"
                                    name="userName" formControlName="userName" placeholder="Email"
                                    id="Email" [(ngModel)]="userInfo.userName" required>
                            </div>
                            <div class="error-box text-left">
                                <span class="help-block"
                                    *ngIf="!customerLoginForm.get('userName').valid && customerLoginForm.get('userName').touched">
                                    <span
                                        *ngIf="customerLoginForm.get('userName').errors['required']">This
                                        field is required</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div layout="row" fxLayout-sm="column" class="m-t-20 login-bottom"
                    fxLayout-xs="column" fxLayout-align="start center"
                    fxLayout-align-xs="center start" fxLayout-align-sm="center start" layout-wrap>
                    <button type="submit" class="btn-project w-80 upper-case f-s-18" color="primary" id="resetbtn"
                        mat-raised-button (click)="SendLink('username')" *ngIf="forgotUsername"> Please email me my Login Name</button>
                    <button type="submit" class="btn-project w-80 upper-case f-s-18" color="primary" id="resetbtn"
                        mat-raised-button (click)="SendLink()" *ngIf="!forgotUsername">Please email me a Reset Password Link</button>
                </div>
            </form>
          </div>
      </div>
    </div>
  </div>
  
