<div class="dialog-content-wrapper verify-address" id="two-way-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
    </mat-toolbar>
    <div style="display:flex" class="pop-up-background" *ngIf="!data.InvalidAddress">
        <div class="pop-up-div p-10">
            <p class="fs-24 text-center">Please select shipping address.</p>
            <p class="plr-20 p-btm text-center">To ensure prompt and accurate delivery, we suggest a modified shipping address.</p>
            <div mat-dialog-content class="plr-20 text-center">
                <em> Suggested address : </em>
            </div>
            <p class="SuggestedAddress text-uppercase text-center">{{data.suggestedAddress.address1}}</p>
            <p class="SuggestedAddress text-uppercase text-center">{{data.suggestedAddress.city}}, {{data.suggestedAddress.state}}, {{data.suggestedAddress.zip}}</p>
            <hr class="divider">
            <div mat-dialog-content class="plr-20 text-center">
                <em> Original address : </em>
            </div>
            <p class="userAdderess text-uppercase text-center">{{data.userAddress.address1}}</p>
            <p class="userAdderess text-uppercase text-center">{{data.userAddress.city}}, {{data.userAddress.state}}, {{data.userAddress.zip}}</p>
            <div class="pop-up-button-div">
                <button mat-button class="pop-up-button pop-up-button-no w-button" (click)="addressUse('1')">Use Suggested Address</button> &nbsp;
                <button mat-button aria-current="page" class="pop-up-button w-button w--current"
                        cdkFocusInitial (click)="addressUse('0')">
                    Use Original Address
                </button>
            </div>
        </div>
    </div>
    <div style="display:flex" class="pop-up-background" *ngIf="data.InvalidAddress">
        <div class="pop-up-div">
            <p class="fs-24 text-center"> Address is invalid</p>
            <p class="plr-20 p-btm text-center">Do you want to continue with this address, we suggest to update the shipping address.</p>
            <div mat-dialog-content class="plr-20 text-center">
                <em> Original address : </em>
            </div>
            <p class="userAdderess text-uppercase text-center">{{data.userAddress.address1}}</p>
            <p class="userAdderess text-uppercase text-center">{{data.userAddress.city}}, {{data.userAddress.state}}, {{data.userAddress.zip}}</p>
            <div class="pop-up-button-div">
                <button mat-button class="pop-up-button pop-up-button-no w-button" (click)="continueWithAddress(false)">Update Address</button> &nbsp;
                <button mat-button aria-current="page" class="pop-up-button w-button w--current"
                        cdkFocusInitial (click)="continueWithAddress(true)">
                    Continue with address
                </button>
            </div>
        </div>
    </div>
</div>


