<div id="Order-Enhance" class="page-layout">
  <div class="page-wrapper">
    <div class="ult-checkout-section wf-section">
      <app-header></app-header>
      <app-stepper *ngIf="_enrollSession.mobileView"></app-stepper>
       
      <div class="collection-padding-enhance">
        <app-stepper *ngIf="!_enrollSession.mobileView"></app-stepper>
       <h1 class="checkout-h1">Add-ons<span class="f-s-20">(Optional)</span></h1>
       <div class="w-layout-grid enhance-grid-enhance cen" *ngIf="!isDesktopView">
          <div *ngFor="let item of EnhanceTranformation" class="m-b-30">
            <div class="enhanceItemBorder" (click)="productInfo(item)" fxLayoutAlign="center center">
                <img src="{{item.SmallPicture}}" loading="lazy" alt=""
                class="cursor-pointer">
                <div class="tag_text">
                  <p>{{item.ShortDetail3.split(':')[0]}}</p>
                </div>
              </div>
            <div class="tooltip_container">
              <div class="checkout-h2 enhance-grid-h2 text-truncate">{{item.Description}}
                <span class="tooltiptext">{{item.Description}}</span>
              </div>
            </div>
            <div class="enhance-grid-price mh-22" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{item.Price |number : '1.2-2'}}</div>
            <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
              <div *ngFor="let itemprices of item.ItemPrices">
                <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
              </div>
            </div>
            <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{item.Other1Price |number : '1.2-2'}}</div>
            <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()"><del>Retail : ${{item.Other1Price |number : '1.2-2'}} USD</del></div>
            <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{item.ShortDetail3}}</div>
            <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59" class="button enhance-grid-button w-button hover-white"
              (click)="_sharedMethodService.ContinueRetailOrder(item,1,2,sideNav);">Add To Cart</a>
          </div>
        </div>
        <div class="w-layout-grid enhance-grid-enhance" *ngIf="isDesktopView">
          <div *ngFor="let item of EnhanceTranformation" class="m-b-30">
            <div class="enhanceItemBorder" (click)="productInfo(item)" fxLayoutAlign="center center">
              <img src="{{item.SmallPicture}}" loading="lazy" alt=""
              class="cursor-pointer">
              <div class="tag_text">
                <p>{{item.ShortDetail3.split(':')[0]}}</p>
              </div>
            </div>
            <div class="tooltip_container">
              <div class="checkout-h2 enhance-grid-h2 text-truncate">{{item.Description}}
                <span class="tooltiptext">{{item.Description}}</span>
              </div>
            </div>
            <div class="enhance-grid-price mh-22" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{item.Price |number : '1.2-2'}}</div>
            <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
              <div *ngFor="let itemprices of item.ItemPrices">
                <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
              </div>
            </div>
            <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{item.Other1Price |number : '1.2-2'}}</div>
            <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()"><del>Retail : ${{item.Other1Price |number : '1.2-2'}} USD</del></div>
            <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{item.ShortDetail3}}</div>
            <a class="button enhance-grid-button w-button hover-white"
              (click)="_sharedMethodService.ContinueRetailOrder(item,1,2,sideNav);">Add To Cart</a>
          </div>
        </div>
        <mat-accordion class="example-headers-align">
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="panelOpenState">
            <mat-expansion-panel-header class="allProductHeader">
              <mat-panel-title>
                <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start" fxLayoutAlign.gt-xs="space-between none" fxFlex>
                    <div fxLayoutAlign="start center" fxFlex="100" fxFlex.gt-md="49">
                      <h1 class="order-summary-h1 all-products-accordion">All Purium Products</h1>
                    </div>
                    <div fxLayoutAlign="end center" fxLayoutAlign.lt-lg="center start" fxFlex="100" fxFlex.gt-md="49" class="searchBox tooltip" (keydown)="$event.stopImmediatePropagation();" (click)="$event.stopPropagation();">
                      <input class="searchInput" type="text"  (input)="onSearchChangeEnhance($event)" placeholder='Search Your Product' fxFlex>
                      <mat-icon matSuffix>search</mat-icon>
                      <span class="tooltiptext tooltiptextCustom">Search</span>
                    </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="all-products-accordion-item">
              <div class="w-layout-grid enhance-grid-enhance" *ngIf="isDesktopView">
                <div *ngFor="let product of productList | sortBy: 'asc':'Description' | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()" class="m-b-30">
                    <div class="itemBorder" (click)="productInfo(product)">
                      <img src="{{product.SmallPicture}}" loading="lazy"
                        id="w-node-_421021f4-112c-5413-f715-320b54c43fe5-db337a59" alt=""
                        class="checkout-product-img checkout-product-img-grid cursor-pointer">
                    </div>
                    <div class="tooltip align-center">
                      <div id="w-node-_97ec39c2-7213-1023-164a-ea6b31ad721f-db337a59" class="checkout-h2 enhance-grid-h2 text-truncate">
                        {{product.Description}}<span class="tooltiptext">{{product.Description}}</span>
                      </div>
                    </div>
                    <div id="w-node-cd5718ae-6526-5955-b798-aee0dccf7998-db337a59">
                      <div class="enhance-grid-price mh-22" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{product.Price |number : '1.2-2'}} USD</div>
                      <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
                        <div *ngFor="let itemprices of product.ItemPrices">
                          <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
                        </div>
                      </div>
                      <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{product.Other1Price |number : '1.2-2'}} USD</div>
                      <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()"><del>Retail : ${{product.Other1Price |number : '1.2-2'}} USD</del></div>
                    </div>
                    <a id="w-node-_263a773b-b3b9-3901-44ef-9e82a85a78f1-db337a59"
                      class="button enhance-grid-button w-button hover-white"
                      (click)="addToCart(product,1,2,sideNav);">Add To Cart</a>
                </div>
              </div>
              <div class="w-layout-grid enhance-grid-enhance cen" *ngIf="!isDesktopView">
                <div *ngFor="let product of productList | sortBy: 'asc':'Description' | search: 'ItemCode,Description,ShortDetail,LongDetail,LongDetail1,LongDetail2':getHeaderSearchedValue()">
                  <div class="itemBorder" (click)="productInfo(product)">
                    <img src="{{product.SmallPicture}}" loading="lazy"
                      class="checkout-product-img checkout-product-img-grid cursor-pointer">
                  </div>
                  <div class="tooltip_container">
                    <div class="checkout-h2 enhance-grid-h2 text-truncate2">{{product.Description}}
                      <span class="tooltiptext">{{product.Description}}</span>
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22 " *ngIf="!_auth.isLoggedIn()">Loyal Customer :${{product.Price |number : '1.2-2'}} USD</div>
                  <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
                    <div *ngFor="let itemprices of product.ItemPrices">
                      <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
                    </div>
                  </div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{product.Other1Price |number : '1.2-2'}} USD</div>
                  <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()"><del>Retail : ${{product.Other1Price |number : '1.2-2'}} USD</del></div>
                  <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59"
                    class="button enhance-grid-button w-button hover-white"
                    (click)="addToCart(product,1,2,sideNav);">Add To Cart</a>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <app-footercomponent></app-footercomponent>
      </div>
    </div>
  </div>
</div>