<div class="dialog-content-wrapper verify-address" id="two-way-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
    </mat-toolbar>
    <div style="display:flex" class="pop-up-background">
        <div class="pop-up-div p-10">
            <p class="fs-24 text-center">
                We detected a potential problem with your order.
                <br />
                <br />
                Contact customer care to complete your order. To avoid multiple charges on your card, do not try placing another order..
                <br />
                <br />
                <a target="_blank" href="https://ishoppurium.com/documents/support">Contact Customer Care</a>
            </p>

        </div>
    </div>
</div>


