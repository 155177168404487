<app-header></app-header>
<div class="pop-up-login-div" id="login_container_shopify">
    <form [formGroup]="customerLoginForm" name="Login" id="login" *ngIf="!resetPasswordStatus">
        <div fxLayout="column" class="p-20-15-10">
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100" style="max-width:400px;margin:auto">
                    <div>
                        <input type="text" class="form-field w-input input-field" maxlength="256" name="userName"
                            formControlName="userName" placeholder="Email" id="Email" [(ngModel)]="userInfo.userName"
                            required>
                    </div>
                    <div class="error-box text-left">
                        <span class="help-block"
                            *ngIf="!customerLoginForm.get('userName').valid && customerLoginForm.get('userName').touched">
                            <span *ngIf="customerLoginForm.get('userName').errors['required']">This
                                field is required</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div layout="row" fxLayout-sm="column" class="m-t-20 login-bottom" fxLayout-xs="column"
            fxLayout-align="start center" fxLayout-align-xs="center start" fxLayout-align-sm="center start" layout-wrap>
            <button type="submit" class="btn-project w-80 upper-case f-s-18" id="resetbtn" color="primary" mat-raised-button
                (click)="SendLink()">Please email me a Reset Password Link</button>
        </div>
    </form>
    <h2 *ngIf="resetPasswordStatus" class="linkText">Link sent successfully. <br> Please check your inbox.</h2>
</div>
<app-footercomponent></app-footercomponent>