<div class="dialog-content-wrapper" id="ShippingWebAliasDialog">
  <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
    <div class="pop-up-div">
      <div class="m-0 close-text" >
      </div>
      <h1 class="pop-up-headline"> In order to continue <br>Please confirm your referral code:  </h1>
      <div>
        <label class="billing-address-radio-button-field w-radio">
            <div class="display-flex-radio">
              <label>
                <input type="radio" id="radio-3" name="ShippingWebAlias" value="0" [formControl]="ShippingWebAlias" class="w-form-formradioinput shipping-method-radio-button">
              <span class="billing-address-radio-button-label w-form-label">{{existingCustomerData.CustomerSite.webAliasField}} &nbsp; &nbsp;</span>
              </label>
            </div>
            <div class="display-flex-radio">
              <label>
                <input type="radio" id="radio-4" name="ShippingWebAlias" value="1" [formControl]="ShippingWebAlias" class="w-form-formradioinput shipping-method-radio-button">
              <span class="billing-address-radio-button-label w-form-label">{{_enrollSession.data.RetailEnroll.webAliasField}}</span>
              </label>
            </div>
        </label>
      </div>
      <div class="pop-up-button-div m-t-10">
        <button mat-button class="pop-up-button w-button w--current pop-up-button-submit" (click)="submitButton()">Submit</button></div>
    </div>
  </div>
</div>


