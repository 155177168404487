import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { CalculateRetailOrderService } from './calculateRetailOrder.service';
import { EnrollSessionService } from './enrollSession.service';

@Injectable({
  providedIn: 'root'
})
export class CalculateRetailInitialOrderService {

  constructor(
    private calculateRetailOrder: CalculateRetailOrderService,
    private _enrollSession: EnrollSessionService
    ) {
    this._enrollSession.data = JSON.parse(localStorage.getItem('EnrollSession')) ? JSON.parse(localStorage.getItem('EnrollSession')) : {};
  }

  calculate(shippingId: any): any {
    const promise = new Promise((resolve, reject) => {
      this._enrollSession.data.RetailOrderSummary.InitialOrderDetails = this._enrollSession.data?.RetailOrderSummary?.InitialOrderDetails || {};
      this.calculateRetailOrder.calculate(this._enrollSession.data.RetailOrderSummary.InitialOrders, shippingId, (response) => {
        this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total = response.Total;
        this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal = response.SubTotal;
        this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.BV = response.BusinessVolumeTotal;
        this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.CV = response.CommissionableVolumeTotal;
        this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax = response.TaxTotal;
        this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.ShippingTotal = response.ShippingTotal;
        this._enrollSession.data.RetailOrderProductDetail = response.CustomDetails;
        resolve(response);
      }, 0);
    });
    return promise;
  }
}
