import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { EnrollSessionService } from './enrollSession.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GetDiscountedItemsService {
  EnrollSession: any;
  discountItem: any
  constructor(
    private _enrollSession: EnrollSessionService,
    private auth: AuthService) {
    this.discountItem = { itemCode: '', qty: 0 };
    this._enrollSession.data = JSON.parse(localStorage.getItem('EnrollSession')) ? JSON.parse(localStorage.getItem('EnrollSession')) : {};
  }

  getDiscount(amount) {
    this.discountItem = { itemCode: '', qty: 0, Price: 0 };
    if(!this.auth.isLoggedIn() || this._enrollSession.data.LoggedInUserData.CustomerType == 18 || this._enrollSession.data.LoggedInUserData.CustomerType == 11){
      if (amount < 200 && amount >= 75) {
        this.discountItem.itemCode = '7800GC';
          this.discountItem.qty = 1;
          this.discountItem.Price = -50;
      }
    }
    return this.discountItem;
  }

}
