import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { RestService } from 'app/shared/rest.service';

@Component({
  selector: 'privacy-document-dialog',
  templateUrl: './privacy-document-dialog.component.html',
  styleUrls: ['./privacy-document-dialog.component.scss']
})
export class PrivacyDocumentPopupDialogComponent implements OnInit {
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<PrivacyDocumentPopupDialogComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService, public _restService: RestService) {
  }

  ngOnInit(): void {

  }



  closePopup(){
    this.dialogRef.close();
  }
}
