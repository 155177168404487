<app-header></app-header><br>
<section id="AmyAndDave">
    <div class="container mt-50 m-t-mobile">
        <div class="ownersText">
			Help us celebrate 20 years of superfoods, <br *ngIf="!_enrollSession.mobileViewMethod()">community, eco-friendly practices and financial empowerment!
		</div>
        <div>
        <iframe 
            src="https://www.kudoboard.com/boards/9bBFhT8T/embed" 
            height="800px" 
            width="100%" 
            style="border:none" 
            allowfullscreen 
            sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
        </iframe>
        </div>
        <br><br>
    </div>
</section>