<div class="dialog-content-wrapper" id="CoreSection">
    <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
      <div class="pop-up-div">
        <div class="m-0 close-text" (click)="closePopup()">Close
        </div>
        <div class="display-flex">
          <div class="products">
            <div class="products-sec">
              <p class="core-heading">{{headingText}}</p>
          <p class="core-description" [innerHtml]="descriptionText | safeHtml"></p>
          <img [src]="smallimageUrl" loading="lazy" alt="image" class="icons" style="width:60%">
            </div>
          <div class="ship-text">
            <p class="mayhelp">May help: </p>
            <ul>
              <li *ngIf="data[0].details.text1 && data[0].details.text1 !== ''">{{data[0].details.text1}}</li>
              <li *ngIf="data[0].details.text2 && data[0].details.text2 !== ''">{{data[0].details.text2}}</li>
              <li *ngIf="data[0].details.text3 && data[0].details.text3 !== ''">{{data[0].details.text3}}</li>
              <li *ngIf="data[0].details.text4 && data[0].details.text4 !== ''">{{data[0].details.text4}}</li>
              <li *ngIf="data[0].details.text5 && data[0].details.text5 !== ''">{{data[0].details.text5}}</li>
            </ul>
          </div>
          </div>
          <div class="imageBig">
            <img [src]="bigImage" loading="lazy" alt="image" class="icons">
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  