import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { EnrollSessionService } from '../enrollSession.service';
import { Router } from '@angular/router';
import { RestService } from '../rest.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-lifestyleSubscription',
    templateUrl: './LifestyleSubscription.component.html',
    styleUrls: ['./LifestyleSubscription.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class LifestyleSubscriptionComponent implements OnInit {
  currentRouterPath: any;
  public userLoginDialog: boolean = false;
  public userInfo: any = {};
  public loginDetails: any = {};
  public dropdownMenu:any = [];
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<LifestyleSubscriptionComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    public authService:AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService, public _restService: RestService) {
    this.currentRouterPath = this.router.url.split('?')[0];
  }

  ngOnInit(): void {

    this.dropdownMenu = JSON.parse(sessionStorage.getItem('this.DropdownMenu'));
    let allProducts = {
      url:'/AllProducts'
    }
    this.dropdownMenu.push(allProducts);
  }

  isCurrentPathInDropdown() {
    return this.dropdownMenu.some(item => item.WebCategoryID === 628);
  }

  onClickYes()
  {
    if (this.data.item=='11200' || this.data.item=='11205') {
        this.router.navigateByUrl('/OrderReview?itemcode=11240&site=ultlifestyle')
    }
    else if (this.data.item=='11210' || this.data.item=='11220')
    {
        this.router.navigateByUrl('/OrderReview?itemcode=11241&site=ultlifestyle')
    }
    else if (this.data.item=='11208' || this.data.item=='11209')
    {
        this.router.navigateByUrl('/OrderReview?itemcode=11242&site=ultlifestyle')
    }
    else if (this.data.item=='11235' || this.data.item=='11238')
    {
        this.router.navigateByUrl('/OrderReview?itemcode=11243&site=ultlifestyle')
    }
    else if (this.data.item=='11270' || this.data.item=='11271')
    {
        this.router.navigateByUrl('/OrderReview?itemcode=11248&site=ultlifestyle')
    }
    this.dialogRef.close();
  }

}
