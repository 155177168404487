import { EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { RestService } from './rest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CalculateRetailInitialOrderService } from './calculateRetailInitialOrder.service';
import { CalculateRetailSmartOrderService } from './CalculateRetailSmartOrder.service';
import {EnrollSessionService } from './enrollSession.service'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { CalculateRetailOrderService } from './calculateRetailOrder.service';

@Injectable({
  providedIn: 'root'
})
export class SharedMethodService implements OnInit {
  invokeEvent = new Subject<any>();
  fuseConfig: any;
  CustomerID: any;
  AutoOrderID: any;
  ifReload: any = false;
  finalShippingMethod: any;
  IsCallCalculateOrder: any;
  templateUrl: any;
  navigationInfo: any = {};
  subOrderItem: boolean = false;
  checkSubTotal: BehaviorSubject<any>;
  headerSearchValue: '';
  isOpenDialog:boolean =false;
  initialOrdersQuantity:any;
  displayBV: boolean = false;
  userData:any;
  AllProducts472: BehaviorSubject<any>;
  isOpen = false;
  screenWidth: any;
  mobileView: any;
  loginContent: boolean = false;
  navSupportContent: boolean = true;
  navInputSearch: boolean = false;
  @Output() userLoggedInEvent = new EventEmitter<string>();
  submissionInProgress: boolean;
  processType: string;
  SubscriptionProcessType: string;
  balanceField: string = '0';
  AllBPProducts:any = [];
  giftcardName: string = sessionStorage.getItem('giftCardName');
  tokenizer: any = {};
  constructor(
    private _toastr: ToastrService,
    private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
    private _calculateRetailSmartOrder: CalculateRetailSmartOrderService,
    private _calculateRetailOrder: CalculateRetailOrderService,
    private _router: Router,
    private _restService: RestService,
    public _enrollSession: EnrollSessionService,
    public _activatedRouter: ActivatedRoute,
    public _auth: AuthService) {
    this._enrollSession.data = JSON.parse(localStorage.getItem('EnrollSession')) ? JSON.parse(localStorage.getItem('EnrollSession')) : {};
    this.init();
  }
  init() {
    this.getProducts();
    this.getProcessType();
    this.AllBPProducts = this._auth.getToken() && this.validCustomerType() ? JSON.parse(sessionStorage.getItem("AllBPProducts")) : []; 
    this.checkSubTotal = new BehaviorSubject(null);
    this.AllProducts472 = new BehaviorSubject(null);
  }
 
  ngOnInit(): void {
    
  }
  // *************start Add To Cart ContinueRetailOrder***********************************************************************/
    ContinueRetailOrder(product: any, quantity: any, type: any, autoshipItemtype?: any): any {
      if(this._router.url !== '/Review-Page'){
        if(window.innerWidth < 550){
          this.openMobileSummaryCart();
        }
        else{
          this.toggleSidenavforaddtocart();
        }
      }  
        // Store product in EnrollSession
        if (parseInt(quantity, 10) <= 0) {
            return false;
        }
        this._enrollSession.data.RetailOrderSummary = this._enrollSession.data.RetailOrderSummary || [];
        
        if (type === 1) {
            this._enrollSession.data.RetailOrderSummary.Enrollpack = this._enrollSession.data.RetailOrderSummary.Enrollpack || [];
            const enrollOrder = _.find(this._enrollSession.data.RetailOrderSummary.Enrollpack,
                (item: any) => {
                    return item.ItemCode === product.ItemCode && item.ParentId === product.ParentId;
                });
            if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.Enrollpack)) {
                if (this.subOrderItem) {
                    if (!enrollOrder) {
                        this._enrollSession.data.RetailOrderSummary.Enrollpack.push({
                            ItemCode: product.ItemCode,
                            Description: product.Description,
                            Quantity: quantity ? quantity : 1,
                            Price: product.Price,
                            RetailPrice: product.Other1Price,
                            ShippingPrice: product.ShippingPrice,
                            TinyImage: product.TinyPicture,
                            SmallImage: product.SmallPicture,
                            BV: product.BusinessVolume,
                            CV: product.CommissionableVolume,
                            TaxablePrice: product.TaxablePrice,
                            LongDetail: product.LongDetail,
                            LongDetail2: product.LongDetail2,
                            LongDetail3: product.LongDetail3,
                            LongDetail4: product.LongDetail4,
                            IsUSOnly: product.IsUSOnly ? product.IsUSOnly : false,
                            ParentId: product.ParentId
                        });
                    } else {
                        enrollOrder.Quantity = enrollOrder.Quantity+1 || 1;
                    }
                } else {
                    if (!enrollOrder) {
                    this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
                    this._enrollSession.data.RetailOrderSummary.Enrollpack.push({
                        ItemCode: product.ItemCode,
                        Description: product.Description,
                        Quantity: quantity ? quantity : 1,
                        Price: product.Price,
                        RetailPrice: product.Other1Price,
                        ShippingPrice: product.ShippingPrice,
                        TinyImage: product.TinyPicture,
                        SmallImage: product.SmallPicture,
                        BV: product.BusinessVolume,
                        CV: product.CommissionableVolume,
                        TaxablePrice: product.TaxablePrice,
                        LongDetail: product.LongDetail,
                        LongDetail2: product.LongDetail2,
                        LongDetail3: product.LongDetail3,
                        LongDetail4: product.LongDetail4,
                        IsUSOnly: product.IsUSOnly ? product.IsUSOnly : false,
                        ParentId: product.ParentId
                    });
                } else {
                    enrollOrder.Quantity = enrollOrder.Quantity+1 || 1;
                }
                }
                // *******find shipping method************/

                if (this._enrollSession.data.RetailEnroll?.ShippingMethod) {
                    this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
                } else {
                    this.finalShippingMethod = '';
                }

                if (!product.ParentId || product.ParentId === product.ItemCode) {
                    this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
                }
            }
            else {
                if (!product.ParentId || product.ParentId === product.ItemCode) {
                    this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
                }
            }
        }
        else if (type === 2) {
            this._enrollSession.data.RetailOrderSummary.InitialOrders = this._enrollSession.data.RetailOrderSummary.InitialOrders || [];
            const initialOrder = _.find(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
                return item.ItemCode === product.ItemCode && item.ParentId === product.ParentId;
            });

          
            
           
            if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.InitialOrders)) {
                if (!initialOrder) {
                    this._enrollSession.data.RetailOrderSummary.InitialOrders.push({
                        ItemCode: product.ItemCode,
                        Description: product.Description,
                        Quantity: quantity ? quantity : 1,
                        Price: product.Price,
                        RetailPrice: product.Other1Price,
                        ShippingPrice: product.ShippingPrice,
                        TinyImage: product.TinyPicture,
                        SmallImage: product.SmallPicture,
                        BV: product.BusinessVolume,
                        CV: product.CommissionableVolume,
                        TaxablePrice: product.TaxablePrice,
                        LongDetail: product.LongDetail,
                        LongDetail2: product.LongDetail2,
                        LongDetail3: product.LongDetail3,
                        LongDetail4: product.LongDetail4,
                        ParentId: product.ParentId,
                        IsUSOnly: product.IsUSOnly ? product.IsUSOnly : false
                    });
                } else {
                    initialOrder.Quantity = initialOrder.Quantity + 1;
                }
                // *******shipping method**********/
                if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
                    this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
                } else {
                    this.finalShippingMethod = '';
                }

                // **********calculate order**********************/
                if (this.IsCallCalculateOrder) {
                    this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
                }
                else {
                    this._enrollSession.data.InitialOrdersSubTotal = 0;
                    this._enrollSession.data.InitialOrdersQVTotal = 0;
                    _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
                        this._enrollSession.data.InitialOrdersSubTotal = this._enrollSession.data.InitialOrdersSubTotal + (item.Quantity * item.Price);
                        this._enrollSession.data.InitialOrdersQVTotal = this._enrollSession.data.InitialOrdersQVTotal + (item.Quantity * item.BV);
                    });
                }
                this._enrollSession.data.InitialOrdersSubItems = '';
                const filterInitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
                    return item.ParentId && item.ParentId !== undefined;
                });

                this._enrollSession.data.InitialOrdersSubItems = _.groupBy(filterInitialOrders, 'ParentId');
                const subTotal = this._enrollSession.data.InitialOrdersSubTotal + this._enrollSession.data.Volume109;
                this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
                    return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
                });

              this._calculateRetailInitialOrder.calculate(this.finalShippingMethod).then(() => {
                  if (this._enrollSession.data.RetailOrderSummary.SmartOrders?.length > 0) {
                    this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
                    this._calculateRetailOrder.RedeemDiscount();
                }
              });
                setTimeout(() => {
                    if (this._enrollSession.data.RetailOrderSummary.InitialOrderDetails && this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal) {
                        this.checkSubTotal.next(this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal);
                    }
                }, 2000)
            }
            
            else {
                if (!product.ParentId || product.ParentId === product.ItemCode) {
                    this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
                }
            }
            
            this._enrollSession.data.RetailOrderSummary.InitialOrders.forEach((item)=> {
              this.initialOrdersQuantity = item.Quantity || 0 ;
            })
        }
        else if (type === 3) {
            this._enrollSession.data.RetailOrderSummary.SmartOrders = this._enrollSession.data.RetailOrderSummary.SmartOrders || [];
            const SmartOrder = _.find(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
                return item.ItemCode === product.ItemCode && item.ParentId === product.ParentId;
            });
            if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.SmartOrders)) {
                if (!SmartOrder) {
                    this._enrollSession.data.RetailOrderSummary.SmartOrders.push({
                        ItemCode: product.ItemCode,
                        Description: product.Description,
                        Quantity: quantity ? quantity : 1,
                        Price: product.Price,
                        RetailPrice: product.Other1Price,
                        ShippingPrice: product.ShippingPrice,
                        TinyImage: product.TinyPicture,
                        SmallImage: product.SmallPicture,
                        BV: product.BusinessVolume,
                        CV: product.CommissionableVolume,
                        TaxablePrice: product.TaxablePrice,
                        LongDetail: product.LongDetail,
                        LongDetail2: product.LongDetail2,
                        LongDetail3: product.LongDetail3,
                        LongDetail4: product.LongDetail4,
                        ParentId: product.ParentId,
                        IsUSOnly: product.IsUSOnly ? product.IsUSOnly : false,
                        type: autoshipItemtype,
                        Other1Price: product.Other1Price,
                    });

                }
                else {
                    SmartOrder.Quantity = SmartOrder.Quantity + 1;

                }
                // *******shipping method**********/

                if (this._enrollSession.data.RetailEnroll?.ShippingMethod) {// shipping method
                    this.IsCallCalculateOrder = true;
                    this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
                } else {
                    this.finalShippingMethod = '';
                }
                // **********calculate order**********************/
                if (this.IsCallCalculateOrder) {
                    this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
                }
                else {
                    this._enrollSession.data.SmartOrdersSubTotal = 0;
                    this._enrollSession.data.SmartOrderBVTotal = 0;
                    _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {

                        this._enrollSession.data.SmartOrdersSubTotal = this._enrollSession.data.SmartOrdersSubTotal + (item.Quantity * item.Price);
                        this._enrollSession.data.SmartOrderBVTotal = this._enrollSession.data.SmartOrderBVTotal + (item.Quantity * item.BV);

                    });
                    
                }
                this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
                const filterResult = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
                    return item.ParentId !== undefined;
                });


                this._enrollSession.data.SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');
                localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
            }
            else {
                if (!product.ParentId || product.ParentId === product.ItemCode) {
                    this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
                }
            }
        }
        else if (type === 4) {
            this._enrollSession.data.RetailOrderSummary.AddOnOrders = this._enrollSession.data.RetailOrderSummary.AddOnOrders || [];

            const addonOrder = _.find(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
                return item.ItemCode === product.ItemCode && item.ParentId === product.ParentId;
            });

            if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.AddOnOrders)) {

                if (!addonOrder) {
                    this._enrollSession.data.RetailOrderSummary.AddOnOrders.push({
                        ItemCode: product.ItemCode,
                        Description: product.Description,
                        Quantity: 1,
                        Price: product.Price,
                        ShippingPrice: product.ShippingPrice,
                        TinyImage: product.TinyPicture,
                        SmallImage: product.SmallPicture,
                        BV: product.BusinessVolume,
                        CV: product.CommissionableVolume,
                        TaxablePrice: product.TaxablePrice,
                        LongDetail: product.LongDetail,
                        LongDetail2: product.LongDetail2,
                        LongDetail3: product.LongDetail3,
                        LongDetail4: product.LongDetail4,
                        ParentId: product.ParentId,
                        IsUSOnly: product.IsUSOnly ? product.IsUSOnly : false
                    });


                } else {
                    addonOrder.Quantity = addonOrder.Quantity + 1;

                }
                // *******shipping method**********/
                if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
                    this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
                } else {
                    this.finalShippingMethod = '';
                }


                // **********calculate order**********************/
                if (this.IsCallCalculateOrder) {
                    this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
                }
                else {
                    this._enrollSession.data.AddOnOrdersSubTotal = 0;
                    this._enrollSession.data.AddOnOrdersQVTotal = 0;
                    _.each(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {

                        this._enrollSession.data.AddOnOrdersSubTotal = this._enrollSession.data.AddOnOrdersSubTotal + (item.Quantity * item.Price);
                        this._enrollSession.data.AddOnOrdersQVTotal = this._enrollSession.data.AddOnOrdersQVTotal + (item.Quantity * item.BV);

                    });
                }

                this._enrollSession.data.AddOnOrdersSubItems = '';
                const filterAddOnOrders = _.filter(this._enrollSession.data.RetailOrderSummary.AddOnOrders, (item) => {
                    return item.ParentId && item.ParentId !== undefined;
                });

                this._enrollSession.data.AddOnOrdersSubItems = _.groupBy(filterAddOnOrders, 'ParentId');
                this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal =
                    this._enrollSession.data.AddOnOrdersSubTotal;
                this._enrollSession.data.RetailOrderSummary.InitialOrderDetails.BV =
                    this._enrollSession.data.AddOnOrdersQVTotal;
                this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);

            }
            else {
                if (!product.ParentId || product.ParentId === product.ItemCode) {
                    this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
                }
            }
        }
    }
  decreaseProductQuantity(product: any, quantity: any, type: any) {
    if (parseInt(quantity, 10) <= 0) {
      return false;
    }
    if (type === 1) {
        var enrollOrderIndex;
        this._enrollSession.data.RetailOrderSummary.Enrollpack = this._enrollSession.data.RetailOrderSummary.Enrollpack || [];
        const enrollOrder = _.find(this._enrollSession.data.RetailOrderSummary.Enrollpack, (item, index) => {
          if(item.ItemCode === product.ItemCode && item.ParentId === product.ParentId) {
            enrollOrderIndex = index;
          }
          return item.ItemCode === product.ItemCode && item.ParentId === product.ParentId;
        });
        // *******shipping method**********/
        if (this._enrollSession.data.RetailEnroll?.ShippingMethod) {
          this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
        } else {
          this.finalShippingMethod = '';
        }
        if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.Enrollpack)) {
          if (enrollOrder.Quantity>1) {// decrease order
            enrollOrder.Quantity = enrollOrder.Quantity - 1;
            this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
          }
        }
        else {
          if (!product.ParentId || product.ParentId === product.ItemCode) {
            this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
          }
        }
      }
    if (type === 2) {
      var initialOrderIndex;
      this._enrollSession.data.RetailOrderSummary.InitialOrders = this._enrollSession.data.RetailOrderSummary.InitialOrders || [];
      const initialOrder = _.find(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item, index) => {
        if(item.ItemCode === product.ItemCode && item.ParentId === product.ParentId) {
          initialOrderIndex = index;
        }
        return item.ItemCode === product.ItemCode && item.ParentId === product.ParentId;
      });
      // *******shipping method**********/
      if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
        this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
      } else {
        this.finalShippingMethod = '';
      }
      if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.InitialOrders)) {
        if (initialOrder.Quantity>1) {
          initialOrder.Quantity = initialOrder.Quantity - 1;
          this._calculateRetailInitialOrder.calculate(this.finalShippingMethod).then(() => {
            if (this._enrollSession.data.oldPricetype != this._enrollSession.data.priceType) {
              if (this._enrollSession.data.RetailOrderSummary.SmartOrders?.length > 0) {
                this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
              }
              this._calculateRetailOrder.RedeemDiscount();
            }
          });;
        }
      }
      else {
        if (!product.ParentId || product.ParentId === product.ItemCode) {
          this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
        }
      }
    }else if (type === 3) {
      this._enrollSession.data.RetailOrderSummary.SmartOrders = this._enrollSession.data.RetailOrderSummary.SmartOrders || [];
      const SmartOrder = _.find(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
        return item.ItemCode === product.ItemCode && item.ParentId === product.ParentId;
      });
       // *******shipping method**********/

      if (this._enrollSession.data.RetailEnroll?.ShippingMethod) {
        this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
      } else {
        this.finalShippingMethod = '';
      }
      if (!this.isItemRestrcited(product, this._enrollSession.data.RetailOrderSummary.SmartOrders)) {
        if (SmartOrder.Quantity>1) {
          SmartOrder.Quantity = SmartOrder.Quantity - 1;
          this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
        }
      }
      else {
        if (!product.ParentId || product.ParentId === product.ItemCode) {
          this._toastr.info('Info', 'You can buy only ' + product.RestrictQty + ' quantity of this item.');
        }
      }
    }
  }

  isItemRestrcited(product: any, selectedItems: any): any {
    let isRestricted = false;
    let filterItem: any = {};
    filterItem = _.find(selectedItems, (item) => {
      return item.ItemCode === product.ItemCode;
    });
    if (product.RestrictQty && product.RestrictQty > 0 && (filterItem && filterItem.Quantity >= product.RestrictQty)) {
      isRestricted = true;
    }
    return isRestricted;
  }

  validCustomerType():boolean
  {
    let validCustomer = [4,5,6,7,8,10,17,19,21,24,25]
    let customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData'));
    validCustomer.forEach((item)=> {
      if(customerDetail && customerDetail.CustomerType==item)
      {
          this.displayBV = true;
      }
    })
    return  this.displayBV;
  }
  getProducts(){
    this.userData = JSON.parse(this._auth.getUserData());
    const request = {
      'EnrollmentType': '',
      'CustomerType': this._auth.isLoggedIn() ? this.userData?.CustomerType : 22,
      'WebCategoryId': 472,
      'isUpgradeCustomer': false,
      'IsSubscriptionOrder': false,
      'PriceType': ''
    }
    this._restService.getProducts(request).subscribe((res:any) => {
      this.AllProducts472.next(res.Items);
    });
  }

  
  toggleSidenavforaddtocart(sidenav?: MatSidenav) {
    this.isOpen = true;
  }

  toggleSidenav(sidenav: MatSidenav) {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      sidenav?.open();
    }
    else if(!this.isOpen){
      sidenav?.close();
    }
  }
  
  closeCart(){
    this.isOpen = false;
  }

  sendSubmitApplication() {
    this.invokeEvent.next(void 0);
  }

  getSubmitApplication(): Observable<any> {
    return this.invokeEvent.asObservable()
  }

  openMobileSummaryCart() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 550) {
      if(this.mobileView == true){
        this.mobileView = this.mobileView;
      }
      else{
        this.mobileView = !this.mobileView;
      }
    }
    else {
      this.mobileView = false;
    }

    localStorage.setItem('mobileView', this.mobileView)
    this._enrollSession.SummaryCartMobOnly = this.mobileView;
    this.loginContent = false;
    this.navSupportContent = false;
    this.navInputSearch = false;
    sessionStorage.setItem('SummaryCartMobOnly', JSON.stringify(this._enrollSession.SummaryCartMobOnly));
  }

  getPointAccount(customerId: number): Promise<string | null> {
    return new Promise((resolve, reject) => {
      if (customerId && customerId !== 0) {
        const request = {
          PointAccountID: 2,
          CustomerID: customerId,
          CustomerKey: ""
        };
  
        this._restService.GetPointAccount(request).subscribe(
          (res: any) => {
            if (res && res.Data.response.resultField.errorsField.length === 0) {
              sessionStorage.setItem('balanceFieldPoint', JSON.stringify(res.Data.response.balanceField * 100));
              this.balanceField = sessionStorage.getItem('balanceFieldPoint');
              resolve(this.balanceField);
            } else {
              reject('Error retrieving point account details.');
            }
          },
          (error: any) => {
            reject('Error retrieving point account: ' + error.message);
          }
        );
      } else {
        reject('Invalid customer ID.');
      }
    });
  }
  
  getProcessType()
  {
    this.SubscriptionProcessType = localStorage.getItem('SubscriptionProcessType') || '';
    if(this.SubscriptionProcessType === 'AutoOrderProcessTy1'){
      this.processType = 'Always Ship';
    }
    else if(this.SubscriptionProcessType === 'AutoOrderProcessTy2'){
      this.processType = 'Backup Order';
    }
    else {
      this.processType = '';
    }
    localStorage.setItem("SubscriptionProcessType", this.SubscriptionProcessType);
    return this.processType;
  }
  giftcardNameMethod(){
    this.giftcardName = sessionStorage.getItem('giftCardName');
    if(this.giftcardName != null && this.giftcardName != undefined && this.giftcardName != ''){
      return true;
    }
    else{
      return false;
    }
  }
  shareLinkCart(){
    let shareLinkURL = sessionStorage.getItem('FromShareLinkCart');
    if(shareLinkURL !== null && shareLinkURL !== undefined && shareLinkURL !== '' && shareLinkURL !== 'false'){
      return true;
    }
    else{
      return false;
    }
  }
}
