import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { EnrollSessionService } from '../enrollSession.service';
import { Router } from '@angular/router';
import { RestService } from '../rest.service';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-lifestyleSmartorderSubscription',
    templateUrl: './LifestyleSmartorderSubscription.component.html',
    styleUrls: ['./LifestyleSmartorderSubscription.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class LifestyleSmartorderSubscriptionComponent implements OnInit {
  currentRouterPath: any;
  public userLoginDialog: boolean = false;
  public userInfo: any = {};
  public loginDetails: any = {};
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<LifestyleSmartorderSubscriptionComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    public authService:AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService, public _restService: RestService) {
    this.currentRouterPath = this.router.url.split('?')[0];
  }

  ngOnInit(): void {

  }


}
