<!-- ======================= PAGE CONTENT ====================================== -->
<app-header></app-header>
<div class="container my-5 return-policy">
    <div id="ReturnPolicyBanner">
        <span><img src="../../../assets/images/Banner/RETURNPOLICYBANNER.png"></span>
    </div>
    <p style="line-height: 22px;">&nbsp;</p>
    <p style="text-align: left;"><strong>Standard 60-Day Money-Back Guarantee</strong></p>
    <p style="text-align: left;">
        To return or exchange products for any reason, please follow these steps:
    </p>

    <ul style="list-style-type: disc; padding-left: 50px;">
        <li><strong>Prepare Your Return:</strong> Clearly mark "RMA" and your order number on the outside of the box.
            Return all contents within 60 days of receiving your order. Include any specific details inside the package.
        </li>
        <li><strong>Multiple Boxes:</strong> If returning more than one box, ensure each box is labeled with "RMA -
            Order Number."</li>
        <li><strong>Return Requirements:</strong> You must return each item for which you wish to receive a refund. Even
            if a product is fully used, the container(s) must be included for a refund to be issued. Returns must arrive
            at our corporate office within 60 days of the original receipt date. Late returns will be rejected unless
            prior approval for credit has been obtained.</li>
    </ul>
    <p style="line-height: 22px;">&nbsp;</p>

    <p style="text-align: left;"><strong>Shipping Recommendations:</strong></p>
    <p style="text-align: left;">
        We highly recommend using a traceable shipping method for returns, as Purium Health Products is not responsible
        for lost or damaged items. If sending products via U.S. mail, please use registered, certified, or return
        receipt mail.
    </p>
    <p style="line-height: 22px;">&nbsp;</p>

    <p style="text-align: left;"><strong>Processing Time:</strong></p>
    <p style="text-align: left;">
        Returns typically take 2-3 weeks to process. Refunds can only be issued to the credit card used for the original
        order. Please confirm whether the credit card is still valid; if not, your refund will be issued via check from
        Purium Health Products. You will receive an email confirmation from the Returns Department once your refund is
        processed.
    </p>
    <p style="line-height: 22px;">&nbsp;</p>

    <p style="text-align: left;"><strong>Will Call Returns:</strong></p>
    <p style="text-align: left;">
        Returns will be accepted at Will Call; however, they will not be processed on the spot.
    </p>
    <p style="line-height: 22px;">&nbsp;</p>

    <p style="text-align: left;"><strong>Return Address:</strong></p>
    <p style="text-align: left;">Please send all products you wish to return or request a refund to:</p>
    <p style="text-align: left;padding-left: 50px;">
        <strong>US Returns</strong><br> Purium <br> 2610 Homestead Place <br> Rancho Dominguez, CA 90220
    </p>
    <p style="text-align: left;padding-left: 50px;">
        <strong>Canada Returns</strong><br> Purium <br> 130 - 1959 152nd Street <br> STE 512 <br> Surrey, BC V4A 0C4
    </p>
    <p style="line-height: 22px;">&nbsp;</p>

    <p style="text-align: left;"><strong>Refund Policy:</strong></p>
    <p style="text-align: left;">
        Purium reserves the right to refuse any refund in cases of abuse of our return policy. A 20% restocking fee may
        apply to returned products. Please note:
    </p>
    <ul style="text-align: left;padding-left: 50px;">
        <li>The guarantee applies only to products; there is no guarantee on Enrollment Packs or business tools.</li>
        <li>All refunds are handled at the discretion of Purium Health Products' management.</li>
        <li>Standard refunds will not include any shipping fees incurred by the customer.</li>
        <li>Commissions paid on returned items will be deducted from future commissions for the recipient of those
            commissions.</li>
    </ul>
    <p>If there is a company error or if you receive damaged goods, please contact Customer Service for assistance.
    </p>
    <p style="line-height: 22px;">&nbsp;</p>

    <p style="text-align: left;"><strong>Rewards Points Are Non-Refundable</strong></p>
    <p style="text-align: left;">
        Please note that we do not refund Rewards Points when a Customer or Brand Partner returns products purchased
        using those points. While Customers and Brand Partners can accrue and redeem Rewards Points at their discretion,
        points will expire after 3 months of inactivity (no earning or redeeming).
    </p>
</div>


<!-- ============================================================ -->

<app-footercomponent></app-footercomponent>

<!-- Bootstrap Script -->
<script src="scrollingtext.js"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js">
</script>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">