<div class="dialog-content-wrapper" id="SubscriptionDate">
  <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
    <div class="pop-up-div">
      <div class="m-0 close-text" (click)="closePopup()">Close
      </div>
      <h4 class="pop-up-headline">  Choose A Date </h4>
      <div class="subscriptionDate-text">
        <p class="checkout-p">Subscription orders process the 1st through the 30/31st of each month and ship 1-4 business days
          after the process date.<br>
        </p>
        <div>
          <div>
            <div style="color: #016145; font-weight: bold; margin: 10px;" class="btn btn-outline-secondary"
              mwlCalendarToday [(viewDate)]="viewDate">
              <span class="title">
                {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
              </span>
            </div>
            <div style="text-align: right;">
              <button class="btn btn-basic" (click)="decrement()" [disabled]="prevBtnDisabled">
                 <mat-icon>arrow_left</mat-icon>
              </button>
            
              <button [disabled]="nextBtnDisabled" style="text-align: right;" class="btn btn-basic" (click)="increment()">
                <mat-icon>arrow_right</mat-icon>
              </button>
            </div>
          </div>
          <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [refresh]="refresh"
              (dayHeaderClicked)="dayHeaderClicked.emit($event)" (dayClicked)="dayClicked($event.day)"
              (beforeViewRender)="beforeMonthViewRender($event)">
            </mwl-calendar-month-view>
          </div>
        </div>
        <div class="smartOrderDateBox">
          <div class="p-r-10">
            <label>Your Subscription Order Date:</label>
          </div>
          <div>
            <Span>{{_enrollSession.data.smartOrderDate ? _enrollSession.data.smartOrderDate : (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 ? smartOrderDateForLifestyle : '--/--/----')}}</Span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


