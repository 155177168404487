import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/shared/common.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FAQComponent implements OnInit {
      constructor(
      public _commonService: CommonService) 
      {
       
    }
    ngOnInit(): void {
    }

   
}
