<div class="faq-chatbox">
    <mat-toolbar class="toolbar p-10">
        <button mat-icon-button *ngIf="isActive == 3" class="faq-button">
            <mat-icon (click)="isActive = preState">arrow_back_ios</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isActive == 4" class="faq-button">
            <mat-icon (click)="isActive = preCatState">arrow_back_ios</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isActive == 5" class="faq-button">
            <mat-icon (click)="isActive = preSubCatState">arrow_back_ios</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isActive == 6" class="faq-button">
            <mat-icon (click)="isActive = preSubState">arrow_back_ios</mat-icon>
        </button>
       <button mat-icon-button class="faq-button" (click)="isActive = 1">
            <mat-icon>question_answer</mat-icon>
        </button> 
        <span class="faq">Purium Q & A</span>
        <!-- <button mat-icon-button class="faq-button">
            <mat-icon (click)="isChatbotOpen = !isChatbotOpen">expand_more</mat-icon>
        </button> -->
    </mat-toolbar>
    <div class="content">
        <!-- For Categories -->
        <div *ngIf="isActive == 0" class="m-h overflow-auto h-full">
            <div class="p-card d-flex p-b-0 space-between">
                <p class="f-s-16 fw-600 m-0">
                    Welcome to Purium. <br>
                    How may we assist you today?
                </p>
            </div>
            <div class="p-10 p-t-0 m-10">
                <mat-chip-list *ngIf="showSearchIcon">
                    <mat-chip *ngFor="let faqcat of faqsCatList" (click)="handleFaqCat(faqcat)">
                      {{ faqcat.categoryName }}
                    </mat-chip>
                </mat-chip-list>
                <div *ngIf="!showSearchIcon && allfaqs.length > 0">
                    <div title="{{faq.question}}" *ngFor="let faq of allfaqs | faq : searchedValue" class="p-card cat-link cursor-pointer d-flex align-center b-r-8 space-between border_custom" (click)="handleFaq(faq, 'cat')">
                        <p class="f-s-13-custom m-0">{{(faq.question.length>60)? (faq.question | slice:0:60)+'...':(faq.question)}}</p>
                        <p class="m-0 c-primary pos-relative top-4"><mat-icon>keyboard_arrow_right</mat-icon></p>
                    </div>
                </div>
                <div *ngIf="!showSearchIcon && (allfaqs | faq : searchedValue).length === 0" class="errorfaq">
                    <!-- <mat-icon>error_outline</mat-icon> -->
                    <p class="mb-1">Can't find an answer to your question?</p>
                    <p><a href="https://pure-premium.typeform.com/to/r8k6Ykvq" target="_blank">Click here</a> to leave your question.</p>
                </div>
            </div>
        </div>
        <div *ngIf="isActive == 0" class="search-container">
            <input type="search" name="searchedValue" placeholder="Search"
            (keyup)="onSearchChange($event)" class="searchValueDesktop searchInput" [value]="searchedValue">
            <mat-icon class="searchIcon" *ngIf="showSearchIcon">search</mat-icon>
        </div>
        <!-- For home -->
        <div *ngIf="isActive == 1" class="m-h overflow-auto h-full">
            <div class="p-card d-flex p-b-0 space-between">
                <p class="f-s-16 fw-600 m-0">Most Asked Questions</p>
            </div>
            <div class="p-10 p-t-0 m-10">
                    <div *ngIf="showSearchIcon">
                        <div title="{{faq.question}}" *ngFor="let faq of faqsList " class="p-card cat-link cursor-pointer d-flex align-center b-r-8 space-between border_custom" (click)="handleFaq(faq, 'cat')">
                            <p class="f-s-13-custom m-0">{{(faq.question.length>70)? (faq.question | slice:0:70)+'...':(faq.question)}}</p>
                            <p class="m-0 c-primary pos-relative top-4"><mat-icon>keyboard_arrow_right</mat-icon></p>
                        </div>
                    </div>
                    <div *ngIf="!showSearchIcon && allfaqs.length > 0">
                        <div title="{{faq.question}}" *ngFor="let faq of allfaqs | faq : searchedValue" class="p-card cat-link cursor-pointer d-flex align-center b-r-8 space-between border_custom" (click)="handleFaq(faq, 'cat')">
                            <p class="f-s-13-custom m-0">{{(faq.question.length>60)? (faq.question | slice:0:60)+'...':(faq.question)}}</p>
                            <p class="m-0 c-primary pos-relative top-4"><mat-icon>keyboard_arrow_right</mat-icon></p>
                        </div>
                    </div>
                    <div class="errorfaq">
                        <p class="mb-1">Can't find an answer to your question?</p>
                        <p><a href="https://pure-premium.typeform.com/to/r8k6Ykvq" target="_blank">Click here</a> to leave your question.</p>
                    </div>
            </div>
        </div>
        <div *ngIf="isActive == 1" class="search-container">
            <input type="search" name="searchedValue" placeholder="Search"
            (keyup)="onSearchChange($event)" class="searchValueDesktop searchInput" [value]="searchedValue">
            <mat-icon class="searchIcon" *ngIf="showSearchIcon">search</mat-icon>
        </div>
        <!-- For help -->
        <div *ngIf="isActive == 2" class="m-h overflow-auto">
            <!-- <div class="p-card border-bottom">
                <p class="f-s-16 fw-600 m-0">{{faqsCatList.length}} collections</p>
            </div> -->
            <div *ngFor="let faqcat of faqsCatList" class="p-card cat-link cursor-pointer" (click)="handleFaqCat(faqcat)">
                <div class="d-flex align-center space-between">
                    <div>
                        <p class="f-s-14 fw-600 m-0">{{faqcat.categoryName}}</p>
                        <p class="f-s-14 m-0">{{faqcat.faqs.length}} articles</p>
                    </div>
                    <p class="m-0 c-primary pos-relative top-4"><mat-icon>keyboard_arrow_right</mat-icon></p>
                </div>
                <div class="border-bottom pos-relative top-10"></div>
            </div>
        </div>
        <!-- for home and help faqs (common screen last)-->
        <div *ngIf="isActive == 3" class="m-h m-10">
            <div class="bg-white border overflow-hidden h-355">
                <div class="h-355">
                    <p class="p-10 f-s-16 fw-600 m-0 border-bottom m-h-100 overflow-auto">{{faq.question}}</p>
                    <p class="p-10 f-s-14 overflow-auto answer-card break-word h-256 m-0" [innerHTML]="faq.answer|safeHtml"></p>
                </div>
            </div>    
        </div>
        <!-- for help with cat faq list-->
        <div *ngIf="isActive == 4" class="m-h overflow-auto">
            <div class="p-card border-bottom">
                <p class="f-s-14 fw-600 m-0">{{faqc.categoryName}}</p>
                <p class="f-s-14 m-0">{{faqc.subCategory.length}} sub-category, {{faqc.faqs.length}} articles</p>
            </div>

            <div class="p-10 p-t-0 m-10" *ngIf="faqc.subCategory?.length > 0">
                <mat-chip-list>
                    <mat-chip *ngFor="let faqsubcat of faqc.subCategory" (click)="handleFaqSubCat(faqsubcat)">
                      {{ faqsubcat.categoryName }}
                    </mat-chip>
                </mat-chip-list>
            </div>
            <hr *ngIf="faqc.subCategory?.length > 0">
            <div *ngFor="let faq of faqc.faqs" class="p-card cat-link cursor-pointer" (click)="handleFaq(faq, 'cat')">
                <div title="{{faq.question}}" class="p-card cat-link cursor-pointer d-flex align-center b-r-8 space-between border_custom">
                    <p class="f-s-13-custom m-0">{{(faq.question.length>80)? (faq.question | slice:0:80)+'...':(faq.question)}}</p>
                    <p class="m-0 c-primary pos-relative top-4"><mat-icon>keyboard_arrow_right</mat-icon></p>
                </div>
            </div>

            <div class="errorfaq">
                <p class="mb-1">Can't find an answer to your question?</p>
                <p><a href="https://pure-premium.typeform.com/to/r8k6Ykvq" target="_blank">Click here</a> to leave your question.</p>
            </div>
        </div>
        <!-- for help with sub cat faq list-->
        <div *ngIf="isActive == 5" class="m-h overflow-auto">
            <div class="p-card border-bottom">
                <p class="f-s-14 fw-600 m-0">{{subfaqc.categoryName}}</p>
                <p class="f-s-14 m-0">{{subfaqc.faqs.length}} articles</p>
            </div>

            <div *ngFor="let faq of subfaqc.faqs" class="p-card cat-link cursor-pointer" (click)="handleFaq(faq, 'subcat')">
                <div title="{{faq.question}}" class="p-card cat-link cursor-pointer d-flex align-center b-r-8 space-between border_custom">
                    <p class="f-s-13-custom m-0">{{(faq.question.length>80)? (faq.question | slice:0:80)+'...':(faq.question)}}</p>
                    <p class="m-0 c-primary pos-relative top-4"><mat-icon>keyboard_arrow_right</mat-icon></p>
                </div>
            </div>

            <div class="errorfaq">
                <p class="mb-1">Can't find an answer to your question?</p>
                <p><a href="https://pure-premium.typeform.com/to/r8k6Ykvq" target="_blank">Click here</a> to leave your question.</p>
            </div>
        </div>
        <!-- for home and help sub faqs (common screen last)-->
        <div *ngIf="isActive == 6" class="m-h m-10">
            <div class="bg-white border overflow-hidden h-355">
                <div class="h-355">
                    <p class="p-10 f-s-16 fw-600 m-0 border-bottom m-h-100 overflow-auto">{{subfaq.question}}</p>
                    <p class="p-10 f-s-14 overflow-auto answer-card break-word h-256 m-0" [innerHTML]="subfaq.answer|safeHtml"></p>
                </div>
            </div>    
        </div>
    </div>
    <footer class="bottom-bar">
        <mat-toolbar class="toolbarNav">
            <button mat-flat-button (click)="isActive = 0;" [ngClass]="{'active-link':isActive == 0}">
              <!-- <mat-icon class="material-icons color_blue">home</mat-icon> -->
              <span class="underline">Home</span>
            </button>
            <div class="borderRight"></div>
            <button mat-flat-button (click)="navigateToSupportTeam()" [ngClass]="{'active-link':isActive == 2}">
              <!-- <mat-icon class="material-icons color_blue">help</mat-icon> -->
              <span class="underline">Help</span>
            </button>
        </mat-toolbar>
    </footer>    
</div>