import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as _ from 'underscore';
import { HomePageComponent } from './homepage.component';
import { AppComponent } from 'app/app.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BrowserModule } from '@angular/platform-browser';
import { footerModule } from "../../shared/footer/footer.module";
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

const routes: Routes = [
    {
        path: 'homepage',
        component: HomePageComponent,
        pathMatch: 'full',
    }
];

@NgModule({
    declarations: [
        HomePageComponent
    ],
    entryComponents: [],
    providers: [],
    bootstrap: [AppComponent],
    imports: [
        RouterModule.forChild(routes),
        SlickCarouselModule,
        BrowserModule,
        footerModule,
        FlexLayoutModule,
        MatIconModule
    ]
})
export class  HomePageModule {
}

