import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import * as _ from 'underscore';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from 'app/app.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { ShippingpolicyComponent } from './shippingpolicy/shippingpolicy.component';
import { SupportpageComponent } from './supportpage/supportpage.component';
import { FootercomponentComponent } from './footercomponent/footercomponent.component';
import { IncentivesComponent } from './incentives/incentives.component';
import { HeaderComponent } from '../header/header.component';
import { FormsModule } from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { PipesModule } from 'app/main/pipe/pipe.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SummaryCartModule } from 'app/main/summary-cart/summary-cart.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SummaryCartModuleButton } from 'app/main/summary-cart-button/summary-cart-button.module';
import { IncomeOpportunityComponent } from './IncomeOpportunity/IncomeOpportunity.component';
import { ReturnpolicyComponent } from './returnpolicy/returnpolicy.component';

const routes: Routes = [
    {
        path: 'documents/privacy',
        component: PrivacypolicyComponent,
        pathMatch:'full'
    },
    {
        path: 'documents/return',
        component: ReturnpolicyComponent,
        pathMatch:'full'
    },
    {
        path: 'documents/shipping',
        component: ShippingpolicyComponent,
        pathMatch:'full'
    },
    {
        path: 'documents/support',
        component: SupportpageComponent,
        pathMatch:'full'
    },
    {
        path: 'IncomeOpportunity',
        component: IncomeOpportunityComponent,
        pathMatch:'full'
    },
    {
        path: 'documents/incentives',
        component: IncentivesComponent,
        pathMatch:'full'
    }
];

@NgModule({
    declarations: [
        PrivacypolicyComponent,
        ReturnpolicyComponent,
        ShippingpolicyComponent,
        SupportpageComponent,
        FootercomponentComponent,
        IncentivesComponent,
        HeaderComponent,
        IncomeOpportunityComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserAnimationsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatTabsModule,
        NgpSortModule,
        NgxChartsModule,
        FilterPipeModule,
        MatExpansionModule,
        MatTooltipModule,
        FlexLayoutModule,
        MatDialogModule,
        SlickCarouselModule,
        FormsModule,
        MatAutocompleteModule,
        PipesModule,
        MatSidenavModule,
        SummaryCartModule,
        SummaryCartModuleButton
    ],
    exports: [
        FootercomponentComponent,
        HeaderComponent,
        MatAutocompleteModule
    ],
    entryComponents: [],
    providers: [],
    bootstrap: [AppComponent]
})
export class footerModule {
}

