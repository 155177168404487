import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-shippingpolicy',
  templateUrl: './shippingpolicy.component.html',
  styleUrls: ['./shippingpolicy.component.scss']
})
export class ShippingpolicyComponent implements OnInit {
  BaseURL = environment.BucketURl;
  constructor() { }

  ngOnInit(): void {
  }

}
