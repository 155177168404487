import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-IncomeOpportunity',
  templateUrl: './IncomeOpportunity.component.html',
  styleUrls: ['./IncomeOpportunity.component.scss']
})
export class IncomeOpportunityComponent implements OnInit {

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  ngOnInit(): void {
    const script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
      script.text = `
      {
          "@context": "https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=258138394"
      }
    `;
    script.async = true;
    script.defer = true;
    this._renderer2.appendChild(this._document.body, script);
  }

}
