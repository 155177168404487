import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'app/shared/rest.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';
import { AuthService } from 'app/shared/auth.service';
import { LifestyleSubscriptionComponent } from 'app/shared/LifestyleSubscription/LifestyleSubscription.component';
import { UserDialogComponent } from 'app/shared/user-dialog/user-dialog.component';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-collections',
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CollectionsComponent implements OnInit {
  productList: any[] = [];
  routPath: any = "Subscribe";
  siteType: any = '';
  webID;
  PageTitle: any = '';
  SubscriptionProcessType: any;
  location: any;
  webalias: any;
  url: any;
  mobileView:any;
  sharelinkURL:any;
  constructor(
    private _restService: RestService,
    public _enrollSession: EnrollSessionService,
    public _sharedMethodService: SharedMethodService,
    private _matDialog: MatDialog,
    public _router: Router,
    private _location: Location,
    private _toastr: ToastrService,
    private _activateRoute: ActivatedRoute,
    private _auth: AuthService
  ) {
    sessionStorage.setItem("collection", 'true');
    let bioMedic3 = sessionStorage.getItem("FromBioMedic3Site");
    this._enrollSession.data.customertype = this._enrollSession.data.customertype ? this._enrollSession.data.customertype : 22;
    this.location = this._location;
    this.sharelinkURL = sessionStorage.getItem('FromShareLinkCart');
    this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
    this._enrollSession.data.RetailOrderSummary = this._enrollSession.data.RetailOrderSummary || {};
    if (this._enrollSession.editTransformationPack != 'true' && !this._enrollSession.data.IsLoggedIn && !sessionStorage.getItem("collection") && (this.sharelinkURL === undefined || this.sharelinkURL === null || this.sharelinkURL==="false") && bioMedic3 == 'false') {
      this._enrollSession.data = {};
    }
    if (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary?.Enrollpack !== undefined && this._enrollSession.data.RetailOrderSummary?.Enrollpack !== null && this._enrollSession.data.RetailOrderSummary.Enrollpack?.length > 0 && (this.sharelinkURL === undefined || this.sharelinkURL === null || this.sharelinkURL==="false") && bioMedic3 == 'false') {
      this._enrollSession.data.RetailOrderSummary.InitialOrders = [];
      this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
      this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
      this._enrollSession.data.RetailOrderSummary.SmartOrderDetails = {};
    }
    if (this._enrollSession.data && this._enrollSession.editTransformationPack != 'true' && Object.keys(this._enrollSession.data).length === 0 && !sessionStorage.getItem("collection") && (this.sharelinkURL==="false") && bioMedic3 == 'false') {
      this._enrollSession.data.IsShowCart = false;
      this._enrollSession.data.page = this.location.path();
      this._enrollSession.data.TotalItemLength = 0;
      this._enrollSession.data.RetailEnroll = {};
      this._enrollSession.data.InitOrder = [];
      this._enrollSession.data.InitialOrdersSubTotal = 0;
      this._enrollSession.data.RetailOrderSummary = {};
      this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
      this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
      this._enrollSession.data.RetailOrderSummary.SmartOrderDetails = {};
      this._enrollSession.data.EligibleForFreeUpgrade = false;
      this._enrollSession.data.RetailEnroll.ShippingMethod = '';
      this._enrollSession.data.usePrimaryCard = null;
      this._enrollSession.data.primaryCreditCardField = {};
      this._enrollSession.data.EnrollmentType = this._enrollSession.data.EnrollmentType || 1;
      this._enrollSession.data.IsUpGrade =
        this._activateRoute.snapshot.queryParamMap.get('customerId') ? this._activateRoute.snapshot.queryParamMap.get('customerId') : false;
      if (this._enrollSession.data.IsUpGrade) {
        localStorage.setItem('customerId', this._enrollSession.data.IsUpGrade);
      }
      this._enrollSession.data.Volume109 = 0;
      this._enrollSession.data.SmartOrderBVTotal = 0;
      this._enrollSession.data.InitialOrdersQVTotal = 0;
      this._enrollSession.data.AddOnOrdersQVTotal = 0;
    }
    this.url = window.location.href;
    this.webalias = this._activateRoute.snapshot.queryParamMap.get('giftcard') || sessionStorage.getItem('giftCardName');
    this.siteType = this._activateRoute.snapshot.queryParamMap.get('site');
    if (this.siteType) {
      sessionStorage.setItem("siteType", this.siteType);
      var snapshot = this._activateRoute.snapshot;
      const params = { ...snapshot.queryParams };
      delete params.site
      this._router.navigate([], { queryParams: params });
    } else if (sessionStorage.getItem("siteType") == 'ultlifestyle') {
      this.siteType = '';
      sessionStorage.setItem("siteType", '');
    } else {
      this.siteType = sessionStorage.getItem("siteType") || '';
    }
    sessionStorage.setItem("siteType", this.siteType);
    window.scrollTo(0, 0);
    sessionStorage.setItem('onUltLifestyleFlow', 'false');
  }

  ngOnInit(): void {
    let screenWidth = window.innerWidth
    if(screenWidth < 550){
      this.mobileView = true
    }
    else{
      this.mobileView = false;
    }
    let acivatedRoute = this._activateRoute.snapshot.url[0].path;
    switch (acivatedRoute) {
      case 'Collection1':
        this.webID = 628;
        break;
      case 'Collection2':
        this.webID = 629;
        break;
      case 'Collection3':
        this.webID = 630;
        break;
      case 'Collection4':
        this.webID = 631;
        break;
      case 'Collection5':
        this.webID = 632;
        break;
      case 'Collection7':
        this.webID = 634;
        break;
      case 'Collection8':
        this.webID = 635;
        break;
      case 'Collection9':
        this.webID = 636;
        break;
      case 'Collection10':
        this.webID = 544;
        break;
      case 'IRT':
        this.webID = 654;
        break;
    }
    if (this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
      const request = '?WebAlias=' + this.webalias;
      this._restService.GetCustomerSite(request).subscribe((result: any) => {
          if (result.Data != null) {
              this._enrollSession.data.webAlias = this.webalias;
              this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
          } else {
              this._toastr.error('Invalid WebAlias.', 'Error');
          }
      }, error => {
          this._toastr.error('Somthing is wrong Please try again', 'Error');
      });
    }
    const customertype = JSON.parse(this._auth.getUserData()) || {}
    this.getAllProduct(this.webID, customertype.CustomerType || 22);
  }
  productInfo(item) {
    sessionStorage.setItem('productDisplay', JSON.stringify(item))
    this._router.navigate(['/AllProducts', item.ItemCode])
  }
  getAllProduct(webCategoryId, customertype) {
    const request = {
      'EnrollmentType': '',
      'CustomerType': this._auth.isLoggedIn() ? customertype : 22,
      'WebCategoryId': webCategoryId || 628,
      'isUpgradeCustomer': false,
      'IsSubscriptionOrder': false,
      'PriceType': ''
    };
    this._restService.getProducts(request).subscribe((res: any) => {
      if (res) {
        if (res?.Items.length != 0) {
          this.productList = res?.Items;
          this.PageTitle = res?.Items[0]?.Category;
        }
      } else {
        this._toastr.error('Error');
      }
    }, error => {
      this._toastr.error(error.error.Message, 'Error');
    });
  }

  ultlifestylePoup(itemCode, quantity?: any, type?: any) {
    const dialogRef = this._matDialog.open(LifestyleSubscriptionComponent, {
      width: '500px',
      maxWidth: '500px',
      height: '356px',
      panelClass: '',
      disableClose: true,
      data: {
        item: itemCode.ItemCode,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result == true) {
        if (itemCode.KitMembers && itemCode.KitMembers.length > 0) {
          const dialogRef = this._matDialog.open(OrderSubItemComponent, {
            width: '600px',
            panelClass: ['order-sub-item', 'sub-item-modal'],
            data: {
              item: itemCode,
              type: type
            }
          });
          dialogRef.afterClosed().subscribe(results => {
          });
        } else {
          this._sharedMethodService.ContinueRetailOrder(itemCode, quantity, type)
        }
      }
    });
  }

    addToCart(item: any, quantity: any, type: any, sideNav: MatSidenav) {


    if (this._auth.isLoggedIn() || sessionStorage.getItem('newCustomer') == 'true') {
      if ((this.siteType == "ultpack" || this.siteType == "" || !this.siteType) && (item.ItemCode == '11200' || item.ItemCode == '11205' || item.ItemCode == '11235' || item.ItemCode == '11238' || item.ItemCode == '11208' || item.ItemCode == '11209' || item.ItemCode == '11210' || item.ItemCode == '11220' || item.ItemCode == '11270' || item.ItemCode == '11271')) {
        this.ultlifestylePoup(item, quantity, type);
      }
      else if (item.KitMembers && item.KitMembers.length > 0) {
        const dialogRef = this._matDialog.open(OrderSubItemComponent, {
          width: '600px',
          panelClass: ['order-sub-item', 'sub-item-modal'],
          data: {
            item: item,
            type: type

          }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.event == 'submit') {
                this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
            }
        });
      } else {
          this._sharedMethodService.ContinueRetailOrder(item, quantity, type)
          this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
      }
    } else {
      const dialogRef = this._matDialog.open(UserDialogComponent, {
        width: '500px',
        maxWidth: '500px',
        height: '356px',
        panelClass: '',
        disableClose: true,
        autoFocus: false
      });
      this._sharedMethodService.isOpenDialog = true;
      dialogRef.afterClosed().subscribe(result => {

        this._sharedMethodService.isOpenDialog = false;
        if (result) {
          const customerDetail = JSON.parse(this._auth.getUserData()) || {};
          
          if (sessionStorage.getItem('newCustomer') != 'true') {
            this._restService.GetAutoOrders(customerDetail.CustomerID).subscribe((res: any) => {
              if (res.AutoOrderList.length > 0) {
                  localStorage.setItem("isExistingAutoship", "true");
                let CustomAutoOrderList = [];
                res.AutoOrderList.forEach(item => {
                  if (new Date(item.NextRunDate).getTime() == new Date(Math.min(...res.AutoOrderList.map(e => new Date(e.NextRunDate)))).getTime()) {
                    CustomAutoOrderList.push(item);
                  }
                });


                var minAutoOrderId = Math.min(...CustomAutoOrderList.map(e => e.AutoOrderID));
                CustomAutoOrderList = _.filter(CustomAutoOrderList, (item) => {
                  return (item.AutoOrderID == minAutoOrderId);
                });
                  localStorage.setItem('AutoOrderID', CustomAutoOrderList[0].AutoOrderID);
                  localStorage.setItem("AutoOrderDetails", JSON.stringify(CustomAutoOrderList[0]));
                this.SubscriptionProcessType = CustomAutoOrderList[0].ProcessType == 0 ? "AutoOrderProcessTy1" : "AutoOrderProcessTy2" || JSON.parse(localStorage.getItem('SubscriptionProcessType')) || "AutoOrderProcessTy1";
                localStorage.setItem("SubscriptionProcessType", JSON.stringify(this.SubscriptionProcessType));
                this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
              }
            }, error => {
              console.log("In error")
            })
          }
          if ((this.siteType == "ultpack" || this.siteType == "" || !this.siteType) && (item.ItemCode == '11200' || item.ItemCode == '11205' || item.ItemCode == '11235' || item.ItemCode == '11238' || item.ItemCode == '11208' || item.ItemCode == '11209' || item.ItemCode == '11210' || item.ItemCode == '11220' || item.ItemCode == '11270' || item.ItemCode == '11271')) {
            this.ultlifestylePoup(item, quantity, type);
          }
          else if (item.KitMembers && item.KitMembers.length > 0) {
            const dialogRef = this._matDialog.open(OrderSubItemComponent, {
              width: '600px',
              panelClass: ['order-sub-item', 'sub-item-modal'],
              data: {
                item: item,
                type: type

              }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result.event == 'submit') {
                    this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
                }
            });
          } else {
              this._sharedMethodService.ContinueRetailOrder(item, quantity, type);
              this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
          }

        }
      });
    }
  }
  RedirectToUltLifestyle(){
    this._router.navigateByUrl('/Ultlifestyle');
}
}



