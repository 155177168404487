<div class="page-wrapper">
    <div class="ult-checkout-section wf-section">
        <div class="checkout-div" id="login_container">
            <div id="login" class="font-IBM bgm-white">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" ng-cloak flex
                    class="p-10 login text-center">
                    <div fxLayout-padding fxFlex="50" fxFlex-xs="100">
                        <div class="p-20 mainborder">
                            <!------------------------------------- Forgot password Form Section ---------------------------------------------->
                            <div fxLayout="row" fxLayoutAlign="center center" class="mt-5 bg-green w-70 continue">
                                <button type="submit" class="btn-continue upper-case l-h-35 f-s-18 f-w-600"
                                    (click)="continue()">Back To Login</button>
                            </div>
                            <div class="m-t-20 mt-10">
                                <h3 class="text-center p-t-20 f-s-24 ">FORGOT PASSWORD?</h3>
                                <form [formGroup]="forgotPasswordForm" name="forgot-pass" id="forgotPassword">
                                    <div fxLayout="column" class="p-20-15-10">
                                        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                                            <div fxLayout="column" fxFlex="80" fxFlex.xs="100" fxFlex.sm="100">
                                                <div>
                                                    <input type="text" class="form-field w-input" maxlength="256"
                                                        name="email" formControlName="email" [(ngModel)]="userEmail" placeholder="Email"
                                                        id="email" required>                                                </div>
                                                <div class="error-box text-left">
                                                    <span class="help-block"
                                                        *ngIf="!forgotPasswordForm.get('email').valid&&forgotPasswordForm.get('email').touched">
                                                        <span
                                                            *ngIf="forgotPasswordForm.get('email').errors['required']">This
                                                            field is required</span>
                                                        <span
                                                            *ngIf="forgotPasswordForm.get('email').hasError('pattern') && (forgotPasswordForm.controls['email'].dirty || forgotPasswordForm.controls['email'].touched)"
                                                            class="red">
                                                            Email is not valid, should be (abc@123.xyz)
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div layout="row" fxLayout-sm="column" class="m-t-20 login-bottom"
                                        fxLayout-xs="column" fxLayout-align="start center"
                                        fxLayout-align-xs="center start" fxLayout-align-sm="center start" layout-wrap>
                                        <button type="submit" [ngStyle.lt-md]="{'width':100+'%'}" mat-raised-button
                                            class="btn-project w-80 upper-case f-w-600 f-s-18" color="primary"
                                            mat-raised-button (click)="forgotPassword()">Continue</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>