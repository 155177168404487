import { PathLocationStrategy, APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { Optional, Inject, Injectable } from '@angular/core';
import { UrlSerializer } from '@angular/router';
@Injectable()
export class PathPreserveQueryLocationStrategy extends PathLocationStrategy {
  private get search(): string {
    return this.platformLocation?.search ?? '';
  }
  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
  ) {
    super(platformLocation, _baseHref);
  }
  prepareExternalUrl(internal: string): string {
    const path = super.prepareExternalUrl(internal);
    const existingURLSearchParams = new URLSearchParams(this.search);
    const existingQueryParams = (Object.fromEntries(existingURLSearchParams.entries()));
    let FinalExistingQueryParams = Object.keys(existingQueryParams).filter((key) => key.includes("giftcard")).reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: existingQueryParams[key]
      });
    }, {});
    const urlTree = this.urlSerializer.parse(path);
    const nextQueryParams = urlTree.queryParams;
    let FinalNextQueryParams = Object.keys(nextQueryParams).filter((key) => key.includes("giftcard")).reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: nextQueryParams[key]
      });
    }, {});
    if (localStorage.getItem("LoggedInUserData")) {
      urlTree.queryParams = { };
    }
    else{
      urlTree.queryParams = { ...FinalExistingQueryParams, ...FinalNextQueryParams };
    }
    return urlTree.toString();
  }
}