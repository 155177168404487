import { Component, OnInit } from "@angular/core";
import { EnrollSessionService } from "app/shared/enrollSession.service";

@Component({
    selector: 'app-amyAndDave',
    templateUrl: './amyAndDave.component.html',
    styleUrls: ['./amyAndDave.component.scss']
  })
export class AmyAndDaveComponent implements OnInit{

    constructor(
      public _enrollSession: EnrollSessionService,
    ){}

    ngOnInit(): void {}
}