<div class="dialog-content-wrapper countryChange" id="two-way-wrapper">
	<div style="display:flex" class="pop-up-background">
		<div class="pop-up-div">
			<h1 class="pop-up-headline">You currently have item(s) in your cart that are not available for your shipping address. The item(s) will be removed from your cart. Do you want to Proceed to checkout?</h1>
			<div class="pop-up-button-div">
				<button mat-button aria-current="page" class="pop-up-button w-button w--current" [mat-dialog-close]=""
					cdkFocusInitial (click)="Action('Yes')">YES</button>
				<button mat-button class="pop-up-button pop-up-button-no w-button" (click)="Action('No')">NO</button>
			</div>
		</div>
	</div>
</div>