<div class="page-wrapper">
    <div class="d-block">
        <app-header></app-header>
        <div class="p-t-b-80 displayDesktop">
            <h2 class="text-center f-w-bold">Let's get together!</h2>
            <p class="text-center event-calendar-text">Don't like the fear of missing out? Well, no mo' FOMO! All of our events include 
                product education, business tips, superfoods and a lot of social fun. Mark your 
                calendars today so we can see you soon! Get more Virtual Event details, Zoom 
                guests, and Zoom replays with our Virtual Event Calendar:
            </p>
            <div>
                <a  class="desktopButton" href="https://bit.ly/Virtual-Events-Calendar" target="_blank">Virtual Events Calendar</a>
              </div>
        </div>
        <div class="p-t-b-80 displayMobile">
            <h2 class="text-center f-w-bold">Let's get together!</h2>
            <p class="text-center">Don't like the fear of missing out? Well, no mo' FOMO! All of our events include 
                product education, business tips, superfoods and a lot of social fun. Mark your 
                calendars today so we can see you soon! Get more Virtual Event details, Zoom 
                guests, and Zoom replays with our Virtual Event Calendar:
            </p>
            <div>
                <a  class="desktopButton" href="https://bit.ly/Virtual-Events-Calendar" target="_blank">Virtual Events Calendar</a>
            </div>
        </div>
        <div class="displayDesktop">
            <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&title=Purium%20Calendar&src=cHVyaXVtY2FsZW5kYXJAZ21haWwuY29t&color=%23039BE5" style="border: 0" width="100%" height="625" frameborder="0" scrolling="no"></iframe>
        </div>
        <div class="displayMobile">
            <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&title=Purium%20Calendar&showNav=1&showTabs=1&showCalendars=1&showDate=0&showPrint=0&src=cHVyaXVtY2FsZW5kYXJAZ21haWwuY29t&color=%23039BE5" style="border: 0" width="100%" height="625" frameborder="0" scrolling="no"></iframe>
        </div>
    </div>
    <app-footercomponent></app-footercomponent> 
</div>