<div id="product-popup">
<div class="dialog-content-wrapper product-info">
	<mat-toolbar matDialogTitle class="mat-accent m-0">
		<mat-toolbar-row class="head-text" fxLayout="row" fxLayoutAlign="space-between center">
			<div fxLayout="row" fxFlex="100" class="p-t-l-r-b">
                <div fxLayoutAlign="start center" fxFlex="80">
                    <strong class="sub-heading">{{dialogTitle}}</strong>
                </div>
                <div fxLayoutAlign="end center" fxFlex="20">
                    <mat-icon class="cursor-pointer" (click)="matDialogRef.close()">close</mat-icon>
                </div>
            </div>
		</mat-toolbar-row>
	</mat-toolbar>

	<div class="p-24 pb-0 m-0 dialog-content">
		<div fxLayout="row"  fxLayout.xs="column"  fxLayoutAlign="center start" fxLayoutAlign.xs="center center">
			<div fxFlex="100" fxFlex.lg="40" fxFlex.sm="50" fxFlex.md="50" class="p-r-20" >
				<div class="item-img kitImage">
					  <img [src]="item.SmallPicture" onError="this.src='assets/img/noimage.png';">
				</div>
				<div class="alignCenter">
					<span class="productLabel">{{dialogTitle}}</span>
				</div>
			</div>
			<div fxFlex="100" fxFlex.gt-lg="60" fxFlex.gt-sm="50" fxFlex.gt-md="50">
				<div class="item-info">
					<span [innerHTML]="item.LongDetail"></span>
					<span [innerHTML]="item.LongDetail3"></span>
				</div>
			</div>
		</div>
	</div>

	<div class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-around center">
		<div fxFlex="100" fxFlex.gt-lg="50" fxFlex.gt-sm="50" fxFlex.gt-md="50">
			<strong>Price:</strong><span>${{item.Price |number : '1.2-2'}}</span>	
		</div>
		<div fxFlex="100" fxFlex.gt-lg="50" fxFlex.gt-sm="50" fxFlex.gt-md="50">
			<strong>BV:</strong><span>{{item.BusinessVolume}}</span>
		</div>
	</div>
</div>
</div>